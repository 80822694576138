import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import Icon from 'app/common/Icon/index';
import classes from './style.module.scss';
import {PeriodicCheckPayload} from "types/index";
import {useStoreState} from "~/store";
import List from "~/issues/List/List";
import moment from 'moment';
import {InspectionTypes} from "~/periodicCheck/store";

export interface ListItemProps {
    item: PeriodicCheckPayload
    onSelect?: (item: any, status: PeriodicCheckPayload['status']) => void
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item, onSelect}) => {

    const [...allIssues] = useStoreState(state => state.machine.data.issues);
    const lastOperatingTimeDate = useStoreState(state => state.machine.data.lastOperatingTimeDate);
    const lastOperatingCountDate = useStoreState(state => state.machine.data.lastOperatingCountDate);
    const operatingTimeAllowed = useStoreState(state => state.machine.data.operatingTimeAllowed);
    const operatingCountAllowed = useStoreState(state => state.machine.data.operatingCountAllowed);
    const inspectionType = useStoreState(state => state.periodicCheck.inspectionType);
    const statusChangeHandler = useCallback((newStatus) => {
        if (onSelect && !([InspectionTypes.DAILY, InspectionTypes.PERIODIC].includes(inspectionType)
          && ((!moment(lastOperatingTimeDate).isSame(moment(), 'day') && !!operatingTimeAllowed)
          || (!moment(lastOperatingCountDate).isSame(moment(), 'day') && !!operatingCountAllowed)))) {
            onSelect(item, (newStatus === item.status) ? 0 : newStatus);
        }
    }, [item, onSelect, lastOperatingTimeDate, operatingTimeAllowed, lastOperatingCountDate, operatingCountAllowed, inspectionType]);

    const registerIssue = useCallback((newStatus) => {
        if (onSelect) {
            onSelect(item, 2);
        }
    }, [item, onSelect]);

    const periodicCheckIssues = useMemo(() => {
        return allIssues.reverse().filter(issue => item.issues.map((issue) => issue.id.toString()).includes(issue.id.toString()));
    }, [item.issues, allIssues]);

    return (
        <>
            <div className={`${classes['list-item']} ${periodicCheckIssues.length ? classes['show-issues'] : ''}`}>
                <div className={classes['title']}>{item.name}</div>
                {(item.fluidTypeId && item.status !== 0) &&
                <div className={classes['fluid-value']}>{item.quantity.toFixed(1)}</div>}
                <div className={classNames(classes['icon-wrap'])}>
                    <Icon name={'close'}
                          className={`${classes['cancel']} ${item.status === 2 ? classes['checked'] : ''} ${item.status === 1 ? classes['disabled'] : ''}`}
                          onClick={() => statusChangeHandler(2)}/>
                    <Icon name={'done'}
                          className={`${classes['ok']} ${item.status === 1 ? classes['checked'] : ''} ${item.status === 2 ? classes['disabled'] : ''}`}
                          onClick={() => statusChangeHandler(1)}/>
                </div>
            </div>
            {periodicCheckIssues.length && item.status === 2 ?
                <List list={periodicCheckIssues} listClassName={'periodic-check-issues'}/> : ''}
            {periodicCheckIssues.length && item.status === 2 ?
                <div className={classes['register-issue-link']} onClick={registerIssue}>Registrer ny feil</div> : ''}
        </>
    );
};

export default ListItem
