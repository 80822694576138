import React from 'react';

import useTranslation from '~/app/hooks/useTranslation';

import ListItem from '../ListItem';

import classes from './style.module.scss';

export type EmptyMessageComponent = React.FC

const EmptyMessage: EmptyMessageComponent = () => {
  const { t } = useTranslation('system.form.search');

  return <ListItem className={classes['empty-message']} item={{ id: 1, name: t('noSearchResults') }} />;
};

export default EmptyMessage
