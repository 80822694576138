import React, { useCallback } from 'react';
import {IssueCategory} from 'types/index';
import classNames from 'classnames';
import classes from './style.module.scss';

export interface ListItemProps {
  item: IssueCategory
  activeItem: IssueCategory['id']
  onSelect?: (item: any) => void
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({ item, activeItem, onSelect }) => {
  const itemClickHandler = useCallback(() => {
    if (onSelect) {
      onSelect(item);
    }
  }, [item, onSelect]);

  return (
    <div className={classNames(classes['list-item'], {[classes['active']]: item.id === activeItem})} onClick={itemClickHandler}>
        {item.name}
    </div>
  );
};

export default ListItem
