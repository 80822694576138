import { AuthenticationResult } from '@azure/msal-common';
import {
  AuthError,
  Configuration,
  EventType,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { localStorageGet, localStorageRemove, localStorageSet } from '~/app/helpers/localStorage';
import store from "~/store";
import i18n from 'i18next';

const localStorageKey = 'msal.forgotPasswordProcess';

const resetPassword = !!localStorageGet(localStorageKey);

localStorageRemove(localStorageKey);

export namespace MSALBrowser {
  export function config(): Configuration {
    const {azureADB2CAuthority = '', azureADB2CAuthorityPwdReset = '', azureADB2CApplicationId = ''} = store.getState().tenant.currentTenant || {};
    return {
      auth: {
        clientId: azureADB2CApplicationId,
        authority: resetPassword ? azureADB2CAuthorityPwdReset : azureADB2CAuthority,
        knownAuthorities: [new URL(azureADB2CAuthority).host],
        redirectUri: window.location.origin + process.env.PUBLIC_URL,
      },
    }
  }

  export function authenticationRequest(): RedirectRequest {
    const {azureADB2CScopes = ''} = store.getState().tenant.currentTenant || {};
    return {
      scopes: [ ...azureADB2CScopes ? [azureADB2CScopes] : [], 'openid', 'profile', 'offline_access'],
      extraQueryParameters : {lang: i18n.language}
    }
  }

  export function msalInstance() {
    const {msalInstance} = store.getState().msalBrowser;
    return msalInstance;
  }

  export function init() : PublicClientApplication {
    const msalInstance = new PublicClientApplication(MSALBrowser.config());
    MSALBrowser.addEventCallback(msalInstance);
    return msalInstance;
  }

  export function getSilentRequest(): SilentRequest {
    return {
      scopes: MSALBrowser.authenticationRequest().scopes,
      account: (MSALBrowser.msalInstance().getActiveAccount() || MSALBrowser.msalInstance().getAllAccounts()[0]),
    };
  }

  export function acquireTokenSilent(): Promise<AuthenticationResult> {
    return MSALBrowser.msalInstance().acquireTokenSilent(MSALBrowser.getSilentRequest());
  }

  export function acquireTokenRedirect(): Promise<void> {
    return MSALBrowser.msalInstance().acquireTokenRedirect(MSALBrowser.getSilentRequest());
  }

  export function getAcquireAccessToken(): Promise<AuthenticationResult['accessToken']> {
    return MSALBrowser.acquireTokenSilent().then(({ accessToken }) => accessToken);
  }

  export function logoutRedirect(request: {postLogoutRedirectUri?:string} = {}): Promise<void> {
    return MSALBrowser.msalInstance().logoutRedirect(request);
  }

  export function addEventCallback(msalInstance) {
    msalInstance.addEventCallback(message => {
      if (message.eventType) {
        const error = (message.error as AuthError);

        switch (message.eventType) {
          case EventType.LOGIN_FAILURE:
            switch (error.errorCode) {
              case 'access_denied':
                if (error.errorMessage.includes('AADB2C90118')) {
                    localStorageSet(localStorageKey, '1');
                }

                window.location.reload();

                break;
              case 'null_or_empty_id_token':
              case 'invalid_state_error':
                window.location.reload();

                break;
            }

            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            switch (error.errorCode) {
              case 'silent_sso_error':
                MSALBrowser.acquireTokenRedirect().then();

                break;
            }

            break;
        }
      }
    });
  }
}