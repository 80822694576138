import React from 'react';
import {useStoreState} from '~/store';
import NetworkOffline from './NetworkOffline/index';
import ManualOffline from './ManualOffline/index';

export type OfflineTimerMessageComponent = React.FC

const OfflineTimerMessage: OfflineTimerMessageComponent = () => {
    const status = useStoreState(state => state.app.offline.status);

    return (
        <>
            {status === 1 && <NetworkOffline/>}
            {status === 2 && <ManualOffline/>}
        </>
    );
};

export default OfflineTimerMessage
