export const required = ({name, value}, {values}): boolean => {
    return value !== '' && value !== undefined && value !== null;
};

export const validateOperatingTimeOrCount = (oldValue, newValue): { type, difference } | null => {
    if ((newValue < oldValue || newValue - 40 >= oldValue) && oldValue > 0) {
        if (newValue < oldValue) {
            return {type: 'underLimit', difference: oldValue - newValue};
        } else {
            return {type: 'overLimit', difference: newValue - oldValue};
        }
    }
    return null;
};