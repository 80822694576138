import React, { useMemo } from 'react';
import IdleHoursTrendGraph from '../IdleHoursTrendGraph';
import useTranslation from '~/app/hooks/useTranslation';
import { idleTimeHistoryData } from '~/types';
import IdleHoursGraph from '../IdleHoursGraph';
import ReportCard from '../../ReportCard';
import ReportCardRightSideInfo from '../../common/ReportCardRightSideInfo';

export interface IdleHoursReportCardProps {
    idleTimeHistoryData: idleTimeHistoryData
}

export type IdleHoursReportCardComponent = React.FC<IdleHoursReportCardProps>;

const IdleHoursReportCard = ({ idleTimeHistoryData }) => {
    const { t } = useTranslation("machine.dashboard");

    const firstSectionData = useMemo(() => {
        return {
            title: t('previousWeekAverage'),
            data: `${idleTimeHistoryData.previousWeekPercentage}%`,
            performanceData: idleTimeHistoryData.previousWeekPerformanceCompareToAverage
        }
    }, [idleTimeHistoryData.previousWeekPercentage, idleTimeHistoryData.previousWeekPerformanceCompareToAverage, t])

    const secondSectionData = useMemo(() => {
        return {
            title: t('last12MonthsAverage'),
            data: `${idleTimeHistoryData.last12MonthsPercentage}%`,
            performanceData: idleTimeHistoryData.last12MonthsPerformanceCompareToAverage
        }
    }, [idleTimeHistoryData.last12MonthsPercentage, idleTimeHistoryData.last12MonthsPerformanceCompareToAverage, t]);

    return (
        <ReportCard
            title={t(`idleHours.title`)}
            subtitle={t('belowTarget', { targetPercentage: idleTimeHistoryData.TargerPercentageBySubcategory })}
            dateOfLastAvailableData={idleTimeHistoryData.previousWorkingDay}
            rightSideInfo={<ReportCardRightSideInfo
                firstSection={firstSectionData}
                secondSection={secondSectionData}
            />}
            mainGraphTitle={t('previousWorkingDay')}
            mainGraph={<IdleHoursGraph idleTimeHistoryData={idleTimeHistoryData} />}
            trendGraph={<IdleHoursTrendGraph idleTimeHistoryTargetData={idleTimeHistoryData.TargerPercentageBySubcategory} average={idleTimeHistoryData.previousWorkDayPercentageBySubcategory} />}
        />
    )
}

export default IdleHoursReportCard