import React, { useCallback } from 'react';
import useTranslation from '~/app/hooks/useTranslation';
import { useAppLoader } from '~/app/modules/appLoader';
import { availableLanguages } from '~/i18n';
import { Languages } from '~/app/data/statuses/i18n';
import classes from './style.module.scss';
import { useStoreActions } from '~/store';
import classNames from 'classnames';
import Flag from '~/app/common/Flag';

export interface LanguageSwitcherProps {
  onSelect?: () => void
}

export type LanguageSwitcherComponent = React.FC<LanguageSwitcherProps>

const LanguageSwitcher: LanguageSwitcherComponent = ({ onSelect }) => {
  const { currentLanguage, changeLanguage } = useTranslation('header.userDropdown');
  const { showAppLoader, hideAppLoader } = useAppLoader();
  const updateUserLanguageAction = useStoreActions(actions => actions.user.updateLanguage);

  // Update user language and load the selected language translation on click on dropdown item
  const dropdownClickHandler = useCallback((language: Languages) => {
    showAppLoader();

    // Call update user language API
    updateUserLanguageAction({ language })
      .then(() => {
        // Call i18 change language API to load selected language translation
        changeLanguage(language);
      })
      .finally(hideAppLoader);

    if (onSelect) {
      onSelect();
    }
  }, [changeLanguage, hideAppLoader, onSelect, showAppLoader, updateUserLanguageAction]);

  return (
    <li className={classes['languages']}>
      {availableLanguages.map(item => (
        <span
          key={item.value}
          className={classNames(classes['language'], { [classes['active']]: currentLanguage === item.value })}
          style={{ flexBasis: 100 / availableLanguages.length + '%' }}
          onClick={() => currentLanguage !== item.value && dropdownClickHandler(item.value)}
        >
          <Flag className={classes['flag']} name={item.flag} />
          {item.label}
        </span>
      ))}
    </li>
  );
};

export default LanguageSwitcher
