import React, {useCallback, useState} from "react"
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import LightBox from "~/app/common/LightBox";

export interface CarouselProps {
    images: string[]
}

export type CarouselComponent = React.FC<CarouselProps>

const Carousel: CarouselComponent = ({images}) => {
    const [counter, setCounter] = useState<number>(0);
    const [flexBoxOpen, setFlexBoxOpen] = useState<boolean>(false);

    const goToPreviousSlide = useCallback((e) => {
        e.stopPropagation();
        setCounter(counter > 0 ? counter - 1 : images.length - 1)
    }, [counter, setCounter, images]);

    const goToNextSlide = useCallback((e) => {
        e.stopPropagation();
        setCounter(counter < images.length - 1 ? counter + 1 : 0)
    }, [counter, setCounter, images]);

    const toggleFlexBox = useCallback(() => {
        setFlexBoxOpen(!flexBoxOpen);
    }, [flexBoxOpen, setFlexBoxOpen])

    return (
        <div className={classes['slider']}>
            <img src={images[counter]} alt={''} className={classes['image']} onClick={toggleFlexBox}/>
            {images.length > 1 &&
            <div className={classes['buttons']} onClick={toggleFlexBox}>
                <div className={`${classes['icon-div']} ${classes['left']}`}>
                    <Icon name="arrow_back_ios" className={classes['icon']} onClick={goToPreviousSlide}/>
                </div>
                <div className={classes['text']}>
                    <p className={classes['slider-text']}>{`(${counter + 1}/${images.length})`}</p>
                </div>
                <div className={`${classes['icon-div']} ${classes['right']}`}>
                    <Icon name="arrow_back_ios" className={classes['icon']} onClick={goToNextSlide}/>
                </div>
            </div>}
            <LightBox open={flexBoxOpen} onClose={toggleFlexBox} imageUrl={images[counter]}/>
        </div>
    )
}

export default Carousel