import { MSALBrowser } from "./auth/msalBrowser";
import { MSALCordova } from "./auth/msalCordova";
import { isCordova } from "./cordova";
import store from "./store";

export const fetchJWTToken = async () => {

    let newToken: string | null = null;

    if (isCordova) {
        const { user: { token, tokenData } } = store.getState();

        if (tokenData?.exp && tokenData.exp * 1000 < Date.now()) {
            try {
                newToken = await MSALCordova.getToken({ loginHint: tokenData?.email });
                store.getActions().user.setToken(newToken);
            } catch (err) {
                store.getActions().user.logout().then();
            }
        } else if (token) {
            newToken = token;
        }
    } else {
        newToken = await MSALBrowser.getAcquireAccessToken();
        store.getActions().user.setToken(newToken);
    }
    return newToken;
}