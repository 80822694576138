import React from 'react';
import classes from './style.module.scss';
import UserDetails from "./UserDetails";
import Roles from "./Roles";
import {useStoreState} from "~/store";

export type SettingsProps = {
    onSelect?(): void
}

export type SettingsComponent = React.FC<SettingsProps>

const Settings: SettingsComponent = ({onSelect}) => {
    const networkStatus = useStoreState(state => state.app.offline.status);
    return (
        <ul className={classes['menu']}>
            <UserDetails/>
            {!networkStatus && <Roles onSelect={onSelect}/>}
        </ul>
    );
};

export default Settings
