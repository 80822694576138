import React from 'react';

import Loader from '~/app/common/Loader';

import { useStoreState } from '~/store';

export type AppLoaderComponent = React.FC

export const AppLoader: AppLoaderComponent = () => {
  const loading = useStoreState(state => state.app.appLoader.loading);

  if (!loading) {
    return null;
  }

  return <Loader withDimmer page size="32" />;
};
