import React, {useEffect, useRef} from 'react';
import classes from './style.module.scss';
import ListItem from "./ListItem";
import {useStoreState} from "~/store";

export type ListComponent = React.FC

const List: ListComponent = () => {
    const chat = useStoreState(state => state.issue.data.chat);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [chat, messagesEndRef])

    return (
        <div className={classes['messages']} ref={messagesEndRef}>
            {chat.map((item, index) => <ListItem key={index} item={item}/>)}
        </div>
    );
};

export default List
