import React, {useCallback, useState} from 'react';
import classes from './style.module.scss';
import Icon from "~/app/common/Icon";
import {Role} from "~/types";
import {useStoreActions, useStoreState} from "~/store";
import Loader from "~/app/common/Loader";

export interface ListItemProps {
    item: Role

    onSelect?(): void
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item, onSelect}) => {
    const [fetching, setFetching] = useState<boolean>(false);
    const currentRole = useStoreState(state => state.user.currentRole);
    const fetchPermissions = useStoreActions(actions => actions.user.fetchPermissions);

    const changeRole = useCallback(() => {
        if (currentRole !== item.id) {
            setFetching(true);
            fetchPermissions(item.id).finally(() => {
                setFetching(false);
                onSelect && onSelect();
            });
        }
    }, [fetchPermissions, onSelect, currentRole, item]);

    return (
        <li className={`${classes['role']} ${item.id === currentRole && classes['active']}`}
            onClick={changeRole}>
            <span>{item.name}</span>
            {item.id === currentRole && <Icon name={'done'}/>}
            {fetching && <Loader size="24" inline/>}
        </li>
    );
};

export default ListItem
