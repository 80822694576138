import {localStorageGet, localStorageRemove, localStorageSet} from "~/app/helpers/localStorage";
import store from "~/store";

export namespace MSALCordova {
  export enum AuthorityTypes {
    aad = 'AAD',
    b2c = 'B2C',
  }

  export enum AuthorityAudiences {
    azureADandPersonalMicrosoftAccount = 'AzureADandPersonalMicrosoftAccount',
    azureADMyOrg = 'AzureADMyOrg',
    azureADMultipleOrgs = 'AzureADMultipleOrgs',
    personalMicrosoftAccount = 'PersonalMicrosoftAccount',
  }

  export enum AuthorityCloudInstances {
    msalAzurePublicCloudInstance = 'MSALAzurePublicCloudInstance',
    msalAzureChinaCloudInstance = 'MSALAzureChinaCloudInstance',
    msalAzureGermanyCloudInstance = 'MSALAzureGermanyCloudInstance',
    msalAzureUsGovernmentCloudInstance = 'MSALAzureUsGovernmentCloudInstance',
  }

  export type Authority = {
    type?: AuthorityTypes
    audience?: AuthorityAudiences
    authorityUrl?: string
    cloudInstance?: AuthorityCloudInstances
    default?: boolean
  }

  export enum AuthorizationUserAgents {
    default = 'DEFAULT',
    browser = 'BROWSER',
    webview = 'WEBVIEW',
  }

  export enum AccountModes {
    single = 'SINGLE',
    multiple = 'MULTIPLE',
  }

  export type Config = {
    clientId: string,
    tenantId: string,
    authorities?: Authority[]
    authorizationUserAgent?: AuthorizationUserAgents
    multipleCloudsSupported?: boolean
    brokerRedirectUri?: boolean
    accountMode?: AccountModes
    scopes?: string[]
  }

  export enum SignInInteractivePrompts {
    whenRequired = 'WHEN_REQUIRED',
    selectAccount = 'SELECT_ACCOUNT',
    login = 'LOGIN',
    consent = 'CONSENT',
  }

  export type SignInInteractiveOptions = {
    loginHint?: string
    prompt?: MSALCordova.SignInInteractivePrompts
    authorizationQueryStringParameters?: { param: string, value: string }[]
    otherScopesToAuthorize?: string[]
  }

  export type signInResponseAccount = {
    id: string,
    username: string,
    claims: { key: string, value: string }[]
  }

  export type signInResponse = {
    token: string
    account: signInResponseAccount
  }

  const localStorageKey = 'msalCordova.forgotPasswordProcess';

  const resetPassword = !!localStorageGet(localStorageKey);

  localStorageRemove(localStorageKey);

  export function config(): MSALCordova.Config {
    const { azureADB2CAuthority = '', azureADB2CAuthorityPwdReset = '', azureADB2CScopes = '', azureADB2CApplicationId = '', azureADB2CTenantId = '' } = store.getState().tenant.currentTenant || {};
    return {
      clientId: azureADB2CApplicationId,
      tenantId: azureADB2CTenantId,
      authorities: [
        {
          type: MSALCordova.AuthorityTypes.b2c,
          audience: MSALCordova.AuthorityAudiences.azureADandPersonalMicrosoftAccount,
          authorityUrl: resetPassword ? azureADB2CAuthorityPwdReset : azureADB2CAuthority,
          cloudInstance: MSALCordova.AuthorityCloudInstances.msalAzurePublicCloudInstance,
          default: true,
        },
      ],
      scopes: [azureADB2CScopes],
    }
  }

  export function init(options: MSALCordova.Config = MSALCordova.config()): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.msalInit(resolve, reject, options);
    });
  }

  export function signInSilent(): Promise<signInResponse> {
    return new Promise<signInResponse>((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signInSilent(resolve, reject);
    });
  }

  export function signOut(accountId?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signOut(resolve, reject, accountId);
    });
  }

  export function signInInteractive(options?: MSALCordova.SignInInteractiveOptions): Promise<signInResponse> {
    return new Promise<signInResponse>((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signInInteractive((result) => {
        resetPassword ? signOut().then(() => window.location.reload()).catch(() => window.location.reload()) : resolve(result);
      }, (error) => {
        const resetPasswordAccessDenied = error.includes('AADB2C90118');
        resetPasswordAccessDenied && localStorageSet('msalCordova.forgotPasswordProcess', '1');
        (resetPasswordAccessDenied || resetPassword) ? setTimeout(() => window.location.reload(), 500) : reject(error);
      }, options);
    });
  }

  export function getToken(options?: MSALCordova.SignInInteractiveOptions): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      MSALCordova.signInSilent()
        .then((response: signInResponse) => resolve(response.token))
        .catch(() => {
          MSALCordova.signInInteractive(options)
            .then((response: signInResponse) => resolve(response.token))
            .catch(reject);
        });
    });
  }
}
