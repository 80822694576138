import React from 'react';
import { Area, CartesianGrid, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import useTranslation from '~/app/hooks/useTranslation';
import { useStoreState } from '~/store';
import { fuelConsumptionHistoryData, legendData } from '~/types';
import RenderLegend from '../../common/RenderLegend';

export interface FuelConsumptionTrendGraphProps {
    average: fuelConsumptionHistoryData['previousWorkDayConsumptionBySubcategory']
}

export type FuelConsumptionTrendGraphComponent = React.FC<FuelConsumptionTrendGraphProps>;

const FuelConsumptionTrendGraph: FuelConsumptionTrendGraphComponent = ({ average }) => {
    const { t } = useTranslation("machine.dashboard");
    const machine = useStoreState(state => state.machine.data);
    const legendStyle = {
        left: 'var(--size-10)',
        top: '92%',
        height: 'var(--size-40)'
    }
    const payload: legendData[] = [
        { id: 'legend01', value: t('currentArea'), icon: 'trip_origin' },
        { id: 'legend02', value: t('fuelConsumption.averageUnit'), icon: 'remove' },
    ];

    return (
        <>
            <ResponsiveContainer width="100%" height={280}>
                <ComposedChart
                    data={machine.fuelConsumptionHistoryData}
                    margin={{ top: 25, right: 30, left: -16, bottom: 5 }}
                >
                    <defs>
                        <linearGradient id="color-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="var(--color-blue-dasboard-data)" stopOpacity={0.3} />
                            <stop offset="100%" stopColor="var(--color-blue-dasboard-data)" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} />
                    <XAxis dy={15} tickLine={false} dataKey="day" />
                    <YAxis dx={-18} tickLine={false} type="number" axisLine={false} />
                    <Legend align='left' content={<RenderLegend />} wrapperStyle={legendStyle} payload={payload} />
                    <ReferenceLine y={average} stroke="var(--dashbaord-primary-text)" />
                    <Area type="linear" dataKey="previousWorkDayConsumption" stroke="var(--color-blue-dasboard-data)" strokeWidth={2} dot={{ stroke: 'var(--color-blue-dasboard-data)', fill: 'var(--color-white)' }} fillOpacity={1} fill="url(#color-gradient)" />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}

export default FuelConsumptionTrendGraph