import React, { useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { utc } from 'moment';

import { formatDate } from '~/app/helpers/date';
import FormattedDate from '~/app/common/FormattedDate';

import useField from '../useField';
import useDefaultValue from '../useDefaultValue';

import Field, { FieldProps } from '../Field';

import mainClasses from '../style.module.scss';
import classes from './style.module.scss';

export interface DateRefObject {
  setValue: React.Dispatch<React.SetStateAction<string>>
}

export interface DatePropsDefault extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputClassName?: string
  type?: 'date' | 'time' | 'datetime-local'
  format?: string
  strongTime?: boolean
}

export type DateProps = DatePropsDefault & FieldProps

export type DateComponent = React.ForwardRefRenderFunction<DateRefObject, DateProps>

const Date: DateComponent = (props, ref) => {
  const {
    fieldProps,
    inputProps: {
      inputClassName,
      type = 'datetime-local',
      placeholder,
      format,
      defaultValue: defaultDefaultValue,
      strongTime,
      onChange,
      ...othersInputProps
    }
  } = useField<DatePropsDefault>(props);

  const defaultValue = useDefaultValue(defaultDefaultValue);

  const [value, setValue] = useState<string>(defaultValue ? formatDate(defaultValue) : '');

  const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = formatDate(e.target.value);
    
    if (othersInputProps.min && utc(e.target.value).isBefore(othersInputProps.min)) {
      newValue = formatDate(othersInputProps.min);
    } else if (othersInputProps.max && utc(e.target.value).isAfter(othersInputProps.max)) {
      newValue = formatDate(othersInputProps.max);
    }

    setValue(newValue);

    if (onChange) {
      onChange({ ...e, target: { ...e.target, value: newValue } });
    }
  }, [onChange, othersInputProps.max, othersInputProps.min]);

  useImperativeHandle(ref, () => ({
    setValue
  }), []);

  return (
    <Field {...fieldProps}>
      <div className={classNames(`${mainClasses['input']} ${classes['input']}`, inputClassName)}>
        {!!placeholder && <div className={classes['placeholder']}>{placeholder}</div>}

        <FormattedDate strongTime={strongTime} date={value} format={format} />

        <input
          {...othersInputProps}
          className={classes['date-input']}
          type={type}
          value={value}
          disabled={fieldProps.loading || othersInputProps.disabled}
          onChange={inputChangeHandler}
        />
      </div>
    </Field>
  );
};

export default forwardRef(Date)
