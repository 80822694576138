import React from 'react';

import AuthBrowserProvider from '~/auth/BrowserProvider';
import AuthCordovaProvider from '~/auth/CordovaProvider';

import { isCordova } from '~/cordova';

export type ProviderComponent = React.FC

const Provider: ProviderComponent = ({ children }) => {
  if (!isCordova) {
    return (
      <AuthBrowserProvider>
        {children}
      </AuthBrowserProvider>
    );
  }

  return (
    <AuthCordovaProvider>
      {children}
    </AuthCordovaProvider>
  );
};

export default Provider
