import {action, Action} from 'easy-peasy';
import {localStorageGet, localStorageRemove, localStorageSet} from '~/app/helpers/localStorage';
import moment from 'moment';

const offlineDuration: number = parseInt(process.env.REACT_APP_OFFLINE_DURATION || '0');
const doNotShowDialogAgain: boolean = localStorageGet("offline-do-not-show-again") === 'yes' ? true : false;
const timeDiff: number = moment(localStorageGet("offline-datetime")).diff(moment(), 'seconds');

if (timeDiff <= 0) {
    localStorageRemove("offline-datetime")
}

export interface OfflineModel {
    status: 0 | 1 | 2 // 0 = online, 1 = network offline, 2 = manual offline
    networkStatus: 0 | 1 | 2, // 0 = online, 1 = offline
    time: string | null
    doNotShowDialogAgain: boolean,
    setStatus: Action<OfflineModel, OfflineModel['status']>
    setNetworkStatus: Action<OfflineModel, OfflineModel['networkStatus']>
    setDoNotShowDialogAgain: Action<OfflineModel, OfflineModel['doNotShowDialogAgain']>
    SQLiteConnection?: SQLitePlugin.Database
    SetSQLiteConnection: Action<OfflineModel, OfflineModel['SQLiteConnection']>
}

export const offlineModel: OfflineModel = {
    status: timeDiff > 0 ? 2 : 0,
    networkStatus: 0,
    time: localStorageGet("offline-datetime"),
    doNotShowDialogAgain: doNotShowDialogAgain,
    setStatus: action((state, payload) => {
        const datetime = payload === 2 ? moment().add(offlineDuration, 'seconds').format("YYYY-MM-DD HH:mm:ss") : null;
        (payload === 2 && datetime) ? localStorageSet("offline-datetime", datetime) : localStorageRemove("offline-datetime");
        state.status = payload;
        state.time = datetime;
    }),
    setNetworkStatus: action((state, payload) => {
        state.networkStatus = payload;
        if (!(state.status === 2 && moment(state.time).diff(moment(), 'seconds') >= 0)) {
            state.status = payload;
            state.time = null;
        }
    }),
    setDoNotShowDialogAgain: action((state, payload) => {
        state.doNotShowDialogAgain = payload;
        localStorageSet("offline-do-not-show-again", payload ? 'yes' : 'no')
    }),
    SetSQLiteConnection: action((state, payload) => {
        state.SQLiteConnection = payload;
    }),
};
