import React from 'react';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import Header from './Header';
import Form from './Form';
import {IssueDetails, IssuePayload} from "~/types";

export interface AddIssueProps {
    onClose: () => void
    open: boolean
    type: IssuePayload['issueType']
    inspectionType?: IssuePayload['inspectionType']
    issueDetails?: IssueDetails
    onSave?: (id: IssueDetails['id']) => void
}

export type AddIssueComponent = React.FC<AddIssueProps>
const AddIssue: AddIssueComponent = ({onClose, open, type, inspectionType, issueDetails, onSave}) => {
    return (
        <Modal open={open} position={'top-center'} className={classes['add-issue']}>
            <Header type={type} issueId={issueDetails?.id} />
            <Form onClose={onClose} type={type} inspectionType={inspectionType} issueDetails={issueDetails} onSave={onSave} />
        </Modal>
    );
};

export default AddIssue
