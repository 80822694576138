import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

import Loader from '../Loader';
import Icon, { MaterialIcons } from '../Icon';

import classes from './style.module.scss';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & Omit<LinkProps, 'to'>;

export interface ButtonProps extends HTMLButtonProps {
  to?: string
  block?: boolean
  link?: boolean
  color?: 'primary' | 'red' | 'green' | 'yellow'
  loading?: boolean
  icon?: MaterialIcons
  iconPosition?: 'left' | 'right'
}

export type ButtonComponent = React.FC<ButtonProps>

const Button: ButtonComponent = (
  {
    className,
    type = 'button',
    to,
    block,
    link,
    color,
    loading,
    icon,
    iconPosition = 'left',
    children,
    ...props
  }
) => {
  const buttonProps: HTMLButtonProps = {
    ...props,
    className: classNames(classes['button'], {
      [classes['block']]: block,
      [classes['disabled']]: props.disabled,
      [classes['link']]: link,
      [classes['loading']]: loading,
      [classes['has-icon']]: icon,
      [classes['has-icon-left']]: iconPosition === 'left',
      [classes['has-icon-right']]: iconPosition === 'right'
    }, color ? classes[color] : undefined, className),
    disabled: loading || props.disabled
  };

  const content: React.ReactElement = (
    <>
      <span className={classes['content']}>
        {!!icon && iconPosition === 'left' && <Icon className={classes['icon']} name={icon} />}
        <span>{children}</span>
        {!!icon && iconPosition === 'right' && <Icon className={classes['icon']} name={icon} />}
      </span>
      {loading && <Loader inverse={color === 'primary' || color === 'red' || color === 'green'} />}
    </>
  );

  if (to) {
    // @ts-ignore
    return <Link {...buttonProps} to={to}>{content}</Link>;
  }

  return <button {...buttonProps}>{content}</button>;
};

export default Button
