import React, {useCallback, useState} from 'react';
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import classNames from "classnames";
import TextareaAutosize from '~/app/modules/form/TextareaAutosize';
import UserAvatar from "~/user/UserAvatar";
import Button from '~/app/common/Button';
import useWebSocket from "~/app/hooks/useWebSocket";
import {useStoreState, useStoreActions} from "~/store";

export type FormComponent = React.FC

const Form: FormComponent = () => {
    const {t} = useTranslation("issue.messages");
    const [message, setMessage] = useState('');
    const {sendMessage} = useWebSocket();
    const issue = useStoreState(state => state.issue.data);
    const user = useStoreState(state => state.user.data);
    const addChatUserAction = useStoreActions(actions => actions.issue.addChatUser);

    const messageChange = useCallback((event) => {
        setMessage(event.target.value);
    }, [setMessage]);

    const cancel = useCallback(() => {
        setMessage('');
    }, [setMessage]);

    const send = useCallback(() => {
        if (issue.isChatUser) {
            sendMessage(message);
        } else {
            addChatUserAction(issue.id).then(() => {
                sendMessage(t("joinChatUser", {name: user.name}), 'add');
                sendMessage(message);
            })
        }
        setMessage('');
    }, [user, issue, message, sendMessage, addChatUserAction, t]);

    return (
        <>
            <div className={classes['form']}>
                <UserAvatar className={classes['user-avatar']}/>
                <TextareaAutosize
                    className={classNames(classes['field'])}
                    inputClassName={classes['input']}
                    placeholder={t('write')}
                    onChange={messageChange}
                    value={message}
                    minRows={1}
                    maxRows={6}
                />
            </div>
            {message && <div className={classes['buttons']}>
                <Button className={`${classes['abort']}`} onClick={cancel}> {t('abort')} </Button>
                <Button className={`${classes['submit']}`} onClick={send}> {t('submit')} </Button>
            </div>}
        </>
    );
};

export default Form
