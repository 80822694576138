import { useMemo } from 'react';

import useTranslation from '~/app/hooks/useTranslation';

import { getStatuses, StatusWithLabel } from './common';
import {TypesWithLabel} from "~/app/data/statuses/common";

export enum IssueTypes {
    MINOR = 0,
    CRITICAL = 1,
    MORECRITICAL = 2
}

export const IssueTypeRepaired = 3;

export enum IssueStatuses {
    REGISTERED = 0,
    INPROGRESS = 1,
    COMPLETED = 2,
    CLOSED = 3
}

type IssueStatusesData = StatusWithLabel<IssueStatuses>[]

interface UseIssueStatuses {
    (): IssueStatusesData
}

export const useIssueStatuses: UseIssueStatuses = () => {
    const { t } = useTranslation('issue.statuses');

    return useMemo<IssueStatusesData>(() => (
        getStatuses(IssueStatuses).map(key => ({ label: t(key), value: +key }))
    ), [t]);
};



type IssueTypesData = TypesWithLabel<IssueTypes>[]

interface UseIssueTypes {
    (): IssueTypesData
}

export const useIssueTypes: UseIssueTypes = () => {
    const { t } = useTranslation('issue.types');

    return useMemo<IssueTypesData>(() => (
        getStatuses(IssueTypes).map(key => ({ text: t(key), value: +key }))
    ), [t]);
};