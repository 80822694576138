import React, {useCallback, useState} from 'react';
import {useStoreActions, useStoreState} from '~/store';
import classNames from "classnames";
import Loader from "~/app/common/Loader";
import Icon from "~/app/common/Icon";
import classes from './style.module.scss';
import {Machine} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";
import {notify} from "~/app/helpers/notify";

export interface FavoriteProps {
    id: Machine['id']
    type: 'machine' | 'project'
}

export type FavoriteComponent = React.FC<FavoriteProps>

const Favorite: FavoriteComponent = ({id, type}) => {
    const {t} = useTranslation(`${type}.favorite`);
    const [favoritesUpdating, setFavoritesUpdating] = useState<boolean>(false);

    const inFavorite = useStoreState(state => (type === 'project') ? state.projects.inFavorite(id) : state.machines.inFavorite(id));
    const updateFavoritesAction = useStoreActions(actions => (type === 'project') ? actions.projects.updateFavorites : actions.machines.updateFavorites);
    const fetchFavoriteMachinesIssues = useStoreActions(actions => actions.issue.fetchFavoriteMachinesIssues);
    const isFavoriteAssetsLimitReached = useStoreState(state => state.machines.isFavoriteAssetsLimitReached);
    const favoriteAssetsLimit = useStoreState(state => state.machines.favoriteAssetsLimit);
    const fetchingFavorites = useStoreState(state => state.machines.fetchingFavorites);

    const favoriteClickHandler = useCallback(() => {
        if(type === "machine" && !inFavorite && isFavoriteAssetsLimitReached) return notify(t('info.exceededLimit', {limit: favoriteAssetsLimit}), 'info');

        setFavoritesUpdating(true);
        updateFavoritesAction({machineId: id, projectId: id, favorite: !inFavorite}).finally(() => {
            setFavoritesUpdating(false);
            notify(t(inFavorite ? "success.removed" : "success.added"), 'success');
            type === 'machine' && fetchFavoriteMachinesIssues('all');
        })
    }, [inFavorite, updateFavoritesAction, id, type, t, fetchFavoriteMachinesIssues, isFavoriteAssetsLimitReached, favoriteAssetsLimit]);

    return (
        <div className={classNames(classes['favorite-wrap'], {[classes['in-favorite']]: inFavorite})}>
            {
                favoritesUpdating || (type === "machine" && fetchingFavorites)
                    ? <Loader inline/>
                    : <Icon name={inFavorite ? 'star' : 'star_border'} onClick={favoriteClickHandler}/>
            }
        </div>
    );
};

export default Favorite;