import React from 'react';
import {Project} from '~/types';
import ListItem from './ListItem';
import classes from './style.module.scss';
import Icon from "~/app/common/Icon";
import useTranslation from "~/app/hooks/useTranslation";

export interface ListProps {
    title?: React.ReactNode,
    list: Project[],
    offline?: boolean
}

export type ListComponent = React.FC<ListProps>

const List: ListComponent = ({title, list, offline}) => {
    const {t} = useTranslation('project.listing');
    return (
        <div className={classes['list']}>
            <div className={classes['title']}>
                {title}
                {offline &&
                <div className={classes['offline-title']}>
                    <Icon name={'wifi_off'} size={'15'} className={classes['offline-icon']}/>
                    {t('offline')}
                </div>}
            </div>
            {list.map(item => <ListItem key={item.id} item={item}/>)}
        </div>
    );

}

export default List
