import React, { useMemo } from 'react';
import classes from './style.module.scss';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import AssetDashboardAverageInfo from '~/machines/Details/Dashboard/common/AssetDashboardAverageInfo';
import { useStoreState } from '~/store';
import { chartData, idleTimeHistoryData } from '~/types';
import { IdleHoursParams } from '~/app/data/statuses/machine';

export interface IdleHoursGraphProps {
    idleTimeHistoryData: idleTimeHistoryData
}

export type IdleHoursGraphComponent = React.FC<IdleHoursGraphProps>;

const IdleHoursGraph: IdleHoursGraphComponent = ({ idleTimeHistoryData }) => {
    const idleHoursThresholdValues = useStoreState(state => state.machine.data.idleHoursThresholdValues);
    const averageLine = IdleHoursParams.IDLEHOURSAVERAGELINE;

    const chartData: chartData[] = useMemo(() => {
        let color = '--color-high-performance';
        if (idleHoursThresholdValues.lowThresholdEnd && idleTimeHistoryData.previousWorkDayPercentage <= idleHoursThresholdValues.lowThresholdEnd) {
            color = '--color-high-performance';
        } else if (idleHoursThresholdValues.middleThresholdEnd && idleTimeHistoryData.previousWorkDayPercentage <= idleHoursThresholdValues.middleThresholdEnd) {
            color = '--color-average-performance';
        } else if (idleHoursThresholdValues.highThresholdEnd) {
            color = '--color-low-performance';
        }

        if (idleTimeHistoryData.previousWorkDayPercentageBySubcategory >= idleTimeHistoryData.previousWorkDayPercentage) {
            const bodyColorRange = idleTimeHistoryData.previousWorkDayPercentageBySubcategory - idleTimeHistoryData.previousWorkDayPercentage - averageLine;
            return [
                { value: idleTimeHistoryData.previousWorkDayPercentage, color: color },
                { value: bodyColorRange > 0 ? bodyColorRange : 0, color: '--color-body' },
                { value: averageLine, color: '--dashbaord-primary-text' }
            ]
        } else {
            return [
                { value: idleTimeHistoryData.previousWorkDayPercentageBySubcategory ? idleTimeHistoryData.previousWorkDayPercentageBySubcategory - averageLine : 0, color: color },
                { value: averageLine, color: '--dashbaord-primary-text' },
                { value: idleTimeHistoryData.previousWorkDayPercentage - idleTimeHistoryData.previousWorkDayPercentageBySubcategory, color: color },
            ]
        }
    }, [idleTimeHistoryData.previousWorkDayPercentageBySubcategory, averageLine, idleHoursThresholdValues.highThresholdEnd, idleHoursThresholdValues.lowThresholdEnd, idleHoursThresholdValues.middleThresholdEnd, idleTimeHistoryData.previousWorkDayPercentage]);
    
    const data = useMemo(() => {
        const filteredChartData = chartData.filter((data) => {
            return data.value > 0
        });
        return [
            ...filteredChartData,
            { value: 100 - filteredChartData.reduce((accumulator, currentObject) => accumulator + currentObject.value, 0), color: '--color-body' }
        ]
    }, [chartData]);

    const CustomLabel = ({ viewBox, value }: any) => {
        const { cx, cy } = viewBox;
        return (
            <text x={cx} y={cy - 15} className={classes['idle-time-label']} textAnchor="middle">
                <tspan alignmentBaseline="middle" className={classes['idle-time-in-percentage']}>
                    {value}
                </tspan>
            </text>
        );
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={190}>
                <PieChart>
                    <Legend
                        layout='vertical'
                        verticalAlign='middle'
                        content={<AssetDashboardAverageInfo
                            performanceData={idleTimeHistoryData.previousWorkDayPerformanceCompareToAverage}
                        />}
                        wrapperStyle={{ bottom: '24%' }}
                    />
                    <Pie
                        data={data}
                        cy="58%"
                        startAngle={180}
                        endAngle={0}
                        innerRadius="75%"
                        outerRadius="100%"
                        fill="var(--color-body)"
                        dataKey="value"
                        stroke="none"
                    >
                        {data.map((entry, index) => {
                            return <Cell key={`cell-${index}`} fill={`var(${entry.color})`} />;
                        })}
                        <Label
                            position="center"
                            content={<CustomLabel value={`${idleTimeHistoryData.previousWorkDayPercentage}%`} />}
                        />
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </>
    );
};

export default IdleHoursGraph