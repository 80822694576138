import React, {useCallback} from 'react';
import SearchInput from '~/app/modules/form/Search';
import {API} from '~/app/routes';
import {MachineSearch, Project} from '~/types';
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import {route} from "~/app/helpers/route";
import {MACHINES_ROUTES} from "~/machines/Router";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

export type SearchComponent = React.FC

const Search: SearchComponent = () => {
    const {t} = useTranslation('machine.listing');
    const history = useHistory();
    const {projectId} = useParams<{ projectId: Project['id'] }>();

    const selectHandler = useCallback(({id}: any): void => {
        history.push(route(MACHINES_ROUTES.details, {id, projectId}));
    }, [history, projectId]);

    return (
        <div className={classes['search-wrap']}>
            <SearchInput<MachineSearch>
                placeholder={t('search')}
                className={classes['field']}
                inputClassName={classes['input']}
                searchUrl={API.machines}
                renderItem={item => `${item.intern} ${item.name}`}
                searchKeys={['intern', 'name', 'regNo', 'serialNo']}
                onSelect={selectHandler}
                iconPosition='left'
                />
        </div>
    );
};

export default Search
