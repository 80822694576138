import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { setStatusBarColor } from '~/app/helpers/statusBar';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';
import Loader from '~/app/common/Loader';
import MsalError from '~/auth/MsalError';

import { useStoreActions, useStoreState } from '~/store';
import useTranslation from "~/app/hooks/useTranslation";

export type ProviderComponent = React.FC

const Provider: ProviderComponent = ({ children }) => {
  const {t} = useTranslation('issue');
  const [loading, setLoading] = useState<boolean>(true);

  const checkCurrentLoginUser = useStoreActions(actions => actions.user.checkCurrentLoginUser);
  const fetchPermissions = useStoreActions(actions => actions.user.fetchPermissions);
  const netWorkStatus = useStoreState(state => state.app.offline.status);
  const tenantError = useStoreState(state => state.tenant.tenantError);

  useDidMountEffect(() => {
    setStatusBarColor(getComputedStyle(document.documentElement).getPropertyValue('--color-body'));

    netWorkStatus ? setLoading(false) : checkCurrentLoginUser().then(() => {
      fetchPermissions().then(() => {
        setLoading(false);
      }).catch(() => {
        toast.error(t('error.currentLoginUser'));
        setLoading(false);
      })
    }).catch(() => {
      toast.error(t('error.userPermission'));
      setLoading(false);
    });
  });

  if(tenantError) {
    return <MsalError />
  }

  if (loading) {
    return <Loader size="32" />;
  }

  return <>{children}</>;
};

export default Provider
