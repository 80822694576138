import React, {useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {route} from '~/app/helpers/route';

import usePageTitle from '~/app/hooks/usePageTitle';
import useTranslation from '~/app/hooks/useTranslation';

import {useAppHeader} from '~/app/modules/appHeader';

import {Equipment, Machine, Project} from '~/types';
import {getMachineSubTitle} from '~/machines/helpers';

import {useStoreActions, useStoreState} from '~/store';

import {MACHINES_ROUTES} from '~/machines/Router';

import Header from './Header';
import Specifications from './Specifications';
import Equipments from './Equipments';
import Archives from './Archives';
import {MACHINES_DETAILS_ROUTES} from '~/machines/Details/Router';
import useDidMountEffect from "~/app/hooks/useDidMountEffect";

export type DocumentationComponent = React.VFC;

const Documentation: DocumentationComponent = () => {
    const {id, projectId, equipmentId} = useParams<{ id: Machine['id'], projectId: Project['id'], equipmentId: Equipment['id'] }>();

    const {t} = useTranslation('machine.details');

    usePageTitle(t('informationAndDocuments.title'));

    const {setData} = useAppHeader();

    const machineSpecifications = useStoreState(state => state.documentation.machineSpecifications);
    const activeTab = useStoreState(state => state.documentation.activeTab);
    const parentFolderId = useStoreState(state => state.documentation.parentFolderId);
    const setActiveTab = useStoreActions(actions => actions.documentation.setActiveTab);
    const setParentFolderId = useStoreActions(actions => actions.documentation.setParentFolderId);
    const machineParentArchive = useStoreState(state => state.documentation.machineParentArchive);

    useEffect(() => {
        (machineSpecifications.id && machineSpecifications.id.toString() !== id) && setActiveTab('equipments');
        equipmentId && setActiveTab('specifications');
        setParentFolderId(0);
    }, [id, equipmentId, machineSpecifications, setActiveTab, setParentFolderId]);

    useDidMountEffect(() => {
        setActiveTab('specifications');
        setParentFolderId(0);
    });

    const backFolder = useCallback(() => {
        machineParentArchive && setParentFolderId(machineParentArchive.parentId);
    }, [machineParentArchive, setParentFolderId]);

    const back = useMemo(() => {
        if (parentFolderId) {
            return backFolder;
        } else {
            return equipmentId ? route(MACHINES_DETAILS_ROUTES.documentation, {
                id,
                projectId
            }) : route(MACHINES_ROUTES.details, {id, projectId})
        }
    }, [id, projectId, equipmentId, parentFolderId, backFolder]);

    useEffect(() => {
        setData({
            title: parentFolderId && machineParentArchive ? machineParentArchive['name'] : t('informationAndDocuments.title'),
            subTitle: getMachineSubTitle(machineSpecifications),
            backLink: back,
            showUser: false,
            machineId: '',
            projectId: '',
            parentFolderId: parentFolderId,
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: false
        });
    }, [setData, machineSpecifications, t, back, parentFolderId, machineParentArchive]);

    return (
        <>
            {parentFolderId === 0 && <Header/>}
            {activeTab === 'specifications' && <Specifications/>}
            {activeTab === 'equipments' && <Equipments/>}
            {activeTab === 'archives' && <Archives/>}
        </>
    );
};
export default Documentation
