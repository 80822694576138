import React from 'react';

import {IssueCategory} from 'types/index';

import ListItem, { ListItemProps } from './ListItem/index';

import classes from './style.module.scss';

export interface ListProps extends Pick<ListItemProps, 'onSelect'> {
  list: IssueCategory[]
  activeItem: IssueCategory['id']
}

export type ListComponent = React.FC<ListProps>

const List: ListComponent = ({ list, activeItem, onSelect }) => (
  <div className={classes['list']}>
    {list.map(item => <ListItem key={item.id} item={item} activeItem={activeItem} onSelect={onSelect} />)}
  </div>
);

export default List
