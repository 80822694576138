import React, { useMemo } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { RequiredPermissions } from "~/accessControl";
import AccessDenied from "~/accessControl/AccessDenied";
import { useStoreState } from "~/store";
import { checkPermission } from "~/accessControl/helpers";

export type PrivateRouteProps = RouteProps & {
  requiredPermissions: RequiredPermissions | RequiredPermissions[]
  operator?: "and" | "or" 
}

export type PrivateRouteComponent = React.FC<PrivateRouteProps>

const PrivateRoute: PrivateRouteComponent = props => {
  const permissions = useStoreState(state => state.user.permissions);
  const permitted = useMemo<boolean>(() => {
    if(props.requiredPermissions && Array.isArray(props.requiredPermissions)) {
      if(props?.operator === "and") {
        return props.requiredPermissions.every((permission: RequiredPermissions) => checkPermission(permissions, permission));
      } else {
        return props.requiredPermissions.some((permission: RequiredPermissions) => checkPermission(permissions, permission));
      }
    } else {
      return checkPermission(permissions, props.requiredPermissions);
    }
  }, [permissions, props?.operator, props.requiredPermissions]);
  if(permitted) {
    return <Route {...props} />;
  }
  return <AccessDenied/>;
};

export default PrivateRoute
