import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from '~/store';
import EmptyMessage from './../EmptyMessage';
import List from './List';
import {Machine} from "~/types";
import {useParams} from "react-router-dom";
import Loader from "~/app/common/Loader";

export type EquipmentsComponent = React.VFC;

const Equipments: EquipmentsComponent = () => {
    const {id} = useParams<{ id: Machine['id'] }>();
    const [fetching, setFetching] = useState<boolean>(true);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const machineEquipments = useStoreState(state => state.documentation.machineEquipments);

    const fetchMachineEquipmentsAction = useStoreActions(actions => actions.documentation.fetchMachineEquipments);
    const fetchOfflineMachineEquipmentsAction = useStoreActions(actions => actions.documentation.fetchOfflineMachineEquipments);

    useEffect(() => {
        (netWorkStatus ? fetchOfflineMachineEquipmentsAction : fetchMachineEquipmentsAction)(id).then(() => setFetching(false));
    }, [id, fetchMachineEquipmentsAction, fetchOfflineMachineEquipmentsAction, setFetching, netWorkStatus]);

    if (fetching) {
        return <Loader size="32"/>;
    }

    if (!machineEquipments.length) {
        return <EmptyMessage/>;
    }

    return (
        <>
            <List list={machineEquipments}/>
        </>
    );
};
export default Equipments
