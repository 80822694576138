import { Permissions } from '~/types';
import {RequiredPermissions} from "~/accessControl/index";

export const checkPermission = (permissions: Permissions, requiredPermissions: RequiredPermissions): boolean => {
    const {menu, subMenu, action, subAction} = requiredPermissions;
    if (permissions && menu && permissions[menu]?.status) {
        if (subMenu) {
            const subMenuPermissions = permissions[menu]?.subMenu;
            if (subMenuPermissions && subMenuPermissions[subMenu]?.status) {
                if (action) {
                    const actionPermissions = subMenuPermissions[subMenu]?.actions;
                    if (actionPermissions && actionPermissions[action]?.status) {
                        if (subAction) {
                            const subActionPermissions = actionPermissions[action]?.subActions;
                            return !!(subActionPermissions && subActionPermissions[subAction]?.status);
                        }
                        return true;
                    }
                    return false;
                }
                return true;
            }
            return false;
        }
        return true;
    }
    return false;
}

