import isString from 'lodash/isString';
import React, {useCallback, useEffect, useMemo} from 'react';
import isFunction from 'lodash/isFunction';
import classes from './style.module.scss';
import classNames from 'classnames';

export interface ToggleSwitchProps {
    theme?: string
    enabled?: boolean
    onStateChanged?: (enabled) => void,
    onClick?: () => void,
    className?: string
}

export type ToggleSwitchComponent = React.FC<ToggleSwitchProps>

const ToggleSwitch: ToggleSwitchComponent = props => {
    const {enabled, theme, onClick, className, onStateChanged, ...restProps} = props;

    useEffect(() => {
        isFunction(onStateChanged) && onStateChanged(enabled);
    }, [enabled, onStateChanged]);

    const toggleSwitch = useCallback((e) => {
        isFunction(onClick) && onClick();
    }, [onClick]);

    const themeClass = useMemo(() => `switch--${(theme && isString(theme)) ? theme : 'default'}`, [theme]);
    const toggleClass = useMemo(() => `switch-toggle--${enabled ? 'on' : 'off'}`, [enabled]);

    return (
        <div className={classNames(classes['switch'], classes[themeClass], className)}
             onClick={toggleSwitch} {...restProps}>
            <div className={classNames(classes['switch-toggle'], classes[toggleClass])}></div>
        </div>
    )
}

export default ToggleSwitch;