import _ from 'lodash';

import store from "~/store";
import {Archive, Document} from "~/types";
import QueryBuilder from "~/sqlite/services/queryBuilder";

export class DocumentsOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public reset(documents: Archive[], machineId: string, documentId: number) {
        if (this.SQLite) {
            return new Promise((resolve, reject) => {
                this.checkDocuments(documents, machineId, documentId).then(({addDocuments, deleteDocuments}) => {
                    const qb = new QueryBuilder();
                    this.SQLite.transaction((tx) => {
                        if(deleteDocuments.length){
                            tx.executeSql(qb.deleteIn('Documents' , 'id',  deleteDocuments));
                        }

                        if(addDocuments) {
                            tx.executeSql(qb.insert('Documents', addDocuments));
                        }
                    }, (e) => reject(e), () => resolve());
                })
            });
        }
    }

    public checkDocuments(documentWithFile: Archive[], machineId: string, parentId: number) {
        const qb = new QueryBuilder();
        return new Promise<{addDocuments: Document[], deleteDocuments: string[]}>((resolve, reject) => {
            const whereCondition = [{name: 'parentId', value: parentId.toString()}, {name: 'machineId', value: machineId}]
            console.log(whereCondition)
            this.SQLite.executeSql(
                qb.select('Documents',[], whereCondition), [], (resultSet) => {
                const documents = documentWithFile.map((data) => (
                    {
                        data: JSON.stringify({...data, downloadLink: '', filePath: ''}),
                        documentId: data.id,
                        parentId: parentId,
                        machineId: machineId,
                        downloadLink: '',
                        filePath: ''
                    }
                ))

                const dbDocuments = qb.getAllResult(resultSet);

                const deleteDocuments = dbDocuments
                    .filter((deleteDocument) => !_.some(documents, {data: deleteDocument.data}))
                    .map((data) =>  data.id);

                const addDocuments = documents.filter(
                    (addDocument) => !_.some(dbDocuments, {data: addDocument.data})
                );
                console.log(dbDocuments)
                console.log('document -> delete -> add', deleteDocuments, addDocuments)

                resolve({addDocuments, deleteDocuments});

            }, (e) => {
                return reject(e)
            });
        })
    }

    public getDocuments(machineId: string, parentId: number) {
        if (this.SQLite) {
            return new Promise<Archive[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                const whereCondition = [{name: 'parentId', value: parentId.toString()}, {name: 'machineId', value: machineId}]
                this.SQLite.executeSql(
                    qb.select('Documents',[], whereCondition), [],
                    (resultSet) => {
                        const documents = qb.getAllResult(resultSet).map((doc) => ({
                            ...JSON.parse(doc.data),
                            downloadLink: doc.downloadLink,
                            filePath: doc.filePath,
                        }))
                        return resolve(documents)

                    }, (e) => reject(e));
            });
        }
    }

    public updateDocument(documentId: string, filename: string) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            const whereCondition = [{name: 'documentId', value: documentId}]
            this.SQLite.executeSql(
                qb.update('Documents',{
                    downloadLink: filename,
                    filePath: filename,
                }, whereCondition)
            );
        }
    }
}
