import React from 'react';
import {MachineActiveTabs} from '~/types';
import {useStoreState} from '~/store';
import Dashboard from './Dashboard';
import Registration from './Registration';
import TabHeader from './TabHeader';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import { checkPermission } from '~/accessControl/helpers';
import { MachineTabs } from '~/app/data/statuses/machine';

export interface lastVisitedTab {
    lastVisitedTab: MachineActiveTabs['activeTab']
}

export type DetailsComponent = React.VFC;

const Details: DetailsComponent = () => {
    const permission = useStoreState(state => state.user.permissions);
    const machineDashboardPermission = checkPermission(permission, PERMISSION_GROUPS.machineDashboard);
    const machineRegistrationPermission = checkPermission(permission, PERMISSION_GROUPS.machineRegistration);
    const activeTab = useStoreState(state => state.machine.activeTab);

    return (
        <>
            {machineDashboardPermission && machineRegistrationPermission && <TabHeader/>}
            {activeTab === MachineTabs.DASHBOARD && <Dashboard/>}
            {activeTab === MachineTabs.REGISTRATION && <Registration/>}
        </>
    );
};
export default Details
