import React from 'react';
import moment from 'moment';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';

export type FooterComponent = React.FC

const Footer: FooterComponent = () => {
    const { t } = useTranslation('footer');
    const currentYear = moment().format('YYYY');
    return (
        <div className={classes['footer']}>
            {t('text')} {currentYear}
        </div>
    )
};

export default Footer
