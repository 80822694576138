import React from 'react';
import classNames from 'classnames';

import { getShortName } from '~/user/helpers';
import { useStoreState } from '~/store';

import classes from './style.module.scss';
import { User } from '~/types';

export interface UserAvatarProps {
  className?: string
  active?: boolean
  hovering?: boolean
  userName?: User['name']
  avatar?: User['avatar']
  onClick?: () => void
}

export type UserAvatarComponent = React.FC<UserAvatarProps>

export const UserAvatar: UserAvatarComponent = ({ className, active, hovering, onClick, userName, avatar }) => {
  const user = useStoreState(state => state.user.data);
  const name = userName ? userName : user.name;
  avatar = avatar?.includes('user_default.png') ? "" : avatar;

  const cN = classNames(classes['user-avatar'], className, {
    [classes['active']]: active,
    [classes['hovering']]: hovering,
    [classes['user-name-intials']]: !avatar
  });

  return (
    <div className={cN} onClick={onClick}>
      {avatar ? <img src={avatar} alt={''} /> : getShortName(name)}
    </div>
  );
};

export default UserAvatar
