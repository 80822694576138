import React from 'react';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import Header from "./Header";
import Form from "./Form";

export interface QuantityProps {
    onClose: () => void
    open: boolean
}

export type QuantityComponent = React.FC<QuantityProps>
const Quantity: QuantityComponent = ({onClose, open}) => {
    return (
        <Modal open={open} position={'center'} className={classes['quantity']}>
            <Header/>
            <Form onSelect={onClose}/>
        </Modal>
    );
};

export default Quantity
