import { action, Action, thunk, Thunk } from "easy-peasy";
import { StoreModel } from "~/store";
import { API } from "~/app/routes";
import { CommonModel, getCommonModel } from "~/store/commonStore";
import axios, { CancelTokenSource } from "axios";
import { Notification, NotificationData, NotificationMessage, NotificationPayload, UnreadNotificationCount, UpdateNotificationStatusPayload, UpdateNotificationsData } from "~/types";

export interface NotificationModel extends CommonModel<Notification> {
    notifications: NotificationData['userNotifications'];
    setNotifications: Action<NotificationModel, NotificationData['userNotifications']>;
    totalNotificationsCount: NotificationData['pagination']['totalRecords'];
    setTotalNotificationsCount: Action<NotificationModel, NotificationData['pagination']['totalRecords']>;
    cancelToken: CancelTokenSource | null;
    setCancelToken: Action<NotificationModel, CancelTokenSource | null>;
    fetchNotifications: Thunk<NotificationModel, NotificationPayload, any, StoreModel, Promise<void>>;
    resetNotifications: Action<NotificationModel, void>;

    isSearchOpen: boolean;
    setIsSearchOpen: Action<NotificationModel, boolean>;
    isSearching: boolean;
    setIsSearching: Action<NotificationModel, boolean>;

    notificationPayload: NotificationPayload;
    setNotificationPayload: Action<NotificationModel, NotificationPayload>;

    unreadNotificationCount: number;
    addChatNotification: Action<NotificationModel, NotificationMessage>;
    fetchUnreadNotificationsCount: Thunk<NotificationModel, void, any, StoreModel, Promise<void>>;
    setUnreadNotificationCount: Action<NotificationModel, UnreadNotificationCount>;
    updateNotificationsStatus: Thunk<NotificationModel, UpdateNotificationStatusPayload, void, StoreModel, Promise<void>>;
    updatedNotificationsStatus: Action<NotificationModel, UpdateNotificationsData>;
}

export const notificationModel: NotificationModel = {
    ...getCommonModel<Notification>(API.notifications),

    notifications: [],
    setNotifications: action((state, payload) => {
        state.notifications = payload;
    }),
    totalNotificationsCount: 0,
    setTotalNotificationsCount: action((state, payload) => {
        state.totalNotificationsCount = payload;
    }),
    cancelToken: null,
    setCancelToken: action((state, payload) => {
        state.cancelToken = payload;
    }),
    fetchNotifications: thunk((actions, payload, {getStoreState}) => {
        const storeState = getStoreState().notification;
        // To show loader for search and first time load only
        actions.setFetching(!storeState.notifications.length || storeState.isSearching ? true : false);
        //Check if there are any previous pending requests
        if(storeState.cancelToken != null) {
            storeState.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        actions.setCancelToken(axios.CancelToken.source());
        return axios
            .post<NotificationPayload, NotificationData>(
                `${API.users}${API.notifications}`, payload, { cancelToken: getStoreState().notification.cancelToken?.token }
            )
            .then((data) => {
                const {userNotifications = [], pagination : {totalRecords = 0}} = data;
                actions.setNotifications([
                    ...storeState.isSearching ? [] : storeState.notifications,
                    ...userNotifications
                ]);
                actions.setTotalNotificationsCount(totalRecords);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log(err.message);
                }
            })
            .finally(() => {
                actions.setFetching(false);
                actions.setIsSearching(false);
            });
    }),
    resetNotifications: action((state) => {
        state.notifications = [];
        state.totalNotificationsCount = 0;
        state.notificationPayload.offset = 0;
    }),

    isSearchOpen: false,
    setIsSearchOpen: action((state, payload) => {
        state.isSearchOpen = payload;

        // reload data on search close event
        if (payload === false && state.notificationPayload.searchText && state.notificationPayload.searchText.length > 0) {
            state.notifications = [];
            state.notificationPayload = {searchText: "", offset: 0};
        }
    }),
    isSearching: false,
    setIsSearching: action((state, payload) => {
        state.isSearching = payload;
    }),

    notificationPayload: {
        offset: 0,
        searchText: ""
    },
    setNotificationPayload: action((state, payload) => {
        state.notificationPayload = payload;
    }),

    unreadNotificationCount: 0,
    addChatNotification: action((state, payload) => {
        // do not update the  unreadNotificationCount when a message is sent by the current logged-in user
        state.unreadNotificationCount = payload.messageStatus === false ? state.unreadNotificationCount+1 : state.unreadNotificationCount;
        // remove data for a particular issue id if already available (one row per issue or conversion)
        state.notifications = state.notifications.filter((data) => data.issueId !== payload.issueId);
        // add latest data on top
        state.notifications = [
            {
                id: "",
                ...payload,
                createdAt: payload.dateTime,
                updatedAt: payload.dateTime,
                messageType: payload.type,
                messageStatus: payload.messageStatus
            },
            ...state.notifications,
        ];
    }),
    fetchUnreadNotificationsCount: thunk((actions) => {
        return axios.get<null, UnreadNotificationCount>(`${API.users}/get_unread_notifications_count`)
            .then((data) => {
                actions.setUnreadNotificationCount(data);
            });
    }),
    setUnreadNotificationCount: action((state, payload) => {
        state.unreadNotificationCount = payload.unreadNotificationCount;
    }),
    updateNotificationsStatus: thunk((actions, payload) => {
        return axios
            .post<UpdateNotificationsData, UpdateNotificationsData>(
                `${API.issue}/update_notification_status`, payload
            )
            .then((data) => {
                actions.updatedNotificationsStatus({...data, issueId: payload.issueId});
            });
    }),
    updatedNotificationsStatus: action((state, payload) => {
        state.notifications.map((element) => {
            if(element.issueId === payload.issueId && element.messageStatus !== true) {
                element.messageStatus = true;
            }
            return element;
        })
        state.unreadNotificationCount = state.unreadNotificationCount - (payload?.numberOfNotificationsReads || 0);
    })
};
