import _ from 'lodash';

interface Route {
  (route: string, variables?: { [key: string]: any }): string
}
export const route: Route = (route, variables) => {
  _.forEach(variables, (val, key) => {
    route = route.replace(new RegExp(`(:${key}\\??)`, 'g'), val);
  });

  route = route.replace(/(:\w+\?)/g, '');

  if (/(\/)$/g.test(route)) {
    route = route.slice(0, -1);
  }

  return route;
};
