import { computed, Computed } from 'easy-peasy';
import { StoreModel } from '~/store';
import { PublicClientApplication } from "@azure/msal-browser";
import { MSALBrowser } from "~/auth/msalBrowser";

export type MsalBrowserModel = {
  msalInstance: Computed<MsalBrowserModel, PublicClientApplication, StoreModel>
}

export const msalBrowserModel: MsalBrowserModel = {
  msalInstance: computed([
    (state, storeState) => storeState.tenant.currentTenant
  ], (currentTenant) => {
    return MSALBrowser.init();
  })
};
