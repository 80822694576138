import React, {useCallback, useEffect, useMemo, useState} from 'react';
import usePageTitle from '~/app/hooks/usePageTitle';
import {useAppHeader} from '~/app/modules/appHeader';
import List from './List';
import useTranslation from "~/app/hooks/useTranslation";
import {useStoreActions, useStoreState} from '~/store';
import Header from './Header';
import Footer from './Footer';
import {useParams} from 'react-router-dom';
import {Project} from "~/types";
import {route} from "../app/helpers/route";
import {MACHINES_ROUTES} from "../machines/Router";
import EmptyMessage from './EmptyMessage'
import {ListProps} from "~/machines/Details/List";
import AddIssue, {AddIssueProps} from '~/issues/Add';
import Quantity from './Dialog/Quantity';
import Message from './Message';
import classes from './style.module.scss';
import moment from 'moment';
import {InspectionTypes} from "~/periodicCheck/store";
import { OperatingTypes } from '~/app/data/statuses/operatingData';

export type PeriodicCheckComponent = React.FC<Pick<ListProps, 'onSelect'>>

const PeriodicCheck: PeriodicCheckComponent = () => {
    const {t} = useTranslation("periodicCheck");

    const {setData} = useAppHeader();
    const {projectId} = useParams<{ projectId: Project['id'] }>();
    const machine = useStoreState(state => state.machine.data);
    const updateCheckData = useStoreActions(actions => actions.periodicCheck.updateCheckData);
    const updateCurrentCheck = useStoreActions(actions => actions.periodicCheck.updateCurrentCheck);
    const checksData = useStoreState(state => state.periodicCheck.checksData);
    const inspectionType = useStoreState(state => state.periodicCheck.inspectionType);
    const [issueType, setIssueType] = useState<AddIssueProps['type']>('issue');
    const [openAddIssueDialog, setAddIssueDialog] = useState<boolean>(false);
    const [openQuantityDialog, setOpenQuantityDialog] = useState<boolean>(false);

    usePageTitle(t(`${inspectionType}.title`));

    useEffect(() => {
        setData({
            title: t(`${inspectionType}.title`),
            subTitle: (machine.intern + " - " + machine.name).toUpperCase(),
            backLink: route(MACHINES_ROUTES.details, {id: machine.id, projectId}),
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: false
        });
    }, [setData, machine, t, projectId, inspectionType]);

    const toggleQuantityDialog = useCallback(() => {
        openQuantityDialog && updateCurrentCheck(null);
        setOpenQuantityDialog(!openQuantityDialog);
    }, [setOpenQuantityDialog, openQuantityDialog, updateCurrentCheck]);

    const toggleAddIssueDialog = useCallback(() => {
        openAddIssueDialog && updateCurrentCheck(null);
        setAddIssueDialog(!openAddIssueDialog);
    }, [setAddIssueDialog, openAddIssueDialog, updateCurrentCheck]);

    const changeStatus = useCallback((check, status) => {
        updateCurrentCheck(check);
        if (status === 2) {
            setIssueType('issue');
            toggleAddIssueDialog();
        } else if (check.fluidTypeId) {
            toggleQuantityDialog();
        } else {
            check.status = status;
            check.quantity = 0;
            updateCheckData({...check});
            updateCurrentCheck(null);
        }
    }, [setIssueType, toggleAddIssueDialog, toggleQuantityDialog, updateCheckData, updateCurrentCheck]);

    const showRegisterOperatingTimeMessage = useMemo(() => {
        return [InspectionTypes.DAILY, InspectionTypes.PERIODIC].includes(inspectionType)
          && !moment(machine.lastOperatingTimeDate).isSame(moment(), 'day') 
          && !!machine.operatingTimeAllowed
    }, [machine.lastOperatingTimeDate, machine.operatingTimeAllowed, inspectionType]);

    const showRegisterOperatingCountMessage = useMemo(() => {
        return [InspectionTypes.DAILY, InspectionTypes.PERIODIC].includes(inspectionType)
          && !moment(machine.lastOperatingCountDate).isSame(moment(), 'day') 
          && !!machine.operatingCountAllowed
    }, [machine.operatingCountAllowed, machine.lastOperatingCountDate, inspectionType]);

    if (!checksData.length) {
        return <EmptyMessage/>;
    }

    return (
        <>
            {showRegisterOperatingTimeMessage && <Message type={machine.categoryType} operatingUnitType={OperatingTypes.OPERATING_TIME} />}
            {showRegisterOperatingCountMessage && <Message type={machine.categoryType} operatingUnitType={OperatingTypes.OPERATING_COUNT} />}
            <div className={showRegisterOperatingTimeMessage || showRegisterOperatingCountMessage ? classes['disabled'] : ''}>
                <Header/>
                <List list={checksData} onSelect={changeStatus}/>
            </div>
            <Footer/>
            <AddIssue open={openAddIssueDialog} onClose={toggleAddIssueDialog} type={issueType} inspectionType={inspectionType}/>
            <Quantity open={openQuantityDialog} onClose={toggleQuantityDialog}/>
        </>
    );
};

export default PeriodicCheck
