import React, {useCallback} from 'react';
import SearchInput from '~/app/modules/form/Search';
import {API} from '~/app/routes';
import {Project} from '~/types';
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import {route} from "~/app/helpers/route";
import {MACHINES_ROUTES} from "~/machines/Router";
import {useHistory} from "react-router";

export type SearchComponent = React.FC

const Search: SearchComponent = () => {
    const {t} = useTranslation('project.listing');
    const history = useHistory();

    const selectHandler = useCallback(({id}: any): void => {
        history.push(route(MACHINES_ROUTES.machines, {projectId: id}));
    }, [history]);

    return (
        <div className={classes['search-wrap']}>
            <SearchInput<Project>
                placeholder={t('search')}
                className={classes['field']}
                inputClassName={classes['input']}
                searchUrl={API.projects}
                renderItem={item => `${item.number} ${item.name}`}
                onSelect={selectHandler}
                iconPosition='left'
                />
        </div>
);
};

export default Search
