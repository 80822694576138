import React from 'react';
import classes from './style.module.scss';
import AssetDashboardAverageInfo from '../AssetDashboardAverageInfo';

export interface AssetDashboardPreviousInfoProps {
    title: string
    data: string
    performanceData: number
    defaultColor?: string
    reverseColors?: boolean
}

export type AssetDashboardPreviousInfoComponent = React.FC<AssetDashboardPreviousInfoProps>;

const AssetDashboardPreviousInfo = ({ title, data, performanceData, defaultColor, reverseColors = false }: AssetDashboardPreviousInfoProps) => {
    return (
        <>
            <div className={classes['last-week-average-data']}>
                <div className={classes['title']}>{title}</div>
                <div className={classes['data']}>{data}</div>
                <AssetDashboardAverageInfo performanceData={performanceData} defaultColor={defaultColor} reverseColors={reverseColors} />
            </div>
        </>
    )
}

export default AssetDashboardPreviousInfo;