import React from 'react';
import Modal from 'app/common/Modal/index';
import classes from './style.module.scss';
import Header from './Header/index';
import Content from './Content/index';
import Footer, {FooterProps} from './Footer/index';

export interface OfflineDialogProps {
    onClose: FooterProps['onSelect']
    open: boolean
}

export type OfflineDialogComponent = React.FC<OfflineDialogProps>

const OfflineDialog: OfflineDialogComponent = ({onClose, open}) => {
    return (
        <Modal open={open} position={'center'} className={classes['offline']}>
            <Header/>
            <Content/>
            <Footer onSelect={onClose}/>
        </Modal>
    );
};

export default OfflineDialog
