import React from 'react';
import {useStoreState} from '~/store';
import classes from './style.module.scss';
import UserAvatar from "~/user/UserAvatar";

export type UserDetailsComponent = React.FC

const UserDetails: UserDetailsComponent = () => {
    const user = useStoreState(state => state.user.data);

    return (
        <li className={classes['user']}>
            <UserAvatar className={classes['user-avatar']}/>
            <div className={classes['info']}>
                <span className={classes['name']}>{user.name}</span>
                <span className={classes['email']}>{user.email}</span>
            </div>
        </li>
    );
};

export default UserDetails
