import {useCallback} from "react";
import {useStoreState} from "~/store";
import {RequiredPermissions} from "~/accessControl";
import {checkPermission as checkPermissionFunction} from "~/accessControl/helpers";

const useAccessControl = () => {
    const permissions = useStoreState(state => state.user.permissions);
    const checkPermission = useCallback((requiredPermissions: RequiredPermissions): boolean => checkPermissionFunction(permissions, requiredPermissions), [permissions]);

    return {
        checkPermission
    }
};

export default useAccessControl;