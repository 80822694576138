import React, {useCallback, useState} from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import {NavLink} from 'react-router-dom';
import {useStoreActions} from "~/store";
import classNames from "classnames";

export interface ItemProps {
    key: string,
    label: React.ReactNode
    to: string,
    clickHandler?: () => void
    childItem?: Array<ItemProps>
    itemClassName?: string
    showArrow?: boolean
}

export type ItemComponent = React.FC<ItemProps>

const Item: ItemComponent = ({label, to, childItem, itemClassName, clickHandler, showArrow}) => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const setIsOpen = useStoreActions(actions => actions.app.sidebar.setOpen);
    const menuClickHandler = useCallback((e) => {
        if (childItem) {
            setCollapse(!collapse);
        } else {
            setIsOpen(false);
        }
        if (clickHandler) {
            e.preventDefault();
            clickHandler();
        }
    }, [setIsOpen, setCollapse, childItem, collapse, clickHandler]);

    return (
        <div>
            <NavLink className={classNames(classes['menu-item'], itemClassName ? classes[itemClassName] : '')}
                     activeClassName={classes['active']} to={to} onClick={menuClickHandler}>
                {label}

                {!!childItem ? <Icon name={collapse ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}/> :
                    (showArrow && <Icon name={'keyboard_arrow_right'}/>)}
            </NavLink>

            {!!(childItem && collapse) && childItem.map(item => <Item {...item} itemClassName={'child-menu'}/>)}

        </div>
    );
};

export default Item
