export interface ForeignKeyConstraint {
    referenceTable: string
    referenceKey: string
}

export interface SQLiteTable {
    name: string
    type: string
    isPrimary?: boolean
    defaultValue?: string | number | null
    foreignKey?: ForeignKeyConstraint
}

export const Project: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'name', type: 'text'},
    {name: 'number', type: 'text'},
    {name: 'image', type: 'text', defaultValue: null},
]

export const Machine: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'data', type: 'text'},
    {name: 'categoryImage', type: 'text', defaultValue: null},
];


export const Documents: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'documentId', type: 'string'},
    {name: 'parentId', type: 'string'},
    {name: 'machineId', type: 'string'},
    {name: 'data', type: 'text'},
    {name: 'downloadLink', type: 'text', defaultValue: null},
    {name: 'filePath', type: 'text', defaultValue: null},
];

export const Checks: SQLiteTable[] = [
    /*{name: 'id', type: 'integer', isPrimary: true},
    {name: 'machineId', type: 'integer'},
    {name: 'name', type: 'text'},
    {name: 'type', type: 'text'},
    {name: 'comment', type: 'text', defaultValue: null},
    {name: 'groupId', type: 'text', defaultValue: null},
    {name: 'fluidTypeId', type: 'text', defaultValue: null},*/
];

export const LastPeriodicCheck: SQLiteTable[] = [
    /*{name: 'id', type: 'integer', isPrimary: true},
    {name: 'machineId', type: 'integer'},
    {name: 'userId', type: 'integer'},
    {name: 'userName', type: 'text'},
    {name: 'date', type: 'text', defaultValue: null},*/
];

export const OperatingTimeLog: SQLiteTable[] = [
    /*{name: 'id', type: 'integer', isPrimary: true},
    {name: 'machineId', type: 'integer', defaultValue: null},
    {name: 'operatingTime', type: 'integer', defaultValue: null},
    {name: 'sync', type: 'boolean', defaultValue: 0},
    {name: 'userId', type: 'integer', defaultValue: null},
    {name: 'updatedAt', type: 'text', defaultValue: null},*/
];

export const PeriodicChecks: SQLiteTable[] = [
    /*{name: 'id', type: 'integer', isPrimary: true},
    {name: 'machineId', type: 'integer', defaultValue: null},
    {name: 'projectId', type: 'integer', defaultValue: null},
    {name: 'inspectionType', type: 'integer', defaultValue: null},
    {name: 'checks', type: 'text', defaultValue: null},
    {name: 'sync', type: 'boolean', defaultValue: 0},
    {name: 'userId', type: 'integer', defaultValue: null},
    {name: 'updatedAt', type: 'text', defaultValue: null},*/
];

export const Users: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'name', type: 'text'},
];

export const IssueCategories: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'name', type: 'text'},
];

export const Departments: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'name', type: 'text'},
];

export const Issue: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'machineId', type: 'integer'},
    {name: 'data', type: 'text'},
    {name: 'images', type: 'text', defaultValue: null},
   /* {name: 'type', type: 'integer', defaultValue: 0},
    {name: 'description', type: 'text'},
    {name: 'status', type: 'integer'},
    {name: 'statusDate', type: 'text'},
    {name: 'comment', type: 'text', defaultValue: null},
    {name: 'seenDate', type: 'text', defaultValue: null},
    {name: 'images', type: 'text', defaultValue: null},
    {name: 'categoryName', type: 'text', defaultValue: null},
    {name: 'issueNo', type: 'text', defaultValue: null},
    {name: 'machineIntern', type: 'text', defaultValue: null},
    {name: 'machineName', type: 'text', defaultValue: null},
    {name: 'departmentName', type: 'text', defaultValue: null},
    {name: 'departmentId', type: 'integer', defaultValue: null},
    {name: 'issueDepartmentName', type: 'text', defaultValue: null},
    {name: 'issueDepartmentId', type: 'integer', defaultValue: null},
    {name: 'workOrderNo', type: 'text', defaultValue: null},
    {name: 'isChatUser', type: 'integer', defaultValue: null},
    {name: 'createdAt', type: 'text', defaultValue: null},
    {name: 'createdBy', type: 'text', defaultValue: null},*/
];

export const IssueWork: SQLiteTable[] = [
    /*{name: 'issueId', type: 'integer'},
    {name: 'date', type: 'text'},
    {name: 'comment', type: 'text', defaultValue: null},
    {name: 'name', type: 'text'},
    {name: 'hours', type: 'text'},
    {name: 'cost', type: 'text'},*/
];

export const Chat: SQLiteTable[] = [
   /* {name: 'issueId', type: 'integer'},
    {name: 'user', type: 'text'},
    {name: 'senderId', type: 'text'},
    {name: 'senderName', type: 'text'},
    {name: 'message', type: 'text'},
    {name: 'dateTime', type: 'text', defaultValue: null},
    {name: 'time', type: 'text', defaultValue: null},
    {name: 'actions', type: 'integer', defaultValue: null},
    {name: 'showDate', type: 'string', defaultValue: null},*/
];

export const SyncOfflineData: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'data', type: 'text'},
    {name: 'images', type: 'text', defaultValue: null},
    {name: 'userId', type: 'integer'},
    {name: 'typeOfData', type: 'starting'},
    {name: 'createdAt', type: 'text', defaultValue: null},
]

export const IssuePayload: SQLiteTable[] = [
    /*{name: 'id', type: 'integer', isPrimary: true},
    {name: 'issueType', type: 'text'},
    {name: 'machineId', type: 'integer'},
    {name: 'projectId', type: 'integer'},
    {name: 'departmentId', type: 'integer', defaultValue: null},
    {name: 'performedBy', type: 'integer', defaultValue: null},
    {name: 'categoryId', type: 'integer'},
    {name: 'categoryName', type: 'text', defaultValue: null},
    {name: 'type', type: 'text'},
    {name: 'description', type: 'text', defaultValue: null},
    {name: 'images', type: 'text', defaultValue: null},
    {name: 'sync', type: 'boolean', defaultValue: 0},
    {name: 'userId', type: 'integer', defaultValue: null},
    {name: 'updatedAt', type: 'text', defaultValue: null},*/
];

export const MachineSpecification: SQLiteTable[] = [
    {name: 'id', type: 'integer', isPrimary: true},
    {name: 'hovedNo', type: 'text', defaultValue: null},
    {name: 'data', type: 'text'}
];

export const Schemas = {
    SyncOfflineData,
    Documents,
    Project,
    Machine,
    Checks,
    LastPeriodicCheck,
    OperatingTimeLog,
    PeriodicChecks,
    Users,
    IssueCategories,
    Departments,
    Issue,
    IssueWork,
    Chat,
    IssuePayload,
    MachineSpecification
};
