import React from 'react';
import Modal from 'app/common/Modal/index';
import Menu, {MenuProps} from './Menu/index';

export interface DropDownProps {
    onClose: MenuProps['onSelect']
    open: boolean
}

export type DropdownComponent = React.FC<DropDownProps>

const DropDown: DropdownComponent = ({onClose, open}) => {
    return (
        <Modal open={open} onClose={() => onClose()} position={'bottom'}>
            <div>
                <Menu onSelect={onClose}/>
            </div>
        </Modal>
    );
};

export default DropDown
