import React, { useEffect } from 'react';
import {IssueDetails} from "~/types";
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import List from "./List";
import Form from "./Form";
import {useStoreActions, useStoreState} from "~/store";
import {IssueStatuses} from "~/app/data/statuses/issue";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import AccessControl from "~/accessControl";
import { IssueOrServiceType } from '~/app/data/statuses/chat';

export interface MessagesProps {
    issue: IssueDetails
}

export type MessagesComponent = React.FC<MessagesProps>

const Messages: MessagesComponent = ({issue}) => {
    const {t} = useTranslation("issue.messages");
    const issueId = useStoreState(state => state.issue.data.id);
    const chat = useStoreState(state => state.issue.data.chat);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const updateNotificationsStatusAction = useStoreActions((actions) => actions.notification.updateNotificationsStatus);

    useEffect(() => {
        netWorkStatus === 0 && updateNotificationsStatusAction({issueId: issueId, type: IssueOrServiceType.ISSUE});
    }, [netWorkStatus, chat, issueId, updateNotificationsStatusAction]);
    
    const content = (
        <div className={classes['messages-container']}>
            <div className={classes['title']}>
                {t('title')}
                <span className={classes['message-count']}>{chat.length}</span>
            </div>
            <List/>
            {netWorkStatus === 0 && <Form/>}
        </div>
    );

    if(issue.status === IssueStatuses.COMPLETED || issue.status === IssueStatuses.CLOSED) {
        return (
            <AccessControl requiredPermissions={PERMISSION_GROUPS.readCompletedIssueChat}>
                {content}
            </AccessControl>
        )
    }

    return content;
};

export default Messages
