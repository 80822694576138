import {action, Action, Thunk, thunk} from 'easy-peasy';
import {
    Archive,
    ArchivePayload,
    ArchiveTree,
    DocumentationTabs,
    Equipment,
    MachineSpecification
} from '~/types';
import {API} from "~/app/routes";
import {CommonModel, getCommonModel} from "~/store/commonStore";
import axios from "axios";
import {StoreModel} from "~/store";
import {MachineSpecificationOfflineService} from "~/sqlite/services/machineSpecification";
import FileTransferService from "~/sqlite/services/fileTransfer";
import {DocumentsOfflineService} from "~/sqlite/services/documents";
import {getFileNameFromUrl} from "~/user/helpers";

export interface ArchiveTreePayload {
    data: Archive[],
    tree: ArchiveTree[],
    treeMap: number[],
    parentIndex: number
}

export interface DownloadProgressPayload {
    id: Archive['id'],
    progress: number | undefined
}

export interface DocumentationModel extends CommonModel<MachineSpecification> {
    tabs: DocumentationTabs['activeTab'][]
    activeTab: DocumentationTabs['activeTab']
    setActiveTab: Action<DocumentationModel, DocumentationTabs['activeTab']>

    machineSpecifications: MachineSpecification
    machineSpecificationsHiddenInformation: string[]
    fetchMachineSpecifications: Thunk<DocumentationModel, MachineSpecification['id'], any, StoreModel, Promise<void>>
    fetchOfflineMachineSpecifications: Thunk<DocumentationModel, MachineSpecification['id'], any, StoreModel, Promise<void>>
    fetchedMachineSpecifications: Action<DocumentationModel, MachineSpecification>
    setOfflineMachineSpecifications: Thunk<DocumentationModel, MachineSpecification, any, StoreModel, Promise<void>>

    machineEquipments: Equipment[]
    fetchMachineEquipments: Thunk<DocumentationModel, Equipment['id'], any, StoreModel, Promise<void>>
    fetchOfflineMachineEquipments: Thunk<DocumentationModel, Equipment['id'], any, StoreModel, Promise<void>>
    fetchedMachineEquipments: Action<DocumentationModel, Equipment[]>

    fetchFavoriteMachinesSpecifications: Thunk<DocumentationModel, void, any, StoreModel, Promise<void>>
    fetchedFavoriteMachinesSpecifications: Thunk<DocumentationModel, MachineSpecification[], any, StoreModel, Promise<void>>

    machineArchives: Archive[]
    fetchMachineArchives: Thunk<DocumentationModel, ArchivePayload, any, StoreModel, Promise<void>>
    fetchedMachineArchives: Thunk<DocumentationModel, Archive[], any, StoreModel>
    prepareMachineArchives: Thunk<DocumentationModel, Archive[], any, StoreModel>
    setMachineArchives: Action<DocumentationModel, Archive[]>
    setOfflineMachineArchives: Thunk<DocumentationModel, {documents: Archive[], machineId: string, documentId: number}, any, StoreModel, Promise<void>>
    getOfflineMachineArchives: Thunk<DocumentationModel, { machineId: string, documentId: number}, any, StoreModel, Promise<any>>

    machineParentArchive: Archive | undefined
    setMachineParentArchive: Action<DocumentationModel, Archive | undefined>

    machineArchivesTree: ArchiveTree[]
    currentTreeMap: number[]
    setCurrentTreeMap: Action<DocumentationModel, Archive['id']>
    prepareMachineArchivesTree: Thunk<DocumentationModel, ArchiveTreePayload, any, StoreModel>
    setMachineArchivesTree: Action<DocumentationModel, ArchiveTree[]>

    parentFolderId: number
    setParentFolderId: Action<DocumentationModel, number>


    openArchiveFile: Thunk<DocumentationModel, {archive: Archive, machineId: string}, any, StoreModel>

    downloadProgress: number[]
    setDownloadProgress: Action<DocumentationModel, DownloadProgressPayload>
}

export const documentationModel: DocumentationModel = {
    ...getCommonModel<MachineSpecification>(API.machines),

    tabs: ['specifications', 'equipments', 'archives'],
    activeTab: 'specifications',
    setActiveTab: action((state, payload) => {
        state.activeTab = payload;
    }),

    machineSpecifications: {
        id: '',
        name: '',
        intern: '',
        regNo: ''
    },
    machineSpecificationsHiddenInformation: ['id', 'intern'],
    fetchMachineSpecifications: thunk((actions, payload) => {
        return axios.get<MachineSpecification, MachineSpecification>(`${API.machines}/specifications/${payload}`)
            .then(data => {
                actions.fetchedMachineSpecifications(data);
                actions.setOfflineMachineSpecifications(data);
            });
    }),
    fetchOfflineMachineSpecifications: thunk(async (actions, payload) => {
        const MachineSpecificationOffline = new MachineSpecificationOfflineService();
        let machineSpecifications = await MachineSpecificationOffline.getMachineSpecifications(payload);
        machineSpecifications && actions.fetchedMachineSpecifications(machineSpecifications);
    }),
    fetchedMachineSpecifications: action((state, payload) => {
        state.machineSpecifications = payload;
    }),
    setOfflineMachineSpecifications: thunk(async (actions, payload) => {
        const MachineSpecificationOffline = new MachineSpecificationOfflineService();
        await MachineSpecificationOffline.updateMachineSpecifications(payload);
    }),

    machineEquipments: [],
    fetchMachineEquipments: thunk((actions, payload) => {
        return axios.get<Equipment[], Equipment[]>(`${API.machines}/equipments/${payload}`)
            .then(data => {
                actions.fetchedMachineEquipments(data);
            });
    }),
    fetchOfflineMachineEquipments: thunk(async (actions, payload) => {
        const MachineSpecificationOffline = new MachineSpecificationOfflineService();
        let machineEquipments = await MachineSpecificationOffline.getMachineEquipments(payload);
        machineEquipments && actions.fetchedMachineEquipments(machineEquipments);
    }),
    fetchedMachineEquipments: action((state, payload) => {
        state.machineEquipments = payload;
    }),

    fetchFavoriteMachinesSpecifications: thunk((actions) => {
        return axios.get<MachineSpecification[], MachineSpecification[]>(`${API.machines}/specifications/favorite`)
            .then(data => {
                actions.fetchedFavoriteMachinesSpecifications(data);
            });
    }),
    fetchedFavoriteMachinesSpecifications: thunk(async (actions, payload) => {
        const MachineSpecificationOffline = new MachineSpecificationOfflineService();
        await MachineSpecificationOffline.reset(payload);
    }),

    machineArchives: [],
    fetchMachineArchives: thunk((actions, payload) => {
        return axios.get<Archive[], Archive[]>(`${API.machines}/archives/${payload.machineId}/${payload.parentFolderId}`)
            .then(data => {
                actions.fetchedMachineArchives(data);
                actions.setOfflineMachineArchives({
                    documents: data, machineId: payload.machineId, documentId: payload.parentFolderId}
                )
            });
    }),
    fetchedMachineArchives: thunk((actions, data, helpers) => {
        const {parentFolderId} = helpers.getState();
        actions.setCurrentTreeMap(parentFolderId);

        const {machineArchivesTree, currentTreeMap} = helpers.getState();
        actions.prepareMachineArchivesTree({
            data,
            tree: machineArchivesTree,
            treeMap: currentTreeMap,
            parentIndex: 0
        });
        actions.prepareMachineArchives(data);
    }),
    prepareMachineArchives: thunk(async (actions, data) => {
        const fileTransfer = new FileTransferService();
        const archives = await Promise.all(data.map(async (item): Promise<Archive> => {
            let filePath = item.type === 1 && await fileTransfer.isFileExist(item.downloadLink, 'archives');
            !!filePath && actions.setDownloadProgress({id: item.id, progress: 100});
            return item;
        }));
        actions.setMachineArchives(archives);
    }),
    setMachineArchives: action((state, payload) => {
        state.machineArchives = payload;
    }),
    setOfflineMachineArchives: thunk( async (action, payload) => {
       const DocumentsOffline = new DocumentsOfflineService();
       await DocumentsOffline.reset(payload.documents, payload.machineId, payload.documentId)
    }),
    getOfflineMachineArchives: thunk(async (action, payload, helpers) => {
        const DocumentsOffline = new DocumentsOfflineService();
        return new Promise<Archive[]>(async (resolve) => {
            const documents = await DocumentsOffline.getDocuments(payload.machineId, payload.documentId);
            console.log('documents',documents);
            if (documents) {
                const {parentFolderId} = helpers.getState();
                action.setCurrentTreeMap(parentFolderId);

                const {machineArchivesTree, currentTreeMap} = helpers.getState();
                action.prepareMachineArchivesTree({
                    data: documents,
                    tree: machineArchivesTree,
                    treeMap: currentTreeMap,
                    parentIndex: 0
                });
                action.setMachineArchives(documents);
            }
            resolve()
        })
    }),
    machineParentArchive: undefined,
    setMachineParentArchive: action((state, payload) => {
        state.machineParentArchive = payload
    }),

    machineArchivesTree: [],
    currentTreeMap: [],
    setCurrentTreeMap: action((state, payload) => {
        if (payload) {
            let index = state.currentTreeMap.findIndex((i) => i === payload);

            if (index === -1) {
                state.currentTreeMap = [...state.currentTreeMap, payload];
            } else {
                state.currentTreeMap.length = index + 1;
            }
        } else {
            state.currentTreeMap = [];
        }
    }),
    prepareMachineArchivesTree: thunk((actions, payload, helpers) => {
        let {data, tree, treeMap, parentIndex} = payload;
        let {parentFolderId} = helpers.getState();
        if (parentFolderId === 0) {
            tree = data;
            actions.setMachineParentArchive(undefined);
        } else {
            if (typeof tree !== 'undefined') {
                let index = tree.findIndex((entry) => entry.id === treeMap[parentIndex]);

                if (treeMap[parentIndex] === parentFolderId) {
                    actions.setMachineParentArchive(tree[index]);
                    tree[index].child = data;
                } else {
                    if (typeof tree[index].child !== 'undefined') {
                        parentIndex++;
                        actions.prepareMachineArchivesTree({
                            data,
                            tree: tree[index].child || [],
                            treeMap,
                            parentIndex
                        });
                    }
                }
            }
        }
        actions.setMachineArchivesTree(tree);
    }),
    setMachineArchivesTree: action((state, payload) => {
        state.machineArchivesTree = payload
    }),

    parentFolderId: 0,
    setParentFolderId: action((state, payload) => {
        state.parentFolderId = payload;
    }),

    openArchiveFile: thunk(async (actions, payload) => {
        const {archive: {id, downloadLink, mimeType}, machineId} = payload;
        console.log(id, downloadLink, mimeType)
        const fileTransfer = new FileTransferService();
        const documentsOfflineService = new DocumentsOfflineService();

        if (window['cordova']) {
            const filePath = await fileTransfer.isFileExist(downloadLink, `archives/${machineId}`);
            if(filePath) {
                documentsOfflineService.updateDocument(id.toString(), `archives/${machineId}/${getFileNameFromUrl(downloadLink)}`)
                fileTransfer.openFile(filePath, mimeType)
            } else {
                actions.setDownloadProgress({id, progress: -1});
                axios.create({
                    withCredentials: false,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    }
                }).get(downloadLink, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        actions.setDownloadProgress({id, progress: percentCompleted});
                    }
                }).then(async response => {
                    fileTransfer.saveFileFromBlobResponse(response, downloadLink, `archives/${machineId}`).then((path) => {
                        documentsOfflineService.updateDocument(id.toString(), `archives/${machineId}/${getFileNameFromUrl(downloadLink)}`)
                        //fileTransfer.openFile(path, mimeType)
                    });
                }).catch(() => {
                    actions.setDownloadProgress({id, progress: undefined});
                });
            }
        } else {
            fileTransfer.openFile(downloadLink, mimeType)
        }

    }),

    downloadProgress: [],
    setDownloadProgress: action((state, payload) => {
        if(payload.progress !== undefined) {
            state.downloadProgress[payload.id] = payload.progress;
        } else {
            state.downloadProgress.splice(payload.id, 1);
        }
    })
};
