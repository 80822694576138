import React, { useCallback, useEffect, useState, useMemo } from 'react';

import useDidMountEffect from '~/app/hooks/useDidMountEffect';

import { NotificationMessage } from '~/types';
import { useStoreActions, useStoreState } from '~/store';
import { IssueOrServiceType } from '~/app/data/statuses/chat';

export type WebSocketContextProps = {
  connection?: WebSocket
  reconnect?(message?: string): void
  send?(message: string): void
  close?(): void
}

export const WebSocketContext = React.createContext<WebSocketContextProps>({});

const WebSocketProvider = ({ children }) => {
  const [webSocketConnection, setWebSocketConnection] = useState<WebSocket>();
  const [oldMessage, setOldMessage] = useState('');

  const storeUserData = useStoreState(state => state.user.data);
  const networkStatus = useStoreState(state => state.app.offline.networkStatus);
  const extensionTenantId = useStoreState(state => state.user.tokenData?.extension_tenantid);

  const addChatMessage = useStoreActions(actions => actions.issue.addChatMessage);
  const addChatNotification = useStoreActions(actions => actions.notification.addChatNotification);

  const appendTenantCode = useCallback((message) => {
    if(JSON.parse(message) && extensionTenantId) {
      message = JSON.parse(message);
      message.tenantId = extensionTenantId;
      message = JSON.stringify(message);
    }
    return message;
  }, [extensionTenantId]);

  const createWebSocket = useCallback((): WebSocket => (
    new WebSocket(storeUserData.webSocketURL || '')
  ), [storeUserData.webSocketURL]);

  const reconnect = useCallback((message = '') => {

    if (webSocketConnection && webSocketConnection.readyState !== webSocketConnection.OPEN && webSocketConnection.readyState !== webSocketConnection.CONNECTING) {
      setOldMessage(message);

      webSocketConnection.close();

      setWebSocketConnection(createWebSocket());
    }
  }, [webSocketConnection, setWebSocketConnection, setOldMessage, createWebSocket]);

  const send = useCallback((message) => {
    if (webSocketConnection && webSocketConnection.readyState === webSocketConnection.OPEN) {
      webSocketConnection.send(appendTenantCode(message));
    } else {
      reconnect(message);
    }
  }, [webSocketConnection, reconnect, appendTenantCode]);

  const close = useCallback(() => {
    if (webSocketConnection && webSocketConnection.readyState === webSocketConnection.OPEN) {
      webSocketConnection.close();
    }
  }, [webSocketConnection]);

  const webSocket = useMemo(() => ({
    connection: webSocketConnection,
    reconnect,
    send,
    close,
  }), [webSocketConnection, reconnect, send, close]);

  const onMessage = useCallback(({ data }) => {
    const message: NotificationMessage = JSON.parse(data);

    if (message && message.issueId) {
      addChatMessage(message);
      if(message.type === IssueOrServiceType.ISSUE) {
        const messageReadStatus = storeUserData.id === message.senderId; // Check If sender is same as current user (self-user) then mark message as a read
        addChatNotification({...message, messageStatus: messageReadStatus});
      }
    }
  }, [addChatMessage, addChatNotification, storeUserData]);

  const onOpen = useCallback(() => {
    if (webSocketConnection && storeUserData.id) {
      webSocketConnection.send(
        appendTenantCode(JSON.stringify({
          user: storeUserData.id,
            connectionType: 'open',
        }))
      );
    }

    if (oldMessage) {
      setTimeout(() => {
        if (webSocketConnection) {
          webSocketConnection.send(appendTenantCode(oldMessage));
        }

        setOldMessage('');
      }, 1000);
    }
  }, [storeUserData.id, webSocketConnection, setOldMessage, oldMessage, appendTenantCode]);

  useEffect(() => {
    if (webSocketConnection) {
      webSocketConnection.onmessage = onMessage;
      webSocketConnection.onopen = onOpen;
    }
  }, [webSocketConnection, onMessage, onOpen]);

  useEffect(() => {
    !networkStatus && reconnect();
    const intervalTime = 5000;
    const pingInterval = 60000/intervalTime;
    let ping = 1;
    const interval = setInterval(() => {
      !networkStatus && reconnect();
      if(ping >= pingInterval) {
        (!networkStatus && webSocketConnection && webSocketConnection.readyState === webSocketConnection.OPEN) && webSocketConnection.send('ping');
        ping = 1;
      } else {
        ping++;
      }
    }, intervalTime);
    return () => {
      clearInterval(interval);
      close();
    }
  }, [webSocketConnection, close, reconnect, networkStatus]);

  useDidMountEffect(() => {
    setWebSocketConnection(createWebSocket());
  });

  return (
    <WebSocketContext.Provider value={webSocket}>
      {children}
    </WebSocketContext.Provider>
  );
}
export default WebSocketProvider;