import React, { useMemo } from 'react';
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import AssetDashboardAverageInfo from '~/machines/Details/Dashboard/common/AssetDashboardAverageInfo';
import classes from './style.module.scss';
import { chartData, fuelConsumptionHistoryData } from '~/types';
import { FuelConsumptionParams } from '~/app/data/statuses/machine';

export interface FuelConsumptionGraphProps {
    fuelConsumptionHistoryData: fuelConsumptionHistoryData
}

export type FuelConsumptionGraphComponent = React.FC<FuelConsumptionGraphProps>;

const FuelConsumptionGraph: FuelConsumptionGraphComponent = ({ fuelConsumptionHistoryData }) => {
    
    const maxRange = useMemo(() => {
        return fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory === 0 ? fuelConsumptionHistoryData.previousWorkDayConsumption : FuelConsumptionParams.MAXRANGE * fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory;
    }, [fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory, fuelConsumptionHistoryData.previousWorkDayConsumption]);

    const data: chartData[] = useMemo(() => {
        return [
            { value: fuelConsumptionHistoryData.previousWorkDayConsumption },
        ]
    }, [fuelConsumptionHistoryData.previousWorkDayConsumption]);

    return (
        <>
            <div className={classes['fuel-consumption-data']}>
                {fuelConsumptionHistoryData.previousWorkDayConsumption}
            </div>
            <AssetDashboardAverageInfo performanceData={fuelConsumptionHistoryData.previousWorkDayPerformanceCompareToAverage} defaultColor={'grey'} />
            <ResponsiveContainer width="100%" height={72}>
                <BarChart
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                    barSize={FuelConsumptionParams.BARSIZE}
                >
                    <XAxis hide={true} dataKey="name" scale="point" padding={{ left: 0, right: 0 }} />
                    <YAxis hide={true} type="number" domain={([dataMin, dataMax]) => [FuelConsumptionParams.MINRANGE, maxRange]} />
                    <Bar dataKey="value" fill="var(--color-blue-dasboard-data)" background={{ fill: 'var(--color-body)' }} />
                    <ReferenceLine y={fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory} stroke={fuelConsumptionHistoryData.previousWorkDayConsumption > fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory ? "var(--color-white)" : "var(--dashbaord-primary-text)"} />
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}

export default FuelConsumptionGraph