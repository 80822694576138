import { action, Action, computed, Computed, memo, thunk, Thunk } from 'easy-peasy';
import Axios, { AxiosRequestConfig, Canceler } from 'axios';

import { StoreModel } from './index';

type CommonModelElement = { id: string | number }

export type FetchFilters = {
  id?: string
  userId?: string
  fromDate?: string
  toDate?: string
  page?: number
  results?: number
  orderBy?: string
  sortOrder?: 'asc' | 'desc'
  $filter?: string | { search: string, searchKey?: string }
}

type SaveResponseData = string | number | { id: string | number }

export interface CommonModel<T extends CommonModelElement = CommonModelElement, P = Partial<T>> {
  filters: FetchFilters
  setFilters: Action<CommonModel, FetchFilters>
  clearFilters: Action<CommonModel>

  list: T[]

  fetching: boolean
  setFetching: Action<CommonModel, boolean>
  fetch: Thunk<CommonModel, void | FetchFilters, any, StoreModel, Promise<T[]>>
  fetchCancel: Action<CommonModel>
  fetched: Action<CommonModel, T[]>

  get: Computed<CommonModel, (id: T['id']) => Promise<T>, StoreModel>

  updating: boolean
  setUpdating: Action<CommonModel, boolean>
  update: Thunk<CommonModel, P, any, StoreModel, Promise<T>>
  updated: Action<CommonModel, T>

  saving: boolean
  setSaving: Action<CommonModel, boolean>
  save: Thunk<CommonModel, P, any, StoreModel, Promise<T>>
  saved: Action<CommonModel, T>

  deleting: boolean
  setDeleting: Action<CommonModel, boolean>
  delete: Thunk<CommonModel, string|number, any, StoreModel, Promise<null>>
  deleted: Action<CommonModel, string|number>
}

export let fetchCancel: Canceler;

export const fetch = <T>(fetchUrl: string, params: AxiosRequestConfig['params'] = {}) => Axios.get<T[], T[]>(fetchUrl, {
  params,
  cancelToken: new Axios.CancelToken(cancel => {
    fetchCancel = cancel;
  })
});

export const getCommonModel = <T extends CommonModelElement, P = T>(fetchUrl: string): CommonModel<T, P> => ({
  filters: {},
  setFilters: action((state, payload) => {
    if (payload && typeof payload.$filter === 'object') {
      payload.$filter = `contains(tolower(${payload.$filter.searchKey || 'name'}),'${payload.$filter.search.toLowerCase()}')`;
    }

    state.filters = payload;
  }),
  clearFilters: action(state => {
    state.filters = {};
  }),

  list: [],

  fetching: false,
  setFetching: action((state, payload) => {
    state.fetching = payload;
  }),
  fetch: thunk((actions, payload, { getState }) => {
    actions.setFetching(true);

    if (payload) {
      actions.setFilters(payload);
    }

    return fetch<T>(fetchUrl, getState().filters)
      .then(data => {
        actions.fetched(data);

        return data;
      })
      .finally(() => {
        actions.setFetching(false);
      });
  }),
  fetchCancel: action(() => {
    fetchCancel('Operation canceled by the user');
  }),
  fetched: action((state, payload) => {
    state.list = payload;
  }),

  get: computed(() => memo(
    (id: T['id']): Promise<T> => Axios.get<T, T>(`${fetchUrl}/${id}`),
    100
  )),

  updating: false,
  setUpdating: action((state, payload) => {
    state.updating = payload;
  }),
  update: thunk((actions, payload) => {
    actions.setUpdating(true);

    return Axios.patch<null, null>(fetchUrl, payload)
      .then(() => (
        // @ts-ignore
        Axios.get<T, T>(`${fetchUrl}/${payload.id}`)
          .then(data => {
            actions.updated(data);

            return data;
          })
          .finally(() => {
            actions.setUpdating(false);
          })
      ))
      .catch(error => {
        actions.setUpdating(false);

        return Promise.reject(error);
      });
  }),
  updated: action((state, payload) => {
    const index = state.list.findIndex(({ id: itemId }) => itemId === payload.id);

    if (index !== -1) {
      state.list[index] = payload;
    }
  }),

  saving: false,
  setSaving: action((state, payload) => {
    state.saving = payload;
  }),
  save: thunk((actions, payload) => {
    actions.setSaving(true);

    return Axios.post<SaveResponseData, SaveResponseData>(fetchUrl, payload)
      .then(id => (
        Axios.get<T, T>(`${fetchUrl}/${id}`)
          .then(data => {
            actions.saved(data);

            return data;
          })
          .finally(() => {
            actions.setSaving(false);
          })
      ))
      .catch(error => {
        actions.setSaving(false);

        throw Promise.reject(error);
      });
  }),
  saved: action((state, payload) => {
    state.list.push(payload);
  }),

  deleting: false,
  setDeleting: action((state, payload) => {
    state.deleting = payload;
  }),
  delete: thunk((actions, payload) => {
    actions.setDeleting(true);

    return Axios.delete<null, null>(`${fetchUrl}/${payload}`)
      .then(data => {
        actions.deleted(payload);

        return data;
      })
      .finally(() => {
        actions.setDeleting(false);
      });
  }),
  deleted: action((state, payload) => {
    state.list = state.list.filter(({ id }) => id !== payload);
  })
});
