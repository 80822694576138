import React from 'react';
import classNames from 'classnames';

import useField from './useField';

import Field, { FieldProps } from './Field';

import classes from './style.module.scss';

export interface TextareaPropsDefault extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  ref?: React.Ref<HTMLTextAreaElement>
  inputClassName?: string
}

export type TextareaProps = TextareaPropsDefault & FieldProps

export type TextareaComponent = React.RefForwardingComponent<HTMLTextAreaElement, TextareaProps>

const Textarea: TextareaComponent = (props, ref) => {
  const { fieldProps, inputProps: { inputClassName, ...othersInputProps } } = useField<TextareaPropsDefault>(props);

  return (
    <Field {...fieldProps}>
      <textarea
        {...othersInputProps}
        ref={ref}
        className={classNames(classes['input'], inputClassName)}
        disabled={fieldProps.loading}
      />
    </Field>
  );
};

export default React.forwardRef(Textarea)
