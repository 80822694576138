import React from 'react';
import classNames from 'classnames';
import Icon from '~/app/common/Icon';
import classes from './style.module.scss';

export interface SubTitleProps {
    name?: string
    checked?: boolean
}

export type SubTitleComponent = React.FC<SubTitleProps>

const SubTitle: SubTitleComponent = ({name, checked}) => {
    return (
        <div className={classNames(classes['sub-title'], (checked && classes['ok']))}>
            {name}
            {checked && <Icon name={'check'} className={classes['green-icon']} size={'14'}/>}
        </div>
    );
};

export default SubTitle
