import React from 'react';
import {IssueDetails} from "~/types";
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";

export interface CloseIssueDetailsProps {
  issue: IssueDetails
}

export type CloseIssueDetailsComponent = React.FC<CloseIssueDetailsProps>

const CloseIssueDetails: CloseIssueDetailsComponent = ({issue}) => {
  const {t} = useTranslation("issue.closedIssue");

  return (
    <div className={classes['closed-issue-info']}>
      <div className={classes['title']}>
        {t('title')}
      </div>
      <div className={classes['info']}>
        <div className={classes['time-and-name']}>
          <span className={classes['time']}>{t("closedAt", {date: issue.closedAt})}</span>
          <span className={classes['name']}>{issue.closedBy}</span>
        </div>
        <div className={classes['comment']}>{issue.comment}</div>
      </div>
      <hr className={classes['bottom-line']}/>
    </div>
  );
};

export default CloseIssueDetails
