export enum CertificationTypes {
    NONE = '',
    SK = 1,
    PKK = 2,
    ADR = 3,
    LIFTCONTROL = 4,
    ELECTRICALCONTROL = 5,
    PSV = 6,
    AIRTANK1YEAR = 7,
    EXTINGUISHINGEQUIPMENT = 8,
    AIRTANK2YEAR = 9,
    ELECTRICMOTOR = 10,
    NDT = 11,
    AIRTANK10YEAR = 12
}