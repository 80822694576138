import React, {useEffect} from 'react';
import classes from './style.module.scss';
import {useStoreActions, useStoreState} from '~/store';
import Loader from "~/app/common/Loader";
import ListItem from "./ListItem";

export type RolesProps = {
    onSelect?(): void
}

export type RolesComponent = React.FC<RolesProps>

const Roles: RolesComponent = ({onSelect}) => {
    const roles = useStoreState(state => state.user.roles);
    const fetchingRoles = useStoreState(state => state.user.fetchingRoles);
    const rolesFetchAction = useStoreActions(actions => actions.user.fetchRoles);

    useEffect(() => {
        rolesFetchAction();
    }, [rolesFetchAction]);

    if (fetchingRoles) {
        return (
            <li className={classes['loader']}>
                <Loader size="24" inline/>
            </li>
        );
    }
    return (
        <>
            {roles.length > 1 && roles.map((item, index) => <ListItem key={index} item={item} onSelect={onSelect}/>)}
        </>
    );
};

export default Roles
