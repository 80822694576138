import React from 'react';
import { CartesianGrid, ComposedChart, Label, Legend, Line, ReferenceArea, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useStoreState } from '~/store';
import useTranslation from '~/app/hooks/useTranslation';
import { idleTimeHistoryData, legendData } from '~/types';
import RenderLegend from '../../common/RenderLegend';
import FillPattern from '../../common/fillPattern';

export interface IdleHoursTrendGraphProps {
    idleTimeHistoryTargetData: idleTimeHistoryData['TargerPercentageBySubcategory']
    average: idleTimeHistoryData['previousWorkDayPercentageBySubcategory']
}

export type IdleHoursTrendGraphComponent = React.FC<IdleHoursTrendGraphProps>;

const IdleHoursTrendGraph: IdleHoursTrendGraphComponent = ({ idleTimeHistoryTargetData, average }) => {
    const { t } = useTranslation("machine.dashboard");
    const machine = useStoreState(state => state.machine.data);

    const legendStyle = {
        left: 'var(--size-10)',
        top: '92%',
        height: 'var(--size-40)'
    }

    const payload: legendData[] = [
        { id: 'legend01', value: t('currentUnit'), icon: 'trip_origin' },
        { id: 'legend02', value: t('idleHours.averageUnit'), icon: 'remove' },
    ];

    const pattern = { width: 12, height: 4 };
    const rect = { width: 1, height: 4 };

    return (
        <>
            <ResponsiveContainer width="100%" height={280}>
                <ComposedChart
                    data={machine.idleTimeHistoryData}
                    margin={{ top: 25, right: 30, left: -16, bottom: 5 }}
                >
                    <defs>
                        <FillPattern id="target-pattern" pattern={pattern} rect={rect} fill="var(--color-high-performance)" patternTransform="rotate(45)" />
                    </defs>
                    <CartesianGrid vertical={false} />
                    <XAxis dy={15} tickLine={false} dataKey="day" />
                    <YAxis dx={-18} tickLine={false} type="number" axisLine={false} />
                    <Legend align='left' content={<RenderLegend />} wrapperStyle={legendStyle} payload={payload} />
                    <ReferenceArea
                        stroke="transpernt" ifOverflow="hidden" y1={0} y2={idleTimeHistoryTargetData} fill={`url(#target-pattern)`} fillOpacity=".2"
                        label={
                            <Label
                                value={`${t('onTarget', { targetPercentage: idleTimeHistoryTargetData })}`} fontSize="14" fill="var(--color-high-performance)" position="insideTopLeft" opacity={.5}
                            />
                        }
                    />
                    <ReferenceLine y={average} stroke="var(--dashbaord-primary-text)" />
                    <Line type="linear" dataKey="previousWorkDayPercentage" stroke="var(--color-blue-dasboard-data)" strokeWidth={2} />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}

export default IdleHoursTrendGraph