import React from 'react';
import classNames from 'classnames';

import Dimmer from '../Dimmer';

import classes from './style.module.scss';

export interface LoaderProps {
  className?: string
  size?: '12' | '14' | '16' | '20' | '24' | '32' | '60'
  inline?: boolean | 'center'
  inverse?: boolean
  withDimmer?: boolean
  page?: boolean
  percentage?: number
}

export type LoaderComponent = React.FC<LoaderProps>

const Loader: LoaderComponent = ({ className, size = '24', inline, inverse, withDimmer, page, percentage }) => {
  const loader: React.ReactElement = (
    <div
      className={classNames(classes['loader'], classes['size-' + size], {
        [classes['inline']]: !!inline,
        [classes['center']]: inline === 'center',
        [classes['inverse']]: inverse
      }, className)}
    >
      <svg className={`${classes['spinner']} ${percentage === undefined && classes['spin']}`} viewBox="0 0 66 66">
        <circle className={classNames(classes['path'], classes[`percentage-${percentage !== undefined ? percentage : 'random'}`])} cx="33" cy="33" r="30" />
      </svg>
      {percentage !== undefined && <span className={classes['percentage']}>{percentage}</span>}
    </div>
  );

  if (withDimmer) {
    return <Dimmer className={classes['dimmer']} page={page}>{loader}</Dimmer>;
  }

  return loader;
};

export default Loader
