import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from '~/store';
import EmptyMessage from './../EmptyMessage';
import List from './List';
import {Equipment, Machine} from "~/types";
import {useParams} from "react-router-dom";
import Loader from "~/app/common/Loader";

export type SpecificationsComponent = React.VFC;

const Specifications: SpecificationsComponent = () => {
    const {id, equipmentId} = useParams<{ id: Machine['id'], equipmentId: Equipment['id'] }>();
    const [fetching, setFetching] = useState<boolean>(true);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const machineSpecificationsHiddenInformation = useStoreState(state => state.documentation.machineSpecificationsHiddenInformation);
    const machineSpecifications = useStoreState(state => state.documentation.machineSpecifications);

    const fetchMachineSpecificationsAction = useStoreActions(actions => actions.documentation.fetchMachineSpecifications);
    const fetchOfflineMachineSpecificationsAction = useStoreActions(actions => actions.documentation.fetchOfflineMachineSpecifications);

    useEffect(() => {
        (netWorkStatus ? fetchOfflineMachineSpecificationsAction : fetchMachineSpecificationsAction)(equipmentId || id).then(() => setFetching(false));
    }, [id, fetchMachineSpecificationsAction, fetchOfflineMachineSpecificationsAction, setFetching, netWorkStatus, equipmentId]);

    if (fetching) {
        return <Loader size="32"/>;
    }

    if (!machineSpecifications) {
        return <EmptyMessage/>;
    }

    return (
        <>
            <List list={machineSpecifications} hiddenItems={machineSpecificationsHiddenInformation}/>
        </>
    );
};
export default Specifications
