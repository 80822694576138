import React, { useCallback } from 'react';

import useTranslation from '~/app/hooks/useTranslation';
import { useAppLoader } from '~/app/modules/appLoader';

import {useStoreActions, useStoreState} from '~/store';

import classes from './style.module.scss';
import LanguageSwitcher from '../LanguageSwitcher';

export type MenuProps = {
  onSelect?(): void
}

export type ActionsComponent = React.FC<MenuProps>

const Actions: ActionsComponent = ({ onSelect }) => {
  const { t } = useTranslation('header.userDropdown');
  const networkStatus = useStoreState(state => state.app.offline.status);

  const { showAppLoader, hideAppLoader } = useAppLoader();

  const logoutAction = useStoreActions(actions => actions.user.logout);

  const itemLogoutClickHandler = useCallback((): void => {
    showAppLoader();

    logoutAction({ microsoftLogout: true }).finally(hideAppLoader);

    if (onSelect) {
      onSelect();
    }
  }, [hideAppLoader, logoutAction, onSelect, showAppLoader]);

  return (
    <ul className={classes['menu']}>
      {!networkStatus && <LanguageSwitcher onSelect={onSelect}/>}
      <li className={classes['logout']} onClick={itemLogoutClickHandler}>{t('signOut')}</li>
      <li className={classes['abort']} onClick={onSelect}>{t('abort')}</li>
    </ul>
  );
};

export default Actions
