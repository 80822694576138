import {Schemas} from "./../schemas";

export default class QueryBuilderService {

    public dropTable(tableName: string) {
        return `DROP TABLE IF EXISTS ${tableName}`;
    }

    public createTable(tableName: string) {
        let fkcolumn = [] as any;
        let columnQuery: (string | number | null)[] = Schemas[tableName].map((column) => {
            (column['foreignKey'] !== undefined) && fkcolumn.push(`, FOREIGN KEY (${column['name']}) REFERENCES ${column['foreignKey']['referenceTable']} (${column['foreignKey']['referenceKey']})`);
            return column['name'] + ' ' +
                (column['type'] === 'boolean' ? 'integer' : column['type']) +
                (column['isPrimary'] !== undefined ? ' primary key' : '') +
                (column['defaultValue'] !== undefined ? (' default ' + column['defaultValue']) : '');
        });
        return `CREATE TABLE IF NOT EXISTS ${tableName} (${columnQuery.join(", ")}${fkcolumn.join(" ")})`;
    }

    public insert(tableName: string, data) {
        if (!(Schemas[tableName].length && data.length)) {
            return 'SELECT 1';
        }

        const columns = Schemas[tableName].map(column => column.name);

        let insertQueries: string[] = data.map(row => {
            let queryData: (string | number | null)[] = columns.map((column) => {
                if (typeof row[column] === "boolean") {
                    return row[column] ? 1 : 0;
                }
                return row[column] || row[column] === 0 ? `'${row[column].toString().replace("'", "&#39;")}'` : 'null';
            });
            return `( ${queryData.join(", ")} )`;
        });

        return `INSERT INTO ${tableName} ( ${columns.join(", ")} ) VALUES ${insertQueries.join(", ")}`;
    }

    public select(tableName: string, columns: string[] = [], where: { name: string, value: string, operator?: string }[] = [], orderBy: { name: string, value?: string }[] = []) {
        return `SELECT ${columns.length ? columns.join(', ') : '*'} FROM ${tableName} ${this.whereCondition(where)} ${this.orderBy(orderBy)}`;
    }

    public truncate(tableName: string) {
        return `DELETE FROM ${tableName}`;
    }

    public update(tableName: string, data, where: { name: string, value: string | number, operator?: string }[] = []) {
        if (!(Schemas[tableName].length && data)) {
            return '';
        }

        const columns = Schemas[tableName].map(column => column.name);
        let updateQuery: string[] = [];
        Object.entries(data).forEach(value => {
            columns.includes(value[0]) && updateQuery.push(`${value[0]} = '${value[1]}'`);
        });

        return `UPDATE ${tableName} SET ${updateQuery.join(", ")} ${this.whereCondition(where)}`;
    }

    public whereCondition(where: { name: string, value: string | number, operator?: string }[]) {
        let query = '';
        if (where.length > 0) {
            where.map(function (condition, key) {
                return query += (key === 0 ? ' WHERE ' : ' AND ') + condition.name + (" " + (condition.operator !== undefined ? condition.operator : '=') + " ") + condition.value;
            });
        }
        return query;
    }

    public orderBy(orderBy: { name: string, value?: string }[]) {
        let query = '';
        if (orderBy.length > 0) {
            orderBy.map(function (column, key) {
                return query += `${(key === 0 ? 'ORDER BY' : ',')} ${column.name} ${column.value === 'DESC' ? 'DESC' : 'ASC'}`;
            });
        }
        return query;
    }

    public delete(tableName: string, where: { name: string, value: string, operator?: string }[] = []) {
        return `DELETE FROM ${tableName} ${this.whereCondition(where)}`;
    }

    public deleteIn(tableName: string, name: string, params: string[] = []) {
        return `DELETE FROM ${tableName} WHERE ${name} IN (${params.join(',')})`;
    }

    public getSingleResult(result) {
        if (result && result.rows.length) {
            return result.rows.item(0);
        }
        return {};
    }

    public getAllResult(result) {
        const data: any[] = [];
        if (result && result.rows.length) {
            for (let i = 0; i < result.rows.length; i++) {
                data.push(result.rows.item(i));
            }
        }
        return data;
    }
}
