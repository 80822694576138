import React from 'react';
import Actions from "./Actions";
import Settings from "./Settings";

export type MenuProps = {
  onSelect?(): void
}

export type MenuComponent = React.FC<MenuProps>

const Menu: MenuComponent = ({ onSelect }) => {
  return (
    <>
      <Settings onSelect={onSelect}/>
      <Actions onSelect={onSelect}/>
    </>
  );
};

export default Menu
