import React from "react";
import classes from "./style.module.scss";
import { Notification } from "~/types";
import LinkWrapper from "~/app/common/LinkWrapper";
import { MACHINES_DETAILS_ROUTES } from "~/machines/Details/Router";
import { route } from "~/app/helpers/route";
import moment from "moment";
import classNames from "classnames";
import UserAvatar from "~/user/UserAvatar";

export interface ListItemProps {
    item: Notification;
}

export type ListItemComponent = React.FC<ListItemProps>;

const ListItem: ListItemComponent = ({ item }) => {
    const messageSentDateTime =
        moment(item.createdAt).isSame(moment(), "day")
            ? moment(item.createdAt).format("HH:mm")
            : moment(item.createdAt).format("DD.MM.YYYY");
    const unreadClass = !item.messageStatus ? classes["unread"] : "";
    return (
        <LinkWrapper
            className={`${classes["list-item"]}`}
            to={route(MACHINES_DETAILS_ROUTES.issueDetails, {
                id: item.machineId,
                issueId: item.issueId,
            })}
        >
            <div className={classNames(classes["main-wrapper"], unreadClass)}>
                <div className={classNames(classes['user-avatar-wrapper'], unreadClass)}>
                    <UserAvatar
                        className={classes["user-avatar"]}
                        avatar={item.senderImage}
                        userName={item.senderName}
                    />
                </div>
                <div className={classes["info"]}>
                    <div className={classes["title"]}>
                        <span>{item.intern}</span>
                        <span>{item.category}</span>
                        <span>{item.subcategory}</span>
                    </div>
                    <div className={classes["issue-description"]}>
                        {item.issueDescription}
                    </div>
                    <div className={classes["message-sent-details"]}>
                        <span>{item.senderName}:</span>
                        <span className={classes["message"]}>{item.message}</span>
                    </div>
                </div>
                <div className={classes["message-sent-date"]}>
                    {messageSentDateTime}
                </div>
            </div>
        </LinkWrapper>
    );
};

export default ListItem;
