import React from 'react';

import { ItemProps } from './ListItem';

import ListItem, { ListItemProps } from './ListItem';

import classes from './style.module.scss';

export interface ListProps extends Pick<ListItemProps, 'onSelect'> {
  list: ItemProps[]
}

export type ListComponent = React.FC<ListProps>

const List: ListComponent = ({ list, onSelect }) => (
  <div className={classes['list']}>
    {list.map((item, index) => <ListItem key={index} item={item} onSelect={onSelect} />)}
  </div>
);

export default List
