import React, { useCallback, useState } from 'react'
import { useStoreActions, useStoreState } from '~/store';
import classes from "./style.module.scss";
import ToggleSwitch from "~/app/modules/form/ToggleSwitch/index";
import OfflineDialog from '~/app/App/Messages/Offline/Dialog';
import useTranslation from '~/app/hooks/useTranslation';

export type HeaderOfflineSwitchComponent = React.FC;

const HeaderOfflineSwitch: HeaderOfflineSwitchComponent = () => {
    const { t } = useTranslation("");
    const machine = useStoreState(state => state.machine.data);
    const inFavorite = useStoreState(state => state.machines.inFavorite(machine.id));
    const networkStatus = useStoreState(state => state.app.offline.networkStatus);
    const offlineStatus = useStoreState(state => state.app.offline.status);
    const setOfflineStatus = useStoreActions(actions => actions.app.offline.setStatus);
    const [openOfflineDialog, setOpenOfflineDialog] = useState<boolean>(false);
    const doNotShowDialogAgain = useStoreState(actions => actions.app.offline.doNotShowDialogAgain);

    const toggleOfflineDialog = useCallback((ok) => {
        ok && setOfflineStatus(2);
        setOpenOfflineDialog(!openOfflineDialog);
    }, [setOfflineStatus, setOpenOfflineDialog, openOfflineDialog]);

    const toggleOfflineSwitch = useCallback((): void => {
        offlineStatus === 2 ? setOfflineStatus(networkStatus) : (doNotShowDialogAgain ? setOfflineStatus(2) : setOpenOfflineDialog(true));
    }, [offlineStatus, networkStatus, setOpenOfflineDialog, setOfflineStatus, doNotShowDialogAgain]);

    return (
        <>
            <div className={classes['header']}>
                <div className={classes['date']}>{t('date.primary', { date: new Date() })}</div>
                {inFavorite && !!window['cordova'] && <div className={classes['offline']}>
                    <div className={classes['offline-switch']}>
                        <ToggleSwitch theme="default" className="d-flex" enabled={offlineStatus === 2} onClick={toggleOfflineSwitch} />
                    </div>
                    <div className={classes['offline-text']}>{t('machine.details.offline')}</div>
                    <OfflineDialog onClose={toggleOfflineDialog} open={openOfflineDialog} />
                </div>}
            </div>
        </>
    );
};

export default HeaderOfflineSwitch
