import Axios from 'axios';
import { Tenant, tenantConfig } from './config';

export function getTenant(code: string): Tenant | undefined {
  return tenantConfig[code];
}

export function changeBaseURL(code: string, baseUrl: Tenant['baseUrl']): Tenant['baseUrl'] {
  Axios.defaults.baseURL = `${baseUrl}${process.env.REACT_APP_API_PREFIX}`;
  const currentTenant = getTenant(code);
  currentTenant && (currentTenant.baseUrl = baseUrl);
  return baseUrl;
}
