import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RouterSuspense from '~/app/common/RouterSuspense';

import Instructions from './';

export const REQUESTS_ROUTES = {
    instructions: '/instructions',
};

export type RouterComponent = React.FC

const Router: RouterComponent = () => (
    <RouterSuspense>
        <Switch>
            <Route path={REQUESTS_ROUTES.instructions} component={Instructions}/>
        </Switch>
    </RouterSuspense>
);

export default Router;
