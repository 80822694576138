import React from 'react';

import RouterLoader from '~/app/RouterLoader';

export interface RouterSuspenseProps {

}

export type RouterSuspenseComponent = React.FC<RouterSuspenseProps>;

const RouterSuspense: RouterSuspenseComponent = ({ children }) => (
  <React.Suspense fallback={<RouterLoader />}>
    {children}
  </React.Suspense>
);

export default RouterSuspense
