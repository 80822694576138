import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { ROUTES } from "~/app/Router";
import Loader from "~/app/common/Loader";
import { route } from "~/app/helpers/route";
import usePageTitle from "~/app/hooks/usePageTitle";
import useTranslation from "~/app/hooks/useTranslation";
import { useAppHeader } from "~/app/modules/appHeader";
import { useStoreActions, useStoreState } from "~/store";
import List from "./List";
import EmptyMessage from "./EmptyMessage";
import classes from "./style.module.scss";
import Search from "./Search";
import InfiniteScroll from "react-infinite-scroll-component";
import useDidMountEffect from "~/app/hooks/useDidMountEffect";

export type NotificationsComponent = React.FC;

const Notifications: NotificationsComponent = () => {
    const { setData } = useAppHeader();
    const { t } = useTranslation("notifications");
    const history = useHistory();
    usePageTitle(t("title"));
    const fetching = useStoreState((state) => state.notification.fetching);
    const isSearchOpen = useStoreState((state) => state.notification.isSearchOpen);
    const notifications = useStoreState((state) => state.notification.notifications);
    const totalRecords = useStoreState((state) => state.notification.totalNotificationsCount);
    const notificationsDataFetchAction = useStoreActions((actions) => actions.notification.fetchNotifications);
    const netWorkStatus = useStoreState((state) => state.app.offline.status);
    const notificationPayload = useStoreState((state) => state.notification.notificationPayload);
    const setNotificationPayload = useStoreActions((actions) => actions.notification.setNotificationPayload);
    const prevNotificationPayloadRef = useRef(notificationPayload);

    useEffect(() => {
        setData({
            title: t("title"),
            subTitle: "",
            backLink: history.length > 1 ? history.goBack : route(ROUTES.projects),
            showUser: false,
            machineId: "",
            projectId: "",
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: true,
        });
    }, [setData, t, history]);

    const loadNotifications = useCallback((notificationPayload): void => {
        if (netWorkStatus === 0) {
            notificationsDataFetchAction(notificationPayload);
        }
    }, [notificationsDataFetchAction, netWorkStatus]);

    useEffect(() => {
        if(prevNotificationPayloadRef.current.offset !== notificationPayload.offset || prevNotificationPayloadRef.current.searchText !== notificationPayload.searchText) {
            loadNotifications(notificationPayload);
            prevNotificationPayloadRef.current = notificationPayload;
        }
    }, [loadNotifications, notificationPayload]);

    const fetchData = async () => {
        setNotificationPayload({
            ...notificationPayload,
            offset: notifications.length
        });
    };
    
    useDidMountEffect(() => {
        !notifications.length
            ? loadNotifications(notificationPayload)
            : notifications.length > 0 && totalRecords === 0 && fetchData();
    });

    return (
        <>
            {!netWorkStatus && isSearchOpen && (
                <Search />
            )}
            {fetching ? (
                <Loader size="32" />
            ) : notifications.length ? (
                <InfiniteScroll
                    dataLength={notifications.length}
                    next={fetchData}
                    hasMore={notifications.length < totalRecords}
                    loader={<div className={classes["loader-wrapper"]}><Loader className={classes["loader"]} size="24" /></div>}
                    scrollableTarget="main-scrollable-wrapper"
                >
                    <List list={notifications} />
                </InfiniteScroll>
            ) : (
                <EmptyMessage />
            )}
        </>
    );
};

export default Notifications;
