import {action, Action, thunk, Thunk} from 'easy-peasy';
import {Project} from '~/types';
import {StoreModel} from "~/store";
// import axios from "axios";
import axios from "~/axiosWrapper";
import {API} from "~/app/routes";
import {CommonModel, getCommonModel} from "~/store/commonStore";
import {ProjectOfflineService} from "~/sqlite/services/project";

export interface ProjectModel extends CommonModel<Project> {
    data: Project
    fetchProject: Thunk<ProjectModel, Project['id'], any, StoreModel, Promise<void>>
    fetchedProject: Action<ProjectModel, Project>
    fetchOfflineProject: Thunk<ProjectModel, Project['id'], any, StoreModel, Promise<void>>
}

export const projectModel: ProjectModel = {
    ...getCommonModel<Project>(API.projects),
    data: {
        id: '',
        name: '',
        number: '',
        image: ''
    },
    fetchProject: thunk((actions, payload) => {
        return axios.get<Project, Project>(`${API.projects}/${payload}`)
            .then(data => {
                actions.fetchedProject(data);
            });
    }),
    fetchedProject: action((state, payload) => {
        state.data = payload;
    }),
    fetchOfflineProject: thunk(async (actions, payload) => {
        const ProjectOffline = new ProjectOfflineService();
        let project = await ProjectOffline.projectDetails(payload);
        project && actions.fetchedProject(project);
    })
};
