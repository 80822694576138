import React from 'react';
import classNames from 'classnames';

import 'flag-icon-css/sass/flag-icon.scss';

export interface FlagProps {
  className?: string
  name: string
}

export type FlagComponent = React.FC<FlagProps>

const Flag: FlagComponent = ({ className, name }) => (
  <span className={classNames('flag-icon flag-icon-' + name, className)} />
);

export default Flag
