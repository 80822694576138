import React, {useCallback, useState} from 'react';

import Modal from '~/app/common/Modal';
import UserAvatar from '~/user/UserAvatar';

import Menu from './Menu';

import classes from './style.module.scss';

export type UserDropdownComponent = React.FC

const UserDropdown: UserDropdownComponent = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const triggerOpen = useCallback((state: boolean) => (): void => {
        setIsOpen(state);
    }, []);

    return (
        <>
            <UserAvatar className={classes['user-avatar']} onClick={triggerOpen(true)}/>

            <Modal open={isOpen} onClose={triggerOpen(false)} position={'bottom'} className={classes['user-actions']}>
                <div>
                    <Menu onSelect={triggerOpen(false)}/>
                </div>
            </Modal>
        </>
    );
};

export default UserDropdown
