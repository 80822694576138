import { useStoreActions } from '~/store';

export interface UseAppLoader {
  (): {
    setAppLoading: (state: boolean) => void
    showAppLoader: () => void
    hideAppLoader: () => void
  }
}

export const useAppLoader: UseAppLoader = () => {
  const setLoading = useStoreActions(actions => actions.app.appLoader.setLoading);

  return {
    setAppLoading: setLoading,
    showAppLoader: () => setLoading(true),
    hideAppLoader: () => setLoading(false)
  };
};
