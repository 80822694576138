import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import Icon from '~/app/common/Icon';

import useDidMountEffect from '~/app/hooks/useDidMountEffect';
import useTranslation from '~/app/hooks/useTranslation';

import useField from '../useField';
import Field, { FieldProps } from '../Field';

import Preview from './Preview';
import DropDown, { DropDownProps } from './DropDown';

import classes from './style.module.scss';

export type FilePayload = {
  file: File
  previewUrl: string
}

export type FilePropsDefault = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  ref?: React.Ref<HTMLInputElement>
  inputClassName?: string
  maxFiles?: number
  preview?: boolean
}

export type FileProps = FilePropsDefault & FieldProps

export type FileComponent = React.FC<FileProps>

const FileInput: FileComponent = props => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false);
  const [files, setFiles] = useState<FilePayload[]>([]);

  const { t } = useTranslation('system.form.file');

  const {
    fieldProps,
    inputProps: { placeholder, onChange, maxFiles, defaultValue, preview, inputClassName, ...othersInputProps },
  } = useField<FilePropsDefault>(props);

  const toggleStatusBar = useCallback((): void => {
    if (!window['StatusBar']) {
      return;
    }

    window['StatusBar'].hide();
    window['StatusBar'].show();
  }, []);

  const updateFiles = useCallback((files: FilePayload[]): void => {
    setFiles(files);

    if (onChange) {
      // @ts-ignore
      onChange({ target: { name: props.name, value: files.map(({ file }) => file) } });
    }
  }, [onChange, props.name, setFiles]);

  const pictureSuccessHandler = useCallback((images): void => {
    toggleStatusBar();

    window['resolveLocalFileSystemURL'](images, fileEntry => {
      fileEntry.file(file => {
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const fileObj: any = new Blob([reader.result], { type: file.type });
            fileObj.name = file.name;
            fileObj.lastModified = file.lastModified;

            updateFiles([...files, {
              previewUrl: URL.createObjectURL(fileObj),
              file: fileObj as File,
            }]);
          }
        };

        reader.readAsArrayBuffer(file);
      });
    });
  }, [files, updateFiles, toggleStatusBar]);

  const pictureErrorHandler = useCallback((): void => {
    toggleStatusBar();
  }, [toggleStatusBar]);

  const selectFile = useCallback((captureOption) => {
    if (window['navigator'] && window['navigator']['camera']) {
      window['navigator']['camera'].getPicture(pictureSuccessHandler, pictureErrorHandler, {
        quality: 100,
        destinationType: window['Camera'].DestinationType.FILE_URL,
        correctOrientation: true,
        ...(captureOption === 1 && { sourceType: window['Camera'].PictureSourceType.PHOTOLIBRARY }),
      });
    }
  }, [pictureSuccessHandler, pictureErrorHandler]);

  const toggleDropDown: DropDownProps['onClose'] = useCallback((captureOption): void => {
    setIsOpenDropDown(!isOpenDropDown);

    if (captureOption !== undefined) {
      selectFile(captureOption);
    }
  }, [isOpenDropDown, setIsOpenDropDown, selectFile]);

  const triggerFileInputClick = useCallback((e): void | false => {
    e.preventDefault();

    if (fieldProps.loading || othersInputProps.disabled || maxFiles === files.length) {
      return false;
    }

    if (window['cordova'] && window['navigator']) {
      toggleDropDown();

      return false;
    }

    inputFileRef.current?.click();

    return false;
  }, [toggleDropDown, fieldProps, othersInputProps, maxFiles, files]);

  const triggerChange = useCallback((e): void => {
    if (maxFiles && ((files.length + e.target.files.length) > maxFiles)) {
      toast.error(t('exceedMaxFiles', { max: maxFiles }));

      return;
    }

    updateFiles([
      ...files,
      ...[...e.target.files].map(file => ({
        previewUrl: URL.createObjectURL(file),
        file: file,
      })),
    ]);
  }, [files, maxFiles, t, updateFiles]);

  const removeFile = useCallback((index) => {
    files.splice(index, 1);

    updateFiles([...files]);
  }, [updateFiles, files]);

  useDidMountEffect(() => {
    if (defaultValue) {
      setFiles((Array.isArray(defaultValue) ? defaultValue : [defaultValue]).map((file) => {

        return {
          previewUrl: typeof file === 'string' ? file : URL.createObjectURL(file),
          file: file,
        };
      }))
    }
  });

  return (
    <Field {...fieldProps}>
      <input
        {...othersInputProps}
        ref={inputFileRef}
        className={classNames(classes['input'], inputClassName)}
        type="file"
        accept="image/*"
        multiple={!!maxFiles}
        disabled={fieldProps.loading || othersInputProps.disabled || maxFiles === files.length}
        value=""
        onChange={triggerChange}
      />

      {(files.length > 0 && preview) && <Preview files={files} removeFile={removeFile} />}

      <div className={classes['file-input-container']} onClick={triggerFileInputClick}>
        <Icon name={'camera_alt'}/>
        <div className={classes['title']}>{placeholder}</div>
        {!!maxFiles && (
          <div className={classes['description']}>{t('maxFilesDescription', { max: maxFiles })}</div>
        )}
      </div>

      <DropDown open={isOpenDropDown} onClose={toggleDropDown}/>
    </Field>
  );
};

export default FileInput

