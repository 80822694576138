import React, { useEffect } from 'react'
import IdleHoursReportCard from './IdleHours/IdleHoursReportCard';
import { useStoreActions, useStoreState } from '~/store';
import classes from "./style.module.scss";
import FuelConsumptionReportCard from './FuelConsumption/FuelConsumptionReportCard';
import InspectionReportCard from './Inspection/InspectionReportCard';
import AccessControl from '~/accessControl';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import { MachineTypes } from '~/app/data/statuses/machine';
import HeaderOfflineSwitch from '../HeaderOfflineSwitch';
import MissingDataBanner from './common/MissingDataBanner';
import EmptyMessage from './EmptyMessage';
import Loader from '~/app/common/Loader';
import { getMachineSubTitle } from '~/machines/helpers';
import { MACHINES_ROUTES } from '~/machines/Router';
import { useAppHeader } from '~/app/modules/appHeader';
import { route } from '~/app/helpers/route';
import { useParams } from 'react-router';
import { Machine, Project } from '~/types';

export type DashboardComponent = React.FC;

const Dashboard: DashboardComponent = () => {
    const machine = useStoreState(state => state.machine.data);
    const dataNotAvailableSinceDate = useStoreState(state => state.machine.dataNotAvailableSinceDate);
    const fetching = useStoreState(state => state.machine.fetching);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
    const setDataNotAvailableSinceDate = useStoreActions(actions => actions.machine.setDataNotAvailableSinceDate);

    const {setData} = useAppHeader();

    useEffect(() => {
        setData({
            backLink: route(MACHINES_ROUTES.machines, {projectId}),
            title: (machine.name || '').toUpperCase(),
            subTitle: getMachineSubTitle(machine),
            showUser: false,
            machineId: netWorkStatus === 0 ? id : '',
            projectId: '',
            parentFolderId: 0,
            showMachineMessages: false,
            isIssueDetailPage: true,
            notification: true,
            search: false
        });
    }, [setData, id, machine, projectId, netWorkStatus]);

    useEffect(() => {
        setDataNotAvailableSinceDate(null);
    }, [setDataNotAvailableSinceDate]);

    if (fetching) {
        return <Loader size="32" />;
    }

    if (!(machine && (machine.idleTimeHistoryData?.length || machine.fuelConsumptionHistoryData?.length || machine.inspectionData?.length))) {
        return <EmptyMessage />;
    }

    return (
        <>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.machineDashboard}>
                <HeaderOfflineSwitch />
                {dataNotAvailableSinceDate && <MissingDataBanner date={dataNotAvailableSinceDate} />}
                <div className={classes['dashboard-container']}>
                    <AccessControl requiredPermissions={PERMISSION_GROUPS.readIdleHoursHistoryReport}>
                        {!!machine?.idleTimeHistoryData?.length && <IdleHoursReportCard idleTimeHistoryData={machine.idleTimeHistoryData[machine.idleTimeHistoryData.length - 1]} />}
                    </AccessControl>
                    <AccessControl requiredPermissions={PERMISSION_GROUPS.readFuelConsumptionHistoryReport}>
                        {!!machine?.fuelConsumptionHistoryData?.length && machine?.categoryType !== MachineTypes.EQUIPMENT && <FuelConsumptionReportCard fuelConsumptionHistoryData={machine.fuelConsumptionHistoryData[machine.fuelConsumptionHistoryData.length - 1]} />}
                    </AccessControl>
                    <AccessControl requiredPermissions={PERMISSION_GROUPS.readPeriodicChecksHistoryReport}>
                        {!!machine?.inspectionData?.length && !!machine?.inspectionDataOfLast10Weeks?.length && <InspectionReportCard inspectionData={machine.inspectionData[machine.inspectionData.length - 1]} />}
                    </AccessControl>
                </div>
            </AccessControl>
        </>
    );
};

export default Dashboard
