import React from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import classes from "./style.module.scss";
import { Certification } from '~/types';
import FlashMessage from 'app/common/FlashMessage/index';
import { CertificationTypes } from '~/app/data/statuses/certification';
import moment from 'moment';

export interface certificationTypeProps {
    certification: Certification
}

export type CertificationInfoComponent = React.FC<certificationTypeProps>

const CertificationInfo: CertificationInfoComponent = ({certification}) => {
    const { t } = useTranslation("machine.certification");
    
    if (certification.isApproved) { // If next date is available and next date >= current date then use db status else the status will be not-approved
        certification.isApproved =
            certification.nextDate && moment.utc(certification.nextDate, "YYYY-MM-DD").isSameOrAfter(moment.utc(moment().format('YYYY-MM-DD'), "YYYY-MM-DD"))
                ? certification.isApproved
                : false;
    }
    const dueStatus: boolean = certification.nextDate
        ? moment.utc(certification.nextDate, "YYYY-MM-DD").isBetween(moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"), moment.utc(moment().add(1, "month").format("YYYY-MM-DD"), "YYYY-MM-DD"), 'days', '[)')
        : false;

    if(certification?.type === undefined || certification?.type === CertificationTypes.NONE) {
        return <></>;
    }

    return (
        <FlashMessage type={dueStatus ? 'dueSoon' : (certification.isApproved ? 'success' : 'error')}>
            <div className={classes['certification-info']}>
                <span>{t(`type.${certification.type}`)}: {t(`status.${certification.isApproved ? 'approved' : 'not-approved'}`)}</span>
                <span>{t(`${certification.isApproved ? 'nextInspectionDeadline' : 'inspectionDeadline'}`, { date: certification.nextDate })}</span>
            </div>
        </FlashMessage>
    );
};

export default CertificationInfo
