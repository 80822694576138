import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import Icon from '~/app/common/Icon';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';

import Text, { TextProps } from '../../Text';

import classes from './style.module.scss';

export interface InputProps extends Omit<TextProps, 'ref'> {
  onSearch: (value: string) => void
  value?: string
}

export type InputComponent = React.FC<InputProps>

const Input: InputComponent = ({ defaultValue, fetching, onSearch, ...props }) => {
  const inputElRef = useRef<HTMLInputElement>(null);
  
  const [value, setValue] = useState<string>(props?.value || '');

  const debouncedSearch = useMemo(() => _.debounce((value: string): void => {
    onSearch(value);
  }, 500), [onSearch]);

  const onSearchTriggerDebounce = useCallback((value): void => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const inputChangeHandler = useCallback(({ target }: React.FormEvent<HTMLInputElement>) => {
    const { value } = (target as HTMLInputElement);

    setValue(value);
    
    onSearchTriggerDebounce(value);
  }, [onSearchTriggerDebounce]);

  const inputClear = useCallback((): void => {
    setValue('');

    if (inputElRef.current) {
      inputElRef.current.focus();
    }

    onSearch('');
  }, [onSearch]);
  
  useDidMountEffect(() => {
    if (defaultValue) {
      setValue(String(defaultValue));
    }
  });
  
  return (
    <Text
      {...props}
      ref={inputElRef}
      autoFocus
      className={classNames(classes['field'], props.className)}
      inputClassName={classNames(classes['input'], props.inputClassName)}
      label={undefined}
      icon={ value ? <Icon name="close" onClick={inputClear} /> : 'search'}
      description={undefined}
      value={value}
      onChange={inputChangeHandler}
      fetching={fetching}
    />
  );
};

export default Input
