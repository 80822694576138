import React from 'react';
import classNames from 'classnames';
import Dimmer from '~/app/common/Dimmer';

import classes from './style.module.scss';
import ReactDOM from 'react-dom';
import Icon from "~/app/common/Icon";

export interface LightBoxProps {
    open?: boolean
    onClose?: () => void
    className?: string
    imageUrl: string
}

export type LightBoxComponent = React.FC<LightBoxProps>

const LightBox: LightBoxComponent = ({imageUrl, open = false, onClose, className = ''}) => ReactDOM.createPortal((
    <Dimmer show={open} page>
        <Dimmer.AnimationContent
            classNames={{
                enter: classes['enter'],
                enterActive: classes['enter-active'],
                exit: classes['exit'],
                exitActive: classes['exit-active'],
            }}>
            <div className={classNames(classes['light-box'], className)}>
                <Icon name={'close'} className={classes['close']} onClick={onClose}/>
                <img src={imageUrl} alt={''} className={classes['image']}/>
            </div>
        </Dimmer.AnimationContent>
    </Dimmer>
), (document.getElementById('root') as HTMLElement));

export default LightBox
