import React, {useCallback} from 'react';
import classes from './style.module.scss';
import Button from '~/app/common/Button';
import useTranslation from '~/app/hooks/useTranslation';
import {useStoreState, useStoreActions} from "~/store";
import useForm from "~/app/modules/form/useForm";
import {notify} from '~/app/helpers/notify';
import {OperatingUnitTypePayload} from "~/types";
import { OperatingTypes } from '~/app/data/statuses/operatingData';
import { OperatingTimePayload } from '~/machines/store/machine';

export interface FooterProps {
    onSelect: () => void
    operatingUnitType: OperatingUnitTypePayload
}

export type FooterComponent = React.FC<FooterProps>;

const Footer: FooterComponent = ({onSelect, operatingUnitType}) => {
    const {t} = useTranslation(operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'operatingCount' : 'operatingTime');
    const loading = useStoreState(state => state.machine.updating);
    const machine = useStoreState(state => state.machine.data);
    const registerOperatingTimeOrCount = useStoreActions(actions => actions.machine.registerOperatingTimeOrCount);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const offlineRegisterOperatingTimeOrCountAction = useStoreActions(actions => actions.machine.offlineRegisterOperatingTimeOrCount);
    const {formState, values, formEvents: {onSubmit}} = useForm<Partial<OperatingTimePayload>>({
        formName: 'registerOperatingData'
    });
    const submitClickHandler = useCallback((): void => {
        onSubmit(({formState: {invalid}}) => {
            if (invalid) {
                return;
            }
            (netWorkStatus ? offlineRegisterOperatingTimeOrCountAction : registerOperatingTimeOrCount)({
                machineId: machine.id,
                operatingTime: values.operatingTime as OperatingTimePayload["operatingTime"],
                type: operatingUnitType
            }).then(() => {
                onSelect();
                notify(t("success"), "success");
            });
        })();
    }, [onSubmit, values, onSelect, registerOperatingTimeOrCount, machine, t, netWorkStatus, offlineRegisterOperatingTimeOrCountAction, operatingUnitType]);

    return (
        <div className={`${classes['footer']}`}>
            <Button
                className={classes['abort']}
                onClick={onSelect}>{t('cancel')}</Button>
            <Button
                className={`${classes['register']} ${classes[(formState.touched && !formState.invalid) ? 'valid' : 'invalid']}`}
                loading={loading}
                onClick={submitClickHandler}>
                {t(`${operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'registerNewCounterBtn' : (machine.categoryType === 'vehicle' ? 'registerNewKm' : 'registerNewTimer')}`)}
            </Button>
        </div>
    );
};

export default Footer
