import React from 'react';
import classes from "./style.module.scss";
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import { Moment } from 'moment';

export interface MissingDataBannerProps {
    date: Moment
}

export type MissingDataBannerComponent = React.FC<MissingDataBannerProps>;

const MissingDataBanner: MissingDataBannerComponent = ({date}) => {
    const { t } = useTranslation("machine.dashboard");

    return (
        <>
            <div className={classes['missing-data-container']}>
                <div className={classes['missing-data-banner']}>
                    <span className={classes['message']}>{t('dataNotUpdated', { date })}</span>
                    <Icon name='info' className={classes['icon']} />
                </div>
            </div>
        </>
    );
};

export default MissingDataBanner
