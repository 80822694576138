import React from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import {AddIssueProps} from './../index';
import { IssueDetails } from '~/types';

export interface HeaderProps {
    type: AddIssueProps['type']
    issueId?: IssueDetails['id']
}

export type HeaderComponent = React.FC<HeaderProps>;

const Header: HeaderComponent = ({ type, issueId }) => {
    const {t} = useTranslation('issue');
    return (
        <div className={classes['model-header']}>
            {!issueId && <Icon name={type === 'repair' ? 'build' : 'report_problem'} className={classes['icon']}/>}
            <div className={classes['header-content']}>
                <label className={classes['title']}>
                    {t(issueId ? 'updateIssue' : (type === 'repair' ? 'addRepair' : 'addIssue'))}
                </label>
            </div>
        </div>
    );
};

export default Header
