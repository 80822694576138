import {Project} from '~/types';
import store from "~/store";
import QueryBuilder from "./queryBuilder";
import FileTransfer from "./fileTransfer";
import _ from 'lodash'
import {getFileNameFromUrl} from "~/user/helpers";

export class ProjectOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public reset(projects: Project[]) {
        if (this.SQLite) {
            console.log('downloadImages')
            return new Promise((resolve, reject) => {
                this.downloadProjectsImages(projects).then(({projects, deleteProjects}) => {
                    const qb = new QueryBuilder();
                    this.SQLite.transaction((tx) => {
                        const deleteCondition = deleteProjects.map((data) => data.id)
                        tx.executeSql(qb.deleteIn('Project' , 'id',  deleteCondition));
                        tx.executeSql(qb.insert('Project', projects));
                    }, () => reject(), () => resolve());
                });
            });
        }
    }

    public downloadProjectsImages(projects: Project[]) {
        var allPromise: Promise<Project>[] = [];
        const qb = new QueryBuilder();
        return new Promise<{projects: Project[], deleteProjects: Project[]}>((resolve, reject) => {
            this.SQLite.executeSql(qb.select('Project'), [], (resultSet) => {
                const projectsWithoutUrl = projects.map(
                    (data) => (
                        {...data, image: `projectImages/${getFileNameFromUrl(data.image)}`}
                    ))
                const dbProjects = qb.getAllResult(resultSet);
                const deleteProjects = dbProjects.filter((data) => !_.some(projectsWithoutUrl, { ...data, image: data.image }));
                const addProjects = projects.filter(
                    (data, index) => !_.some(dbProjects, {...data, image: projectsWithoutUrl[index].image})
                );
                console.log('delete -> add', deleteProjects, addProjects)

                addProjects.forEach((project) => {
                    allPromise.push(this.downloadProjectImages(project));
                });

                Promise.all(allPromise).then(projects => {
                    resolve({projects, deleteProjects});
                });

            }, (e) => reject({projects: [], deleteProjects: []}));
        })
    }

    public downloadProjectImages(project: Project) {
        return new Promise<Project>((resolve) => {
            if (project.image) {
                const fileTransfer = new FileTransfer();
                fileTransfer.downloadFileFromURL(project.image, 'projectImages').then((image) => resolve({...project, ...{image: image}}));
            } else {
                resolve(project);
            }
        });
    }

    public getAll() {
        if (this.SQLite) {
            return new Promise<Project[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('Project'), [], (resultSet) => resolve(qb.getAllResult(resultSet)), (e) => reject());
            });
        }
    }

    public projectDetails(id) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            let projectData;
            let where = [{name: 'id', value: id}];
            return new Promise<Project>((resolve, reject) => {
                this.SQLite.transaction((SQLite) => {
                    SQLite.executeSql(qb.select('Project', [], where), [], (SQLite, result) => {
                        projectData = qb.getSingleResult(result);
                    });
                }, () => resolve(projectData), () => {
                    resolve({...projectData});
                });
            });
        }
    }
}
