import { action, Action } from 'easy-peasy';

export interface AppLoaderModel {
  loading: boolean
  setLoading: Action<AppLoaderModel, boolean>
}

export const appLoaderModel: AppLoaderModel = {
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = (payload !== undefined ? payload : !state.loading);
  })
};
