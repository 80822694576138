import React, {useMemo} from 'react';
import FlashMessage from "~/app/common/FlashMessage";
import {useStoreState} from "~/store";
import {IssueTypes} from "~/app/data/statuses/issue";
import useTranslation from "~/app/hooks/useTranslation";
import {MACHINES_DETAILS_ROUTES} from "~/machines/Details/Router";
import {route} from "~/app/helpers/route";

export type IssueMessageComponent = React.FC

const IssueMessage: IssueMessageComponent = () => {
    const {t} = useTranslation('issue');
    const openIssues = useStoreState(state => state.issue.filteredOpenIssues);
    const currentMachineId = useStoreState(state => state.machine.data.id);
    const showMachineMessages = useStoreState(state => state.app.appHeader.showMachineMessages);

    const criticalIssues = useMemo(() => openIssues.filter(issues => issues.type === IssueTypes.CRITICAL), [openIssues]);
    const moreCriticalIssues = useMemo(() => openIssues.filter(issues => issues.type === IssueTypes.MORECRITICAL), [openIssues]);

    const issueType = useMemo(() => moreCriticalIssues.length ? IssueTypes.MORECRITICAL : IssueTypes.CRITICAL, [moreCriticalIssues]);
    const issueCount = useMemo(() => moreCriticalIssues.length ? moreCriticalIssues.length : criticalIssues.length, [moreCriticalIssues, criticalIssues]);
    const issueTotalCount = useMemo(() => moreCriticalIssues.length + criticalIssues.length, [moreCriticalIssues, criticalIssues]);
    const url = useMemo(() => {
        return issueTotalCount ? route(issueTotalCount > 1 ? MACHINES_DETAILS_ROUTES.openCriticalIssues : MACHINES_DETAILS_ROUTES.issueDetails, {
            id: currentMachineId,
            issueId: moreCriticalIssues.length ? moreCriticalIssues[0].id : criticalIssues[0].id
        }) : '';
    }, [moreCriticalIssues, criticalIssues, currentMachineId, issueTotalCount]);

    return (
        <>
            {showMachineMessages && currentMachineId && !!(moreCriticalIssues.length || criticalIssues.length) &&
            <FlashMessage
                type={`${issueType}`}
                text={t(`${issueCount > 1 ? 'typesMultipleMessages' : 'typesMessages'}.${issueType}`, {count: issueCount})}
                url={url}
                icon={issueType === 3 ? 'build' : 'report_problem'}
            />}
        </>
    );
};

export default IssueMessage
