import axios from '~/axiosWrapper';
import {action, Action, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {CommonModel, getCommonModel, fetch, fetchCancel} from 'store/commonStore';
import {API} from 'app/routes';
import {StoreModel} from 'store/index';
import {Project} from 'types/index';
import {ProjectOfflineService} from "~/sqlite/services/project";

interface ProjectsFavoritePayload {
    projectId: Project['id'],
    favorite: boolean
}

interface ProjectsLastUsedPayload {
    projectId: Project['id']
}

export interface ProjectsModel extends CommonModel<Project> {
    filteredList: Computed<ProjectsModel, Project[], StoreModel>

    lastUsed: Project[]
    fetchingLastUsed: boolean
    setFetchingLastUsed: Action<ProjectsModel, boolean>
    fetchLastUsed: Thunk<ProjectsModel, void, any, StoreModel, Promise<Project[]>>
    fetchLastUsedCancel: Action<ProjectsModel>
    fetchedLastUsed: Action<ProjectsModel, Project[]>

    inFavorite: Computed<ProjectsModel, (id: Project['id']) => boolean, StoreModel>
    favorites: Project[]
    fetchingFavorites: boolean
    setFetchingFavorites: Action<ProjectsModel, boolean>
    fetchFavorites: Thunk<ProjectsModel, void, any, StoreModel, Promise<Project[]>>
    fetchFavoritesCancel: Action<ProjectsModel>
    fetchedFavorites: Action<ProjectsModel, Project[]>

    favoritesUpdating: boolean
    setFavoritesUpdating: Action<ProjectsModel, boolean>
    updateFavorites: Thunk<ProjectsModel, ProjectsFavoritePayload, any, StoreModel, Promise<null>>
    updatedFavorites: Action<ProjectsModel, Project[]>

    updateLastUsed: Thunk<ProjectsModel, ProjectsLastUsedPayload, any, StoreModel, Promise<null>>

    setOfflineFavorites: Thunk<ProjectsModel, Project[], any, StoreModel, Promise<void>>
    getOfflineFavorites: Thunk<ProjectsModel, void, any, StoreModel, Promise<void>>
}

export const projectsModel: ProjectsModel = {
    ...getCommonModel<Project>(API.projects),

    filteredList: computed(state => state.list.filter(item => (
        !state.lastUsed.find(({id}) => item.id === id) &&
        !state.favorites.find(({id}) => item.id === id)
    ))),

    lastUsed: [],
    fetchingLastUsed: false,
    setFetchingLastUsed: action((state, payload) => {
        state.fetchingLastUsed = payload;
    }),
    fetchLastUsed: thunk((actions) => {
        actions.setFetchingLastUsed(true);

        return fetch<Project>(`${API.projects}/last-used`)
            .then(data => {
                actions.fetchedLastUsed(data);
                return data;
            })
            .finally(() => {
                actions.setFetchingLastUsed(false);
            });
    }),
    fetchLastUsedCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedLastUsed: action((state, payload) => {
        state.lastUsed = payload;
    }),

    inFavorite: computed(
        state => (id: Project['id']): boolean => !!state.favorites.find(item => item.id.toString() === id.toString())
    ),
    favorites: [],
    fetchingFavorites: false,
    setFetchingFavorites: action((state, payload) => {
        state.fetchingFavorites = payload;
    }),
    fetchFavorites: thunk((actions) => {
        actions.setFetchingFavorites(true);

        return fetch<Project>(`${API.projects}/favorite`)
            .then(data => {
                actions.fetchedFavorites(data);
                actions.setOfflineFavorites(data);

                return data;
            })
            .finally(() => {
                actions.setFetchingFavorites(false);
            });
    }),
    fetchFavoritesCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedFavorites: action((state, payload) => {
        state.favorites = payload;
    }),

    favoritesUpdating: false,
    setFavoritesUpdating: action((state, payload) => {
        state.favoritesUpdating = payload;
    }),
    updateFavorites: thunk((actions, payload) => {
        actions.setFavoritesUpdating(true);

        return axios.patch<null, null>(`${API.projects}/favorite`, payload).then(
            data => fetch<Project>(`${API.projects}/favorite`)
                .then(list => {
                    actions.updatedFavorites(list);
                    actions.setOfflineFavorites(list);

                    return data;
                })
                .finally(() => {
                    actions.setFavoritesUpdating(false);
                })
        )
    }),
    updatedFavorites: action((state, payload) => {
        state.favorites = payload;
    }),

    updateLastUsed: thunk((actions, payload) => {
        return axios.post<null, null>(`${API.projects}/last-used`, payload);
    }),

    setOfflineFavorites: thunk(async (actions, payload) => {
        const ProjectOffline = new ProjectOfflineService();
        await ProjectOffline.reset(payload);
    }),

    getOfflineFavorites: thunk(async (actions) => {
        const ProjectOffline = new ProjectOfflineService();
        let projects = await ProjectOffline.getAll();
        projects && actions.updatedFavorites(projects.map((project) => ({...project, image: `${window['cordova'].file.dataDirectory}/${project.image}`})));
    }),
};
