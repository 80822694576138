import React, {useCallback, useMemo} from 'react';
import classes from './style.module.scss';
import {Archive} from "~/types";
import classNames from "classnames";
import Icon from "~/app/common/Icon";
import FileIcon from "./FileIcon";
import {useStoreActions, useStoreState} from "~/store";
import Loader from "~/app/common/Loader";

export interface ListItemProps {
    item: Archive
    machineId: string
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item, machineId}) => {
    const setParentFolderIdAction = useStoreActions(actions => actions.documentation.setParentFolderId);
    const openArchiveFileAction = useStoreActions(actions => actions.documentation.openArchiveFile);
    const downloadProgress = useStoreState(state => state.documentation.downloadProgress[item.id]);

    const isFileDownloaded = useMemo(() => downloadProgress === 100, [downloadProgress]);
    const clickHandler = useCallback(() => {
        if (item.type === 1 && item.downloadLink !== '' && (downloadProgress === undefined || isFileDownloaded)) {
            openArchiveFileAction({archive: item, machineId});
        } else if (item.type === 0) {
            setParentFolderIdAction(item.id);
        }
    }, [item, setParentFolderIdAction, openArchiveFileAction, downloadProgress, isFileDownloaded, machineId]);

    return (
        <div className={classes['list-item']} onClick={clickHandler}>
            <FileIcon extension={item.fileType}/>
            <div className={classes['info']}>
                <div className={classes['name']}>{item.name}</div>
                {item.description && <div className={classes['description']}>{item.description}</div>}
            </div>
            {!item.type && <div className={classNames(classes['right-icon-wrap'])}>
                <Icon name="keyboard_arrow_right"/>
            </div>}
            {(!!item.type && !isFileDownloaded) && <div className={classNames(classes['right-icon-wrap'])}>
                {downloadProgress === undefined ? <Icon name="get_app"/> : <Loader inline percentage={downloadProgress !== -1 ? downloadProgress : undefined}/>}
            </div>}
        </div>
    );
};

export default ListItem
