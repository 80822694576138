import React from 'react';
import classNames from 'classnames';
import Icon from '~/app/common/Icon';
import classes from './style.module.scss';
import {Issue, Machine} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";
import LinkWrapper from "~/app/common/LinkWrapper";
import {useParams} from 'react-router-dom';
import {MACHINES_DETAILS_ROUTES} from "~/machines/Details/Router";
import {route} from "~/app/helpers/route";

export interface ListItemProps {
    item: Issue
    listClassName?: string
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item, listClassName}) => {

    const {t} = useTranslation("issue");
    const {id} = useParams<{ id: Machine['id'] }>();

    return (
        <LinkWrapper className={`${classes['list-item']} ${listClassName && classes[listClassName]}`}
                     to={route(MACHINES_DETAILS_ROUTES.issueDetails, {id, issueId: item.id})}>
            <div className={classes['info']}>
                <div className={classes['title']}>{item.description}</div>
                <div className={classes['details']}>
                    <span className={`${classes['type']} ${classes[item.type]}`}>{t(`types.${item.type}`)}</span>
                    <span>{t('issueDate', {date: item.statusDate})}</span>
                    <span className={classes['status']}>{t(`statuses.${item.status}`)}</span>
                </div>
            </div>
            <div className={classNames(classes['right-icon-wrap'])}>
                <Icon name="keyboard_arrow_right"/>
            </div>
        </LinkWrapper>
    );
};

export default ListItem
