import { action, Action } from 'easy-peasy';
import {AppMessageProps} from "~/app/common/FlashMessage";

export interface FlashMessageModel {
    messages: AppMessageProps[]
    setMessages: Action<FlashMessageModel, AppMessageProps[]>
}

export const flashMessageModel: FlashMessageModel = {
    messages: [],
    setMessages: action((state, payload) => {
        state.messages = payload;
    })
};
