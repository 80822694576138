import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RouterSuspense from '~/app/common/RouterSuspense';

import MachinesRouter from '~/machines/Router';
import AboutUs from '~/pages/aboutUs/AboutUs';
import MachinesDetailsRouter from '~/machines/Details/Router';
import InstructionsRouter from '~/instructions/Router';
import ProjectsRouter from '~/projects/Router';
import NotificationsRouter from '~/notifications/Router';
import PrivateRoute from './PrivateRoute';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';

export enum ROUTES {
  projects = '/',
  machines = '/projects/:projectId',
  machinesDetails = '/machines/:id',
  instructions = '/instructions',
  aboutUs = '/aboutUs',
  notifications = '/notifications'
}

export type RouterComponent = React.FC

const Router: RouterComponent = () => (
  <RouterSuspense>
    <Switch>
      <Route path={ROUTES.aboutUs} component={AboutUs}/>
      <Route path={ROUTES.instructions} component={InstructionsRouter}/>
      <Route path={ROUTES.machinesDetails} component={MachinesDetailsRouter}/>
      <PrivateRoute path={ROUTES.machines} component={MachinesRouter} requiredPermissions={[PERMISSION_GROUPS.machineRegistration, PERMISSION_GROUPS.machineDashboard]}/>
      <Route path={ROUTES.notifications} component={NotificationsRouter}/>
      <Route path={ROUTES.projects} component={ProjectsRouter}/>
      <Redirect from="**" to={ROUTES.projects}/>
    </Switch>
  </RouterSuspense>
);

export default Router;
