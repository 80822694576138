import React from 'react';
import Actions from './Actions';

export type MenuProps = {
  onSelect?(): void,
  onEdit?(): void
}

export type MenuComponent = React.FC<MenuProps>

const Menu: MenuComponent = ({ onSelect, onEdit }) => {
  return (
    <>
      <Actions onSelect={onSelect} onEdit={onEdit} />
    </>
  );
};

export default Menu
