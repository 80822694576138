import React, {useCallback} from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import classes from "./style.module.scss";
import Button from '~/app/common/Button';
import {useParams} from 'react-router-dom';
import {Machine, Project} from "~/types";
import {route} from "~/app/helpers/route";
import {MACHINES_ROUTES} from "~/machines/Router";
import {useHistory} from "react-router";
import {useStoreActions, useStoreState} from "~/store";
import {notify} from "~/app/helpers/notify";
import { generateGUID } from '~/app/helpers/guid';

export type FooterComponent = React.FC

const Footer: FooterComponent = () => {
    const {t} = useTranslation("periodicCheck");
    const history = useHistory();
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
    const isChecksDataUpdated = useStoreState(state => state.periodicCheck.isChecksDataUpdated);
    const checksData = useStoreState(state => state.periodicCheck.checksData);
    const inspectionType = useStoreState(state => state.periodicCheck.inspectionType);
    const loading = useStoreState(state => state.periodicCheck.updating);
    const machine = useStoreState(state => state.machine.data);
    const updatePeriodicChecks = useStoreActions(actions => actions.periodicCheck.update);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const updatePeriodicCheckOfflineAction = useStoreActions(actions => actions.periodicCheck.setOfflinePeriodicChecksData);
    const MachineFetchAction = useStoreActions(actions => actions.machine.fetchMachine);
    const MachineOfflineFetchAction = useStoreActions(actions => actions.machine.fetchOfflineMachine);

    const back = useCallback(() => {
        history.push(route(MACHINES_ROUTES.details, {id, projectId}));
    }, [id, projectId, history]);

    const submit = useCallback(() => {
        (netWorkStatus ? updatePeriodicCheckOfflineAction : updatePeriodicChecks)({
            machineId: machine.id,
            projectId,
            inspectionType,
            checks: checksData,
            guid: generateGUID()
        }).then(async () => {
            await (netWorkStatus ? MachineOfflineFetchAction : MachineFetchAction)(id);
            history.push(route(MACHINES_ROUTES.details, {id, projectId}));
            notify(t(`${inspectionType}.success`), 'success');
        });
    }, [id, projectId, history, checksData, inspectionType, updatePeriodicChecks, machine, t, netWorkStatus, updatePeriodicCheckOfflineAction, MachineFetchAction, MachineOfflineFetchAction]);

    return (
        <div className={classes['footer']}>
            <Button className={`${classes['abort']}`} onClick={back}> {t('cancel')} </Button>
            <Button
                loading={loading}
                className={classes['submit']}
                onClick={submit}
                disabled={!isChecksDataUpdated}
            > {t(`${inspectionType}.complete`)} </Button>
        </div>
    );
};

export default Footer
