export const lastFind = <T>(arr: T[], callback: (item: T) => boolean): T | undefined => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (callback(arr[i])) {
      return arr[i];
    }
  }
};

export const lastFindIndex = <T>(arr: T[], callback: (item: T) => boolean): number => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (callback(arr[i])) {
      return i;
    }
  }

  return -1;
};

export const orderBy = <T>(arr: T[], sort: string[]): T[] => {
  for(let i = sort.length - 1; i >= 0; i--) {
    arr = (sort[i][0] === '-') ? arr.sort((a, b) => a[sort[i].substring(1)] > b[sort[i].substring(1)] ? -1 : 1) : arr.sort((a, b) => a[sort[i]] < b[sort[i]] ? -1 : 1)
  }
  return arr;
};
