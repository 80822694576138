import React, { useMemo } from 'react';
import { fuelConsumptionHistoryData } from '~/types';
import useTranslation from '~/app/hooks/useTranslation';
import FuelConsumptionGraph from '../FuelConsumptionGraph';
import FuelConsumptionTrendGraph from '../FuelConsumptionTrendGraph';
import ReportCard from '../../ReportCard';
import ReportCardRightSideInfo from '../../common/ReportCardRightSideInfo';

export interface FuelConsumptionReportCardProps {
    fuelConsumptionHistoryData: fuelConsumptionHistoryData
}

export type FuelConsumptionReportCardComponent = React.FC<FuelConsumptionReportCardProps>;

const FuelConsumptionReportCard: FuelConsumptionReportCardComponent = ({ fuelConsumptionHistoryData }) => {
    const { t } = useTranslation("machine.dashboard");

    const firstSectionData = useMemo(() => {
        return {
            title: t('previousWeekAverage'),
            data: fuelConsumptionHistoryData.previousWeekConsumption,
            performanceData: fuelConsumptionHistoryData.previousWeekPerformanceCompareToAverage
        }
    }, [fuelConsumptionHistoryData.previousWeekConsumption, fuelConsumptionHistoryData.previousWeekPerformanceCompareToAverage, t]);

    const secondSectionData = useMemo(() => {
        return {
            title: t('last12MonthsAverage'),
            data: fuelConsumptionHistoryData.last12MonthsConsumption,
            performanceData: fuelConsumptionHistoryData.last12MonthsPerformanceCompareToAverage
        }
    }, [fuelConsumptionHistoryData.last12MonthsConsumption, fuelConsumptionHistoryData.last12MonthsPerformanceCompareToAverage, t]);

    return (
        <ReportCard
            title={t(`fuelConsumption.title`)}
            dateOfLastAvailableData={fuelConsumptionHistoryData.previousWorkingDay}
            rightSideInfo={<ReportCardRightSideInfo
                firstSection={firstSectionData}
                secondSection={secondSectionData}
                defaultColor='grey'
            />}
            mainGraphTitle={t('previousWorkingDay')}
            mainGraph={<FuelConsumptionGraph fuelConsumptionHistoryData={fuelConsumptionHistoryData} />}
            trendGraph={<FuelConsumptionTrendGraph average={fuelConsumptionHistoryData.previousWorkDayConsumptionBySubcategory} />}
        />
    )
}

export default FuelConsumptionReportCard