import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import classNames from 'classnames';

import usePageTitle from '~/app/hooks/usePageTitle';
import useTranslation from '~/app/hooks/useTranslation';

import Loader from '~/app/common/Loader';

import { useAppHeader } from '~/app/modules/appHeader';
import ToggleSwitch from '~/app/common/ToggleSwitch/ToggleSwitch';

import { useStoreActions, useStoreState } from '~/store';

import Search from './Search';
import List from './List';

import classes from './style.module.scss';

export type ProjectsComponent = React.FC

const Projects: ProjectsComponent = () => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { t } = useTranslation('project.listing');

  usePageTitle(t('pageTitle'));

  const { setData } = useAppHeader();

  const lastUsedProjects = useStoreState(state => state.projects.lastUsed);
  const lastUsedProjectsFetchAction = useStoreActions(actions => actions.projects.fetchLastUsed);

  const favoritesProjects = useStoreState(state => state.projects.favorites);
  const favoritesProjectsFetchAction = useStoreActions(actions => actions.projects.fetchFavorites);

  const offlinefavoritesProjectsFetchAction = useStoreActions(actions => actions.projects.getOfflineFavorites);

  const networkStatus = useStoreState(state => state.app.offline.status);

  useEffect(() => {
    setData({
      title: '',
      subTitle: '',
      backLink: undefined,
      showUser: true,
      machineId: '',
      projectId: '',
      parentFolderId: 0,
      showMachineMessages: false,
      isIssueDetailPage: false,
      notification: true,
      search: false
    });
  }, [setData]);

  useEffect(
    () => {
      (
        networkStatus
          ? offlinefavoritesProjectsFetchAction()
          : Axios.all([
            lastUsedProjectsFetchAction(),
            favoritesProjectsFetchAction(),
          ])
      ).finally(() => {
        setFetching(false);
      });
    },
    [
      favoritesProjectsFetchAction,
      lastUsedProjectsFetchAction,
      setFetching,
      networkStatus,
      offlinefavoritesProjectsFetchAction
    ]
  );

  if (fetching) {
    return <Loader size="32"/>;
  }
  return (
    <>
      <div className={classNames(classes['title'], { [classes['offline']]: networkStatus })}>{t('title')}<ToggleSwitch/></div>

      {!networkStatus && <Search/>}

      {(!!lastUsedProjects.length && !networkStatus) && <List title={t('recentlyUsed')} list={lastUsedProjects} />}

      {!!favoritesProjects.length && <List title={t('favoriteProjects')} list={favoritesProjects} offline={true} />}

      {(!!networkStatus && !favoritesProjects.length) && (
          <div className={classes['offlineTextInfo']}>
            <b>{t('messageInfoTitle')} <br/><br/>{t('messageInfoDescription')}
            </b>
          </div>
      )}
    </>
  );
};

export default Projects
