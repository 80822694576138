import _ from "lodash";
import { parseBodyFields } from "~/user/helpers";

export const checkIsObjectPropertiesEqual = (previousObjectValues: { [key: string]: any }, currentObjectValues: { [key: string]: any }, keysToCheck: string[] = []): boolean => {
    if (keysToCheck.length) {
        previousObjectValues = parseBodyFields(previousObjectValues, keysToCheck);
        currentObjectValues = parseBodyFields(currentObjectValues, keysToCheck);
    }
    for (let key in currentObjectValues) {
        if (!_.isEqual(currentObjectValues[key], previousObjectValues[key])) {
            return false;
        }
    }
    return true;
}