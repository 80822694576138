import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Qs from 'qs';
import Bugsnag from '@bugsnag/js'
import { notify } from '~/app/helpers/notify';
import { TenantErrorCodes, publicApiURLs } from '~/tenantProvider/config';
import store from './store';
import { fetchJWTToken } from './axiosHelper';

const { REACT_APP_API_PREFIX } = process.env;

Axios.defaults.baseURL = `${store.getState().tenant.currentTenant?.baseUrl}${REACT_APP_API_PREFIX}`;
Axios.defaults.withCredentials = true;
Axios.defaults.paramsSerializer = params => Qs.stringify(params, { arrayFormat: 'repeat' });
Axios.defaults.validateStatus = status => (status >= 200 && status < 400);

/*Axios.create({
  timeout: 1000,
})*/

console.log('axiosConfig')

Axios.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {

  const url = config.url;
  // Check if the URL should be excluded
  if (url && publicApiURLs.some(apiUrl => url.includes(apiUrl))) {
    return config; // Do not attach the token to this request
  }
  
  const token = await fetchJWTToken();

  config.headers = {'Authorization': `Bearer ${token}`}

  return config;
});

function logout(response: AxiosResponse): void {
  if(response.data && response.data.code && response.data.code in TenantErrorCodes) {
    store.getActions().tenant.setTenantError(response.data);
  } else {
    store.getActions().user.logout().then();
  }
}

const setOfflineMode = () => {
  store.getActions().app.offline.setNetworkStatus(1)
}

Axios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse['data'] => {
    if (response.status === 401) {
      logout(response);
    }

    if (response.data && !response.data.success && response.data.message) {
      notify(response.data.message, 'error');

      return Promise.reject(response.data.message);
    }

    return response.data;
  },
  (error: any): Promise<AxiosError> => {

    if (Axios.isCancel(error)) {
      // setOfflineMode()
      return Promise.reject(error);
    }
    const errorData = error.toJSON();

    console.groupCollapsed('Error');
    console.log('{ ...error }',{ ...error });
    console.log('errorData', errorData);
    console.groupEnd();

    if(errorData && errorData.message === 'Network Error'){
      setOfflineMode()
      return Promise.reject(error);
    }

    if (error.response && error.response.status && error.response.status === 401) {
      logout(error.response);
      return Promise.reject(error);
    }
    Bugsnag.notify(errorData.message)

    if (error.response && error.response.data && error.response.data.message) {
      notify(error.response.data.message, 'error');
    }

    return Promise.reject(error);
  }
);
