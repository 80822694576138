import React from 'react';
import classes from './style.module.scss';
import useTranslation from "app/hooks/useTranslation";

export interface MenuProps {
    onSelect: (captureOption?: 0 | 1) => void
}

export type MenuComponent = React.FC<MenuProps>

const Menu: MenuComponent = ({onSelect}) => {
    const {t} = useTranslation('system.form.file');

    return (
        <ul className={classes['menu']}>
            <li className={classes['camera']} onClick={() => onSelect(0)}>{t('camera')}</li>
            <li className={classes['gallery']} onClick={() => onSelect(1)}>{t('gallery')}</li>
        </ul>
    );
};

export default Menu
