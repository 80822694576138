import React from 'react';
import ListItem from './ListItem';
import {MachineSpecification} from '~/types';

export type ListProps = {
    list: MachineSpecification,
    hiddenItems: string[]
}

export type ListComponent = React.VFC<ListProps>

const List: ListComponent = ({list, hiddenItems}) => {
    const keys = Object.keys(list).filter((el) => !hiddenItems.includes(el));
    return (
        <>
            {keys.map(id => <ListItem key={id} itemKey={id} item={list[id]}/>)}
        </>
    );
}

export default List
