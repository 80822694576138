import React from "react";
import ListItem from "./ListItem";
import { Notification } from "~/types";

export type ListProps = {
    list: Notification[]
}

export type ListComponent = React.VFC<ListProps>;

const List: ListComponent = ({ list }) => {
    return (
        <>
            {list.map((item, index) => <ListItem key={index} item={item} />)}
        </>
    );
};

export default List;