import React from 'react';
import ListItem from './ListItem';
import {Archive} from '~/types';
import {orderBy} from '~/app/helpers/array';

export type ListProps = {
    list: Archive[]
    machineId: string
}

export type ListComponent = React.VFC<ListProps>

const List: ListComponent = ({list, machineId}) => (
    <>
        {orderBy(list, ['type', '-docType', '-lastUpdatedAtForSorting'])
            .map(item => <ListItem key={item.id} item={item} machineId={machineId}/>)}
    </>
);

export default List
