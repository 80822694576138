import React from 'react';
import classes from './style.module.scss';
import AssetDashboardPreviousInfo from '../AssetDashboardPreviousInfo';

export interface ReportCardRightSideInfoProps {
    firstSection: {
        title: string
        data: string
        performanceData: number
    }
    secondSection: {
        Title: string
        data: string
        performanceData: number
    }
    defaultColor?: string
    reverseColors?: boolean
}

export type ReportCardRightSideInfoComponent = React.FC<ReportCardRightSideInfoProps>;

const ReportCardRightSideInfo = ({ firstSection, secondSection, defaultColor = "", reverseColors = false }) => {
    return (
        <>
            <AssetDashboardPreviousInfo title={firstSection.title} data={firstSection.data} performanceData={firstSection.performanceData} defaultColor={defaultColor} reverseColors={reverseColors} />
            <hr className={classes['divider']} />
            <AssetDashboardPreviousInfo title={secondSection.title} data={secondSection.data} performanceData={secondSection.performanceData} defaultColor={defaultColor} reverseColors={reverseColors} />
        </>
    )
};

export default ReportCardRightSideInfo