import React, {FC, useCallback, useState, useEffect} from 'react'
import classNames from 'classnames';

import classes from './style.module.scss';
import {useStoreActions, useStoreState} from "~/store";

const ToggleSwitch: FC = () => {
    const setNetworkStatusAction = useStoreActions(actions => actions.app.offline.setStatus);
    const offlineStatus = useStoreState(state => state.app.offline.status);
    const [checked, setChecked] = useState(!!offlineStatus);

    const toggleChange = useCallback(() => {
        const status = !checked
        setNetworkStatusAction(status ? 2 : 0)
    }, [checked, setNetworkStatusAction])

    useEffect(() => {
        setChecked(!!offlineStatus)
    },[offlineStatus])

    return (
        <div className={classes.switchContainer}>
            <label className={classes.switch}>
                <input type="checkbox" onChange={toggleChange} checked={checked} />
                <span className={classNames(classes.slider, classes.round)} />
            </label>
        </div>
    )
}

export default ToggleSwitch

