import React from 'react';

import useTranslation from '~/app/hooks/useTranslation';

import Button from '~/app/common/Button';
import Icon from '~/app/common/Icon';

import classes from './style.module.scss';

export type LoginCanceledProps = {
  onLoginClick?(): void
}

export type LoginCanceledComponent = React.VFC<LoginCanceledProps>

const LoginCanceled: LoginCanceledComponent = ({ onLoginClick }) => {
  const { t } = useTranslation('msalAuth.loginCanceled');

  return (
    <div className={classes['wrap']}>
      <div className={classes['content']}>
        <Icon className={classes['icon']} name="warning" size="60"/>

        <div className={classes['title']}>{t('title')}</div>

        <Button color="primary" onClick={onLoginClick}>{t('loginButton')}</Button>
      </div>
    </div>
  );
}

export default LoginCanceled
