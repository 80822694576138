import store from "~/store";
import QueryBuilder from "./queryBuilder";
import {
    IssueCategory,
    IssueDetails,
    IssueDetailsTable,
    IssuePayload,
    IssueResponsePayload,
} from "~/types";
import FileTransfer from "~/sqlite/services/fileTransfer";
import moment from 'moment';
import _ from 'lodash';
import {syncTypes} from "~/sqlite/syncDataTypes";
import { IssueStatuses, IssueTypeRepaired } from "~/app/data/statuses/issue";

export class IssueOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public resetIssueCategories(data: IssueCategory[]) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            return new Promise((resolve, reject) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.truncate('IssueCategories'));
                    tx.executeSql(qb.insert('IssueCategories', data));
                }, () => reject(), () => resolve());
            });
        }
    }

    public getAllIssueCategories() {
        if (this.SQLite) {
            return new Promise<IssueCategory[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('IssueCategories'), [], resultSet => resolve(qb.getAllResult(resultSet)), () => reject());
            });
        }
    }

    public reset(issues: IssueDetails[]) {
        if (this.SQLite) {
            return new Promise((resolve, reject) => {
                // this.downloadIssuesImages(issues).then((issues) => {
                issues.splice(100)
                this.checkIssues(issues).then(({issues, deleteIssues}) => {
                const qb = new QueryBuilder();
                    this.SQLite.transaction((tx) => {
                        if(deleteIssues.length){
                            tx.executeSql(qb.deleteIn('Issue' , 'id',  deleteIssues));
                        }

                        if(issues) {
                            tx.executeSql(qb.insert('Issue', issues));
                        }
                    }, (e) => reject(e), () => resolve());
                })
                // });
            });
        }
    }

    public checkIssues(issuesWithImages: IssueDetails[]) {
        const qb = new QueryBuilder();
        return new Promise<{issues: IssueDetailsTable[], deleteIssues: string[]}>((resolve, reject) => {
            this.SQLite.executeSql(qb.select('Issue'), [], (resultSet) => {
                const issues = issuesWithImages.map((data) => (
                    {data: JSON.stringify({...data, images: ''}),id: data.id, machineId: data.machineId, images: ''}
                ))

                const dbIssues = qb.getAllResult(resultSet);
                const deleteIssues = dbIssues
                    .filter((deleteIssue) => !_.some(issues, {data: deleteIssue.data}))
                    .map((data) =>  data.id);

                const addIssues = issues.filter(
                    (addIssue) => !_.some(dbIssues, {data: addIssue.data})
                );
                console.log('issues -> delete -> add', deleteIssues, addIssues)

                resolve({issues: addIssues, deleteIssues});

            }, (e) => reject(e));
        })
    }

    public updateIssueDetail(issue: IssueDetails) {
        if (this.SQLite && store.getState().machines.inFavorite(issue.machineId)) {
            const qb = new QueryBuilder();
            this.downloadIssueImages(issue).then((issue) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(
                        qb.update('Issue',
                            {
                                id: issue.id,
                                machineId: issue.machineId,
                                images: JSON.stringify(issue.images),
                                data: JSON.stringify({...issue, images: ''})},
                            [{name: 'id', value: issue.id}]
                        )
                    );
                });
            });
        }
    }

    public downloadIssuesImages(issues: IssueDetails[]) {
        const allPromise: Promise<IssueDetails>[] = [];
        issues.forEach((issues) => {
            allPromise.push(this.downloadIssueImages(issues));
        });

        return Promise.all(allPromise).then(machines => {
            return machines;
        });
    }

    public downloadIssueImages(issue: IssueDetails) {
        return new Promise<IssueDetails>((resolve) => {
            if (issue.images) {
                const fileTransfer = new FileTransfer();
                const allPromise: Promise<string>[] = [];
                issue.images.forEach((image) => {
                    let imageDownloadPromise = new Promise<string>((resolve) => {
                        fileTransfer.downloadFileFromURL(image, `issueImages/${issue.machineId}/${issue.id}`).then((image) => {
                            resolve(image);
                        });
                    });
                    allPromise.push(imageDownloadPromise);
                });
                Promise.all(allPromise).then(images => resolve({...issue, ...{images: images.filter(item => item)}}));
            } else {
                resolve(issue);
            }
        });
    }

    public getIssue(id) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            let issue;
            let where = [{name: 'id', value: id}];
            return new Promise<IssueDetails>((resolve, reject) => {
                    this.SQLite.executeSql(qb.select('Issue', [], where), [], (result) => {
                        issue = qb.getSingleResult(result);
                        console.log('single issue', JSON.parse(issue.data));

                        let images = []
                        if(issue.images){
                            const listImages = JSON.parse(issue.images)
                            if(typeof listImages === 'object'){
                                images  = listImages.map((path) => `${window['cordova'].file.dataDirectory}/${path}`)
                            }
                        }

                        resolve({
                            ...JSON.parse(issue.data),
                            id: issue.id,
                            images
                        })
                    },(e) => reject(e));
            });
        }
    }

    public saveIssue(data: IssuePayload) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            const fileTransfer = new FileTransfer();
            const departments = store.getState().app.appData.departments;
            const users = store.getState().app.appData.users;
            const issueDepartmentIndex = departments.findIndex(department => department.id === data.departmentId);
            let issueId = '';
            const updatedIssueId = data?.id ?? null;
            const issueDetails = store.getState().issue.data.id ? store.getState().issue.data : null;
            const userIndex = users.findIndex(user => data.performedBy && user.id === parseInt(data.performedBy));
            let issuePayloadId = '';
            let existingImagesData: string[] = [];
            let newImagesArray: File[] = [];
            // While updating issue there is two types of data available in images
            // 1. string (existing image URLs) 2. newly uploaded image object (blob)
            // Below we have seperated strings and blobs object in different arrays and store only newly uploaded file to local system
            data?.images?.forEach((image: string | File) => {
                if(_.isString(image)) {
                    existingImagesData.push(image.replace(`${window['cordova'].file.dataDirectory}/`, ""));
                } else {
                    newImagesArray.push(image)
                }
            });
            return fileTransfer.saveFilesFromBlobs(newImagesArray).then((images) => {
                console.log('save in offlineMode', data);
                return new Promise<IssueResponsePayload>((resolve, reject) => {
                    this.SQLite.transaction((tx) => {
                        // Here we are merging existing images URLs with new uploaded image path
                        // Store this all images path in sqlite db
                        const imagesData = existingImagesData.concat(images);
                        const imagesJson = JSON.stringify(imagesData ?? []);

                        const dataJson = JSON.stringify({
                            departmentId: null,
                            departmentName: null,
                            status: data.issueType === 'repair' ? IssueStatuses.COMPLETED : IssueStatuses.REGISTERED,
                            ...data,
                            chat: issueDetails?.chat ?? [], 
                            workDetails: issueDetails?.workDetails ?? [],
                            type: data.issueType === 'repair' ? IssueTypeRepaired : data.type,
                            statusDate: issueDetails?.statusDate ?? moment().format("YYYY-MM-DD HH:mm:ss"),
                            machineIntern: store.getState().machine.data.intern,
                            machineName: store.getState().machine.data.name,
                            operatingTime: store.getState().machine.data.operatingTime,
                            operatingCount: store.getState().machine.data.operatingCount,
                            issueDepartmentName: issueDepartmentIndex !== -1 ? departments[issueDepartmentIndex].name : '',
                            issueDepartmentId: data.departmentId,
                            isChatUser: issueDetails?.isChatUser ?? 0, 
                            createdAt: issueDetails?.createdAt ?? moment().format("YYYY-MM-DD HH:mm:ss"),
                            createdBy: issueDetails?.createdBy ?? (userIndex !== -1 ? users[userIndex].name : store.getState().user.data.name),
                            issueCreatedBy: issueDetails?.issueCreatedBy ?? (userIndex !== -1 ? users[userIndex].name : store.getState().user.data.name),
                            ...(updatedIssueId && {
                                oldDepartmentId: issueDetails?.issueDepartmentId ? Number(issueDetails?.issueDepartmentId) : null,
                                updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                                issueUpdatedBy: store.getState().user.data.name,
                            }),
                        });

                        tx.executeSql(qb.insert('SyncOfflineData', [{
                            data: dataJson,
                            images: imagesJson,
                            userId: store.getState().user.data.id,
                            typeOfData: syncTypes.ADD_ISSUE
                        }]), [], (SQLite, result) => (issuePayloadId = result.insertId));

                        if(updatedIssueId) {
                            tx.executeSql(qb.update('Issue', {
                                images: imagesJson,
                                data: dataJson,
                                machineId: data.machineId
                            }, [{name: 'id', value: updatedIssueId}]), [], (SQLite, result) => {
                                issueId = String(updatedIssueId);
                            });
                        } else {
                            tx.executeSql(qb.insert('Issue', [{
                                data: dataJson,
                                images: imagesJson,
                                machineId: data.machineId
                            }]), [], (SQLite, result) => (issueId = result.insertId));
                        }

                    }, (e) => reject(e), () => {
                        console.log('new res', {id: issueId, type: parseInt(data.type), offlineIssueId: issuePayloadId})
                        return resolve({id: issueId, type: parseInt(data.type), offlineIssueId: issuePayloadId})});
                });
            });
        } else {
            return {id: '', type: parseInt(data.type)};
        }
    }
}
