import React from 'react';
import {IssueDetails} from "~/types";
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import Icon from "~/app/common/Icon";

export interface IssueProps {
    issue: IssueDetails
}

export type IssueComponent = React.FC<IssueProps>

const Issue: IssueComponent = ({issue}) => {
    const {t} = useTranslation("issue");
    return (
        <div className={classes['issue-info']}>
            <div className={classes['issue-title']}>{issue.description}</div>
            <div className={classes['issue-register-details']}>{t('registerTimeName', {
                date: issue.createdAt,
                name: issue.createdBy
            })}</div>
            <div className={classes['info-wrapper']}>
                <div className={classes['title']}>{t("statusTitle")}</div>
                <div className={classes['value']}>
                    <Icon name="stop_circle" className={`${classes['status']} ${classes[issue.status]}`}/>
                    {t(`statuses.${issue.status}`)}
                </div>
            </div>
            <div className={classes['info-wrapper']}>
                <div className={classes['title']}>{t("classification.title")}</div>
                <div className={classes['value']}>{t(`types.${issue.type}`)}</div>
            </div>
            <div className={classes['info-wrapper']}>
                <div className={classes['title']}>{t("issueCategories")}</div>
                <div className={classes['value']}>{issue.categoryName}</div>
            </div>
            {issue.issueDepartmentId && <div className={classes['info-wrapper']}>
                <div className={classes['title']}>{t("issueDepartment")}</div>
                <div className={classes['value']}>{issue.issueDepartmentName}</div>
            </div>}
            <hr className={classes['bottom-line']}/>
        </div>
    );
};

export default Issue
