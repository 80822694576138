import React, { useCallback } from "react";
import classes from "./style.module.scss";
import useTranslation from "~/app/hooks/useTranslation";
import Input from "~/app/modules/form/Search/Input";
import { useStoreActions, useStoreState } from "~/store";

export type SearchComponent = React.FC;

const Search: SearchComponent = () => {
    const { t } = useTranslation("notifications");
    const isSearching = useStoreState((state) => state.notification.isSearching);
    const setIsSearching = useStoreActions((actions) => actions.notification.setIsSearching);
    const setNotificationPayload = useStoreActions((actions) => actions.notification.setNotificationPayload);
    const notificationPayload = useStoreState((state) => state.notification.notificationPayload);
    const setFetching = useStoreActions((actions) => actions.notification.setFetching);

    const searchFormSearchHandler = useCallback(
        (value: string): void => {
            setIsSearching(true);
            setNotificationPayload({
                searchText: value
            });
            setFetching(true);
        },
        [setIsSearching, setNotificationPayload, setFetching]
    );
    
    return (
        <div className={classes["search-wrap"]}>
            <Input
                placeholder={t("search")}
                className={classes["field"]}
                inputClassName={classes["input"]}
                onSearch={searchFormSearchHandler}
                fetching={isSearching}
                value={notificationPayload.searchText}
                iconPosition='left'
            />
        </div>
    );
};

export default Search;
