import React from 'react';
import ListItem from './ListItem';
import {Equipment} from '~/types';

export type ListProps = {
    list: Equipment[]
}

export type ListComponent = React.VFC<ListProps>

const List: ListComponent = ({list}) => (
    <>
        {list.map(item => <ListItem key={item.id} item={item}/>)}
    </>
);

export default List
