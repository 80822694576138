import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

import classes from './style.module.scss';

export type LinkWrapperComponent = React.FC<LinkProps>

const LinkWrapper: LinkWrapperComponent = ({ className, children, ...props }) => (
  <Link className={classNames(classes['link'], className)} {...props}>{children}</Link>
);

export default LinkWrapper
