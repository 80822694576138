import React, {useMemo} from 'react';
import AccessDenied from "./AccessDenied";
import useAccessControl from "~/app/hooks/useAccessControl";

export interface RequiredPermissions {
    menu: string,
    subMenu?: string,
    action?: string,
    subAction?: string
}

export interface AccessControlProps {
    requiredPermissions: RequiredPermissions
    showMessage?: boolean
}

export type AccessControlComponent = React.FC<AccessControlProps>;

const AccessControl: AccessControlComponent = ({requiredPermissions, children, showMessage}) => {
    const {checkPermission} = useAccessControl();
    const permitted = useMemo<boolean>(() => checkPermission(requiredPermissions), [checkPermission, requiredPermissions]);

    if (permitted) {
        return (
            <>
                {children}
            </>
        );
    }

    if (showMessage) {
        return <AccessDenied/>;
    }

    return (
        <></>
    );
};
export default AccessControl
