import React from 'react';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';

export type NetworkErrorMessageComponent = React.FC

const NetworkErrorMessage: NetworkErrorMessageComponent = () => {
    const {t} = useTranslation('offline');

    return (
        <div className={classes['message']}>
            <Icon className={classes['icon']} name="terrain" size="60"/>
            <div className={classes['title']}>{t('title')}</div>
        </div>
    );
};

export default NetworkErrorMessage
