import { ActionCreator } from 'easy-peasy';

import { useStoreActions } from '~/store';

import { SetAppHeaderDataPayload } from './store';

export interface UseAppHeader {
  (): {
    setData: ActionCreator<SetAppHeaderDataPayload>
    clearData: ActionCreator<undefined>
  }
}

export const useAppHeader: UseAppHeader = () => {
  const setData = useStoreActions(actions => actions.app.appHeader.setData);
  const clearData = useStoreActions(actions => actions.app.appHeader.clearData);

  return {
    setData,
    clearData
  };
};
