import {MachineSpecification} from '~/types';
import store from "~/store";
import QueryBuilder from "./queryBuilder";

export class MachineSpecificationOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public reset(machines: MachineSpecification[]) {
        if (this.SQLite && machines.length) {
            return new Promise((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.truncate('MachineSpecification'));
                    tx.executeSql(qb.insert('MachineSpecification', machines.map((machine) => {
                        return {
                            ...machine,
                            data: JSON.stringify(machine) 
                        }
                    })));
                }, () => reject(), () => resolve());
            });
        }
    }

    public getMachineEquipments(id) {
        if (this.SQLite) {
            let excavatorData;
            let where = [{name: 'id', value: id}];
            return new Promise<MachineSpecification[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('Machine', [], where), [], (result) => {
                    excavatorData = qb.getSingleResult(result);
                    excavatorData?.data ? this.SQLite.executeSql(qb.select('MachineSpecification', [], [{
                        name: 'hovedNo',
                        value: JSON.parse(excavatorData.data)?.intern
                    }]), [], (resultSet) => resolve(qb.getAllResult(resultSet).map((result) => JSON.parse(result.data))), (e) => resolve([])) : resolve([]);
                }, (e) => resolve([]));
            });
        }
    }

    public getMachineSpecifications(id) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            let machineSpecification;
            let where = [{name: 'id', value: id}];
            return new Promise<MachineSpecification>((resolve, reject) => {
                this.SQLite.transaction((SQLite) => {
                    SQLite.executeSql(qb.select('MachineSpecification', [], where), [], (SQLite, result) => {
                        const machineSpecificationRow = qb.getSingleResult(result);
                        if(machineSpecificationRow.data) {
                            machineSpecification = JSON.parse(machineSpecificationRow.data);
                        }
                    });
                }, () => resolve(machineSpecification), () => resolve(machineSpecification));
            });
        }
    }

    public updateMachineSpecifications(machine: MachineSpecification) {
        if (this.SQLite && store.getState().machines.inFavorite(store.getState().machine.data.id)) {
            const qb = new QueryBuilder();
            this.SQLite.transaction((tx) => {
                tx.executeSql(
                    qb.update('MachineSpecification',
                        {
                            id: machine.id,
                            hovedNo: machine.hovedNo,
                            data: JSON.stringify({...machine})
                        },
                        [
                            {name: 'id', value: machine.id}
                        ]
                    )
                );
            });
        }
    }
}
