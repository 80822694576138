import React, { useCallback, useState } from 'react';

import Button from '~/app/common/Button';
import Icon from '~/app/common/Icon';

import useField from '../useField';
import Field, { FieldProps } from '../Field';

import mainClasses from '../style.module.scss';
import classes from './style.module.scss';

export interface CounterPropsDefault extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>
}

export type CounterProps = CounterPropsDefault & FieldProps

export type CounterComponent = React.RefForwardingComponent<HTMLInputElement, CounterProps>

const Counter: CounterComponent = (props, ref) => {
  const {
    fieldProps,
    inputProps: { placeholder, defaultValue, step = 1, onChange, ...othersInputProps }
  } = useField<CounterPropsDefault>(props);

  const [value, setValue] = useState<number>(defaultValue ? +defaultValue : 0);

  const triggerChange = useCallback((value: number): void => {
    if (onChange) {
      // @ts-ignore
      onChange({ target: { name: props.name, value } });
    }
  }, [onChange, props.name]);
  
  const decrementClickHandler = useCallback(() => {
    const newValue = value - +step;

    setValue(newValue);
    
    triggerChange(newValue);
  }, [step, triggerChange, value]);
  const incrementClickHandler = useCallback(() => {
    const newValue = value + +step;

    setValue(newValue);
    
    triggerChange(newValue);
  }, [step, triggerChange, value]);

  const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(+e.target.value);
    
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);
  
  return (
    <Field {...fieldProps}>
      <div className={`${mainClasses['input']} ${classes['input']}`}>
        {!!placeholder && <div className={classes['placeholder']}>{placeholder}</div>}

        <div className={classes['counter-wrap']}>
          <Button className={classes['button']} onClick={decrementClickHandler}>
            <Icon name="remove" size="32" />
          </Button>

          <input
            {...othersInputProps}
            ref={ref}
            className={`${mainClasses['input']} ${classes['counter']}`}
            type="number"
            value={value}
            step={step}
            onChange={inputChangeHandler}
          />

          <Button className={classes['button']} onClick={incrementClickHandler}>
            <Icon name="add" size="32" />
          </Button>
        </div>
      </div>
    </Field>
  );
};

export default React.forwardRef(Counter)
