import React from 'react';
import AccessControl from '~/accessControl';
import { CertificationPermissionKeys, PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import { Certification } from '~/types';
import CertificationInfo from '../../../CertificationInfo';

export interface ListItemProps {
    readPerimission: CertificationPermissionKeys
    data: Certification
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({ readPerimission, data }) => (
    <AccessControl requiredPermissions={PERMISSION_GROUPS[readPerimission]}>
        <CertificationInfo certification={data} />
    </AccessControl>
);

export default ListItem;
