import React, { useMemo } from 'react';
import i18next from 'i18next';
import parse from 'html-react-parser';
import { MomentInput, utc } from 'moment';

import { formatDate } from '~/app/helpers/date';
import useTranslation from '~/app/hooks/useTranslation';

export interface FormattedDateProps {
  date: MomentInput
  format?: string
  human?: boolean
  strongTime?: boolean
}

export type FormattedDateComponent = React.FC<FormattedDateProps>

export const formatHumanDate = (date: MomentInput): string => {
  date = utc(date);
  
  const now = utc();

  if (!date.isSame(now, 'year')) {
    return i18next.t('common.formattedDate.human.previousYear');
  } else if (date.isSame(now, 'day')) {
    return i18next.t('common.formattedDate.human.today');
  } else if (date.isSame(now.add(1, 'day'), 'day')) {
    return i18next.t('common.formattedDate.human.tomorrow');
  }

  return i18next.t('common.formattedDate.human.other')
};

const FormattedDate: FormattedDateComponent = ({ date, format, human, strongTime }) => {
  const { t } = useTranslation('common.formattedDate');
  
  const dateFormat = useMemo(() => {
    if (format) {
      return format;
    }
    
    if (human) {
      return formatHumanDate(date);
    }

    if (strongTime) {
      return t('strongTime');
    }

    return t('main')
  }, [date, format, human, strongTime, t]);

  return <>{parse(formatDate(date, dateFormat))}</>;
};

export default FormattedDate
