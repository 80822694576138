import React from 'react';
import { useStoreState } from '~/store';
import AccessControl from '~/accessControl';
import { CertificationPermissionKeys, PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import HeaderOfflineSwitch from '../../HeaderOfflineSwitch';
import { Certification } from '~/types';
import List from '../CertificationContainer/List';

export type HeaderComponent = React.FC;

const Header: HeaderComponent = () => {
    const machine = useStoreState(state => state.machine.data);

    // Prepare all certification list with associated permissions and data
    const certificationList: { [key in CertificationPermissionKeys]: Certification } =
    {
        readCertificationInfo: machine.certification,
        readADRCertificationInfo: machine.adrCertification,
        readLiftControlCertificationInfo: machine.liftControlCertification,
        readElectricalControlCertificationInfo: machine.electricalControlCertification,
        readPSVCertificationInfo: machine.psvCertification,
        readAirTank1YearCertificationInfo: machine.airTank1YearCertification,
        readExtinguishingEquipmentCertificationInfo: machine.extinguishingEquipmentCertification,
        readAirTank2YearCertificationInfo: machine.airTank2YearCertification,
        readElectricMotorCertificationInfo: machine.electricEngineCertification,
        readNDTCertificationInfo: machine.ndtCertification,
        readAirTank10YearCertificationInfo: machine.airTank10YearCertification
    };

    return (
        <>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.machineRegistration}>
                <List list={certificationList} />
                <HeaderOfflineSwitch />
            </AccessControl>
        </>
    );
};

export default Header
