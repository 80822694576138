import React, {useCallback, useState, useRef, useEffect} from 'react';
import axios from '~/axiosWrapper';

import {useStoreActions, useStoreState} from '~/store';
import Loader from "~/app/common/Loader";
import useDidMountEffect from "~/app/hooks/useDidMountEffect";
import {API} from 'app/routes';

export type ProviderComponent = React.FC

const Provider: ProviderComponent = ({children}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const setNetworkStatusAction = useStoreActions(actions => actions.app.offline.setNetworkStatus);
    const offlineStatus = useStoreState(state => state.app.offline.networkStatus);
    const timeOutConnection = useRef<any>(undefined);
    useEffect(() => {
        if(offlineStatus === 1) {
            if(timeOutConnection.current){
                clearInterval(timeOutConnection.current)
            }

            timeOutConnection.current = setInterval(() => {
                console.log('rerender setInterval', offlineStatus)
                axios.get(`${API.projects}/last-used`).then(() => {
                    console.log('internet Working')
                    setNetworkStatusAction(0)
                    clearInterval(timeOutConnection.current)
                }).catch(() => {
                    console.log('internet does not working')
                })
            }, 10000)
        }
    }, [offlineStatus, setNetworkStatusAction])

    const setNetworkStatus = useCallback((status) => {
        setNetworkStatusAction(status);
        setLoading(false);
    }, [setNetworkStatusAction]);

    const checkConnection = useCallback(() => {
        setNetworkStatus(navigator['connection'].type === 'none' ? 1 : 0);
    }, [setNetworkStatus]);

    useDidMountEffect(() => {
        if (window['cordova']) {
            document.addEventListener('offline', () => setNetworkStatus(1), false);
            document.addEventListener('online', () => setNetworkStatus(0), false);
            checkConnection();

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else {
            setLoading(false);
        }
    });

    if (loading) {
        return <Loader size="32"/>;
    }

    return <>{children}</>;
};

export default Provider
