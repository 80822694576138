import React, {useEffect} from 'react'
import {useAppHeader} from "~/app/modules/appHeader";
import {getMachineSubTitle} from "~/machines/helpers";
import { Machine, Project} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";
import {useParams} from 'react-router-dom';
import {route} from "~/app/helpers/route";
import {MACHINES_ROUTES} from "~/machines/Router";
import { useStoreState } from "~/store";
import ListItem from "~/documentation/Specifications/List/ListItem";

const detailItems = ['intervalText', 'dueHoursText', 'serviceProgram', 'workOrderPlannedDate', 'workOrderNo', 'nextService'];

const NestService = () => {
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
    const machine = useStoreState(state => state.machine.data);
    const services = useStoreState(state => state.machine.nextService);

    const {t} = useTranslation('machine.details');
    const {setData} = useAppHeader();

    useEffect(() => {
        setData({
            title: t('serviceInfo'),
            subTitle: getMachineSubTitle(machine),
            backLink: route(MACHINES_ROUTES.details, {id, projectId}),
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            notification: false,
            search: false
        });
    }, [setData, t, id, machine, projectId]);

    return (
        <div>
            {detailItems?.map((item, index) => <ListItem key={index} itemKey={item} item={services[item]}/>)}
        </div>
    )
}

export default NestService;
