import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RouterSuspense from '~/app/common/RouterSuspense';
import Notifications from '.';

export const REQUESTS_ROUTES = {
    notifications: '/notifications',
};

export type RouterComponent = React.FC

const Router: RouterComponent = () => (
    <RouterSuspense>
        <Switch>
            <Route path={REQUESTS_ROUTES.notifications} component={Notifications}/>
        </Switch>
    </RouterSuspense>
);

export default Router;
