import React, {FC, useEffect} from 'react'
import Bugsnag from '@bugsnag/js'

Bugsnag.start({
    apiKey: 'bb10c8ea1e516788cea87752306ba8c8',
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: process.env.REACT_APP_ENV,
    // plugins: [new BugsnagPluginReact()],
    autoDetectErrors: true,
    onError: function (event) {
        event.addMetadata('type-of-device', {
            type:  window['cordova']?.platformId ? window['cordova'].platformId : 'webView',
            betaAccess: true
        })
    }
})

interface Props {}

const ErrorProvider:FC<Props> = ({children}) => {

    useEffect(() => {
        const debugError = (event) => {
            if(event) {
                Bugsnag.notify({name:'Bugsnag Code Error', message: event?.message})
            }

        }
        window.addEventListener("error", debugError);

        return () => {
            window.removeEventListener('error', debugError)
        }
    },[])

    return <>{children}</>
}

export default ErrorProvider
