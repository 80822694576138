import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {useStoreActions, useStoreState} from '~/store';
import usePageTitle from '~/app/hooks/usePageTitle';
import {useAppHeader} from '~/app/modules/appHeader';
import classes from "./style.module.scss";
import Search from './Search';
import List from './List';
import useTranslation from "~/app/hooks/useTranslation";
import Loader from '~/app/common/Loader';
import {ROUTES} from "~/app/Router";
import useDidMountEffect from '~/app/hooks/useDidMountEffect';

export type MachinesComponent = React.FC

const Machines: MachinesComponent = () => {
    const {setData} = useAppHeader();
    const {t} = useTranslation("machine.listing");
    usePageTitle(t('pageTitle'));
    const [fetching, setFetching] = useState<boolean>(true);

    const lastUsedMachines = useStoreState(state => state.machines.lastUsed);
    const lastUsedMachinesFetchAction = useStoreActions(actions => actions.machines.fetchLastUsed);

    const favoritesMachines = useStoreState(state => state.machines.favorites);
    const favoritesMachinesFetchAction = useStoreActions(actions => actions.machines.fetchFavorites);

    const offlinefavoritesMachinesFetchAction = useStoreActions(actions => actions.machines.getOfflineFavorites);

    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const project = useStoreState(state => state.project.data);

    const resetActiveTab = useStoreActions(actions => actions.machine.resetActiveTab);

    useDidMountEffect(() => {
        resetActiveTab();
    });

    useEffect(() => {
        setData({
            title: project.name,
            subTitle: project.number,
            backLink: ROUTES.projects,
            showUser: false,
            machineId: '',
            projectId: netWorkStatus === 0 ? project.id : '',
            parentFolderId: 0,
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: true,
            search: false
        });
    }, [setData, project, netWorkStatus]);

    useEffect(
        () => {
            if (netWorkStatus === 0) {
                Axios.all([
                    lastUsedMachinesFetchAction(),
                    favoritesMachinesFetchAction()
                ]).finally(() => {
                    setFetching(false);
                });
            } else {
                offlinefavoritesMachinesFetchAction().finally(() => {
                    setFetching(false);
                })
            }
        },
        [
            favoritesMachinesFetchAction,
            lastUsedMachinesFetchAction,
            setFetching,
            netWorkStatus,
            offlinefavoritesMachinesFetchAction
        ]
    );

    if (fetching) {
        return <Loader size="32"/>;
    }

    return (
        <>
            <div className={`${classes['title']} ${!!netWorkStatus && classes['offline']}`}>{t('title')}</div>
            {!netWorkStatus && <Search/>}

            {(!!lastUsedMachines.length && !netWorkStatus) && <List title={t('recentlyUsed')} list={lastUsedMachines}/>}

            {!!favoritesMachines.length && <List title={t('favoriteMachines')} list={favoritesMachines} offline={true}/>}

            {(!!netWorkStatus && !favoritesMachines.length) && (
                <div className={classes['offlineTextInfo']}>
                    <b>{t('messageInfoTitle')} <br/><br/>{t('messageInfoDescription')}
                    </b>
                </div>
            )}
        </>
    );
};

export default Machines
