import { useEffect } from 'react';

export interface UsePageTitle {
  (title: string): void
}

const usePageTitle: UsePageTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default usePageTitle
