import React, {useEffect, useMemo} from 'react';
import usePageTitle from '~/app/hooks/usePageTitle';
import {useAppHeader} from '~/app/modules/appHeader';
import {useParams} from 'react-router-dom';
import {Machine, IssueDetails} from "~/types";
import {route} from "~/app/helpers/route";
import Carousel from "~/app/common/Carousel";
import StatusMessage from "~/issues/Details/statusMessage";
import Issue from "~/issues/Details/Issue";
import WorkOrder from "~/issues/Details/WorkOrder";
import Messages from "~/issues/Details/Messages";
import {useHistory} from 'react-router-dom';
import {ROUTES} from "~/app/Router";
import CloseIssueDetails from "~/issues/Details/ClosedIssueDetails";

export interface DetailsProps {
    issueDetail: IssueDetails
}

export type DetailsComponent = React.FC<DetailsProps>

const Details: DetailsComponent = ({issueDetail}) => {
    const history = useHistory();
    const {setData} = useAppHeader();
    const {id} = useParams<{ id: Machine['id'] }>();

    const pageTitle = useMemo(() => {
        return issueDetail.issueNo ? `Feil #${issueDetail.issueNo}` : issueDetail.description
    }, [issueDetail]);

    usePageTitle(pageTitle);
    useEffect(() => {
        setData({
            title: pageTitle,
            subTitle: (issueDetail.machineIntern + " - " + issueDetail.machineName).toUpperCase(),
            backLink: history.length > 1 ? history.goBack : route(ROUTES.projects),
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            isIssueDetailPage: true,
            notification: false,
            search: false
        });
    }, [setData, id, issueDetail, pageTitle, history]);
    return (
        <>
            {!!issueDetail.images.length && <Carousel images={issueDetail.images}/>}
            <StatusMessage item={issueDetail}/>
            <Issue issue={issueDetail}/>
            {(issueDetail.workOrderNo && issueDetail.status === 2) && <WorkOrder {...issueDetail}/>}
            {issueDetail.status === 3 && <CloseIssueDetails issue={issueDetail}/>}
            <Messages issue={issueDetail}/>
        </>
    );
};

export default Details
