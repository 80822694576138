import React, {useMemo} from 'react';
import classes from './style.module.scss';
import FileExcel from '~/assets/icons/file-excel.svg';
import FileImage from '~/assets/icons/file-image.svg';
import FileMusic from '~/assets/icons/file-music.svg';
import FilePDF from '~/assets/icons/file-pdf.svg';
import FilePowerPoint from '~/assets/icons/file-powerpoint.svg';
import FileVideo from '~/assets/icons/file-video.svg';
import FileWord from '~/assets/icons/file-word.svg';
import FileFolder from '~/assets/icons/folder.svg';
import FileFixedFolder from '~/assets/icons/folder_special.svg';
import FileDefault from '~/assets/icons/insert_drive_file.svg';

export interface FileIconProps {
    extension: string
}

export interface FileExtension {
    name: string,
    extensions: string[],
    icon: string
}

export type FileIconComponent = React.FC<FileIconProps>

const FileIcon: FileIconComponent = ({extension}) => {
    const allExtensions = useMemo<FileExtension[]>(() => ([
        {name:'image', extensions: ['gif', 'jpg', 'jpeg', 'tif', 'tiff', 'png'], icon: FileImage},
        {name:'video', extensions: ['3g2', '3gp', 'asf', 'avi', 'flv', 'm4v', 'mov', 'mp4', 'mpg', 'rm', 'srt', 'swf', 'vob', 'wmv'], icon: FileVideo},
        {name:'audio', extensions: ['aif', 'iff', 'm3u', 'm4a', 'mid', 'mp3', 'mpa', 'wav', 'wma'], icon: FileMusic},
        {name:'powerPoint', extensions: ['pptx', 'pptm', 'ppt', 'xps', 'potx', 'pot', 'ppsx', 'ppsm', 'pps', 'ppam', 'ppa'], icon: FilePowerPoint},
        {name:'pdf', extensions: ['pdf'], icon: FilePDF},
        {name:'word', extensions: ['doc', 'docx'], icon: FileWord},
        {name:'excel', extensions: ['csv', 'xlr', 'xls', 'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'xml', 'xla'], icon: FileExcel},
        {name:'folder', extensions: ['folder'], icon: FileFolder},
        {name:'fixedFolder', extensions: ['fixedfolder'], icon: FileFixedFolder},
        {name:'default', extensions: [], icon: FileDefault}
    ]), []);

    const fileIcon = useMemo<FileExtension>(() => {
        return allExtensions.find(extensionGroup => extensionGroup.extensions.indexOf(extension.toLowerCase()) !== -1 || extensionGroup.name === 'default') || allExtensions[allExtensions.length - 1];
    }, [allExtensions, extension]);

    return (
        <div className={classes['icon-wrap']}>
            <img className={classes[fileIcon.name]} src={fileIcon.icon} alt={''}/>
        </div>
    );
};

export default FileIcon
