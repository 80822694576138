import { SidebarModel, sidebarModel } from './App/Sidebar/store';
import { AppHeaderModel, appHeaderModel } from './modules/appHeader';
import { AppLoaderModel, appLoaderModel } from './modules/appLoader';
import { FlashMessageModel, flashMessageModel } from './common/FlashMessage/store';
import { OfflineModel, offlineModel } from './App/Messages/Offline/store';
import { AppDataModel, appDataModel } from './App/store';

export interface AppModel {
  sidebar: SidebarModel
  appHeader: AppHeaderModel
  appLoader: AppLoaderModel
  flashMessage: FlashMessageModel
  offline: OfflineModel
  appData: AppDataModel
}

export const appModel: AppModel = {
  sidebar: sidebarModel,
  appHeader: appHeaderModel,
  appLoader: appLoaderModel,
  flashMessage: flashMessageModel,
  offline: offlineModel,
  appData: appDataModel
};
