import { Language, User } from "~/types";
import { Languages } from "../data/statuses/i18n";
import { localStorageGet } from "./localStorage";

// Prepre language object
export const prepareLanguageObj = (value: Language['value'], flag: Language['flag'], label: Language['label']): Language => ({
    value: value,
    flag: flag,
    label: label
});

// Get current user object from localstorage
export const currentUserLanguage: Languages = (JSON.parse(localStorageGet('currentUser') as string) as User)?.language || Languages.NORWEGIAN;