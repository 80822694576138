import React from 'react';
import useTranslation from "app/hooks/useTranslation";
import FlashMessage from 'app/common/FlashMessage/index';
import classes from './style.module.scss';
// import {useStoreState, useStoreActions} from '~/store';
// import {getTime} from "./../helpers";
// import moment from 'moment';

export type OfflineTimerMessageComponent = React.FC

const OfflineTimerMessage: OfflineTimerMessageComponent = () => {
    const {t} = useTranslation("offline.dialog");
   /* const [seconds, setSeconds] = useState<any>(0);
    const time = useStoreState(state => state.app.offline.time);
    const status = useStoreState(state => state.app.offline.status);
    const networkStatus = useStoreState(state => state.app.offline.networkStatus);
    const setStatus = useStoreActions(actions => actions.app.offline.setStatus);*/

    /*useEffect(() => {
        let newSeconds = moment(time).diff(moment(), 'seconds');
        setSeconds(newSeconds ? newSeconds : 0);

        const interval = setInterval(() => {
            setSeconds(newSeconds = newSeconds - 1);
            newSeconds <= 0 && setStatus(networkStatus);
        }, 1000);

        return () => clearInterval(interval);
    }, [time, status, networkStatus, setStatus])*/

    return (
        <FlashMessage type="dark" icon={"error"}>
            <div className={classes['content']}>
                {t("title")}
                {/*<span>{getTime(seconds)}</span>*/}
            </div>
        </FlashMessage>
    );
};

export default OfflineTimerMessage
