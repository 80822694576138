import React from 'react';
import classes from './style.module.scss';
import {MachineSpecification} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";

export interface ListItemProps {
    item: Partial<MachineSpecification>
    itemKey: string
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({itemKey, item}) => {

    // For the "documentation.specifications" namespace
    const {t: tDocsSpec } = useTranslation("documentation.specifications");
    // For the "constant" namespace
    const {t: tConstant  } = useTranslation("constant");

    const value = item ?? '-';

    return (
        <div className={`${classes['list-item']}`}>
            <div className={classes['title']}>{tDocsSpec(`${itemKey}`)}</div>
            <div className={classes['value']}>{tConstant(`${value}`, `${value}`)}</div>
        </div>
    );
};

export default ListItem
