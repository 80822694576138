import {action, Action, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {PeriodicCheckPayload, PeriodicCheck, PeriodicChecksPayload, Machine, LastPeriodicCheck} from '~/types';
import {StoreModel} from "~/store";
import axios from "axios";
import {API} from "~/app/routes";
import {PeriodicCheckOfflineService} from "~/sqlite/services/periodicCheck";

interface ChecksDataPayload {
    inspectionType: Machine['inspectionType']
    checks: PeriodicCheck[]
    lastRegisteredCheck: LastPeriodicCheck | undefined
}

export enum InspectionTypes {
    DAILY = 0,
    PERIODIC = 1,
    ACCEPTANCE_INSPECTION = 2
}

export interface PeriodicCheckModel {
    checksData: PeriodicCheckPayload[]
    inspectionType: Machine['inspectionType']
    lastRegisteredCheck: ChecksDataPayload['lastRegisteredCheck']

    currentCheck: PeriodicCheckPayload | null
    updateCurrentCheck: Action<PeriodicCheckModel, PeriodicCheckPayload | null>

    loadChecksData: Action<PeriodicCheckModel, ChecksDataPayload>
    updateCheckData: Action<PeriodicCheckModel, PeriodicCheckPayload>
    isChecksDataUpdated: Computed<PeriodicCheckModel>

    updating: boolean
    setUpdating: Action<PeriodicCheckModel, boolean>
    update: Thunk<PeriodicCheckModel, PeriodicChecksPayload, any, StoreModel, Promise<void>>
    setOfflinePeriodicChecksData: Thunk<PeriodicCheckModel, PeriodicChecksPayload, any, StoreModel, any>
}

export const periodicCheckModel: PeriodicCheckModel = {
    checksData: [],
    inspectionType: 0,
    lastRegisteredCheck: undefined,

    currentCheck: null,
    updateCurrentCheck: action((state, check) => {
        state.currentCheck = check;
    }),

    loadChecksData: action((state, data) => {
        state.inspectionType = data.inspectionType;
        state.lastRegisteredCheck = data.lastRegisteredCheck;
        state.checksData = [];
        data.checks.map(check => state.checksData.push({
            ...check,
            status: 0,
            issues: [],
            quantity: 0
        }))
    }),
    updateCheckData: action((state, check) => {
        state.checksData = state.checksData.map(item => item.id === check.id ? check : item);
    }),
    isChecksDataUpdated: computed(state => state.checksData.find(check => check.status !== 0) ? true : false),

    updating: false,
    setUpdating: action((state, payload) => {
        state.updating = payload;
    }),
    update: thunk((actions, payload) => {
        actions.setUpdating(true);
        return axios.patch<null, null>(`${API.periodicCheck}/save`, payload)
            .then(data => {
                actions.setUpdating(false);
            })
            .finally(() => {
                actions.setUpdating(false);
            });
    }),
    setOfflinePeriodicChecksData: thunk(async (actions, payload) => {
        const PeriodicCheckOffline = new PeriodicCheckOfflineService();
        return await PeriodicCheckOffline.updatePeriodicChecks(payload);
    })
};
