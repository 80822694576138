import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { setStatusBarColor } from '~/app/helpers/statusBar';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';
import Icon from '~/app/common/Icon';

import { useStoreActions, useStoreState } from '~/store';

import bardeLogo from '~/assets/images/barde-logo.png';

import Title from './Title';
import UserDropdown from './UserDropdown';
import Favorite from './Favorite'

import classes from './style.module.scss';
import LinkWrapper from '~/app/common/LinkWrapper';
import { REQUESTS_ROUTES } from '~/notifications/Router';
import classNames from 'classnames';
import IssueOptions from './IssueOptions';

export type AppHeaderComponent = React.FC

export const AppHeader: AppHeaderComponent = () => {
  const history = useHistory();

  const title = useStoreState(state => state.app.appHeader.title);
  const backLink = useStoreState(state => state.app.appHeader.backLink);
  const backLinkIcon = useStoreState(state => state.app.appHeader.backLinkIcon);
  const chatIcon = useStoreState(state => state.app.appHeader.chatIcon);
  const menuIcon = useStoreState(state => state.app.appHeader.menuIcon);
  const searchIcon = useStoreState(state => state.app.appHeader.searchIcon);
  const showUser = useStoreState(state => state.app.appHeader.showUser);
  const machineId = useStoreState(state => state.app.appHeader.machineId);
  const projectId = useStoreState(state => state.app.appHeader.projectId);
  const parentFolderId = useStoreState(state => state.app.appHeader.parentFolderId);
  const isIssueDetailPage = useStoreState((state) => state.app.appHeader.isIssueDetailPage);

  const setIsMenuOpen = useStoreActions(actions => actions.app.sidebar.setOpen);
  const notification = useStoreState(state => state.app.appHeader.notification);
  const search = useStoreState(state => state.app.appHeader.search);
  const isSearchOpen = useStoreState(state => state.notification.isSearchOpen);
  const setIsSearchOpen = useStoreActions(actions => actions.notification.setIsSearchOpen);
  const unreadNotificationCount = useStoreState(state => state.notification.unreadNotificationCount);
  const netWorkStatus = useStoreState((state) => state.app.offline.status);

  const backLinkClickHandler = useCallback(() => {
    if (typeof backLink === 'string') {
      history.push(backLink);
    } else if (backLink) {
      backLink();
    }
  }, [backLink, history]);

  const menuClickHandler = useCallback(() => {
    setIsMenuOpen(true);
  }, [setIsMenuOpen]);

  const searchClickHandler = useCallback(() => {
    setIsSearchOpen(!isSearchOpen);
  }, [isSearchOpen, setIsSearchOpen]);

  useDidMountEffect(() => {
    setStatusBarColor(getComputedStyle(document.documentElement).getPropertyValue('--color-header'));
  });

  return (
    <header className={`${classes['app-header']} ${parentFolderId && classes['light']}`}>
      <div className={classes['left-wrap']}>
        {(
          backLink
            ? <Icon name={backLinkIcon} onClick={backLinkClickHandler} />
            : <Icon name={menuIcon} onClick={menuClickHandler} />
        )}
      </div>

      {(title ? <Title/> : <img className={classes['logo']} src={bardeLogo} alt="Barde" />)}
      

      <div className={classes['right-wrap']}>
        {(
          notification && netWorkStatus === 0 && 
          <LinkWrapper to={REQUESTS_ROUTES.notifications}>
            <div className={classes['chat-icon-wrap']}>
              <Icon name={chatIcon} className={classNames(unreadNotificationCount ? classes['notification-badge-icon'] : "")}/>
            </div>
          </LinkWrapper>
        )}
        {(
          search && <Icon name={searchIcon} onClick={searchClickHandler}/>
        )}
        {(
          showUser
            ? <UserDropdown/>
            : (
              machineId
                ? <Favorite id={machineId} type={'machine'} />
                : (
                  projectId 
                  ? <Favorite id={projectId} type={'project'} /> 
                  : (isIssueDetailPage ? <IssueOptions /> : undefined))
            )
        )}
      </div>
    </header>
  );
};
