import React from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import {useStoreState} from "~/store";
import { OperatingTypes } from '~/app/data/statuses/operatingData';
import { OperatingUnitTypePayload } from '~/types';

export interface HeaderProps {
    operatingUnitType: OperatingUnitTypePayload
}
export type HeaderComponent = React.FC<HeaderProps>;
const Header: HeaderComponent = ({operatingUnitType}) => {
    const {t} =  useTranslation(operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'operatingCount' : 'operatingTime');
    const machine = useStoreState(state => state.machine.data);

    return (
        <div className={classes['model-header']}>
            <Icon name={"speed"} className={classes['icon']}/>
            <div className={classes['header-content']}>
                <label className={classes['title']}>
                    {t(`${operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'registerNewCounter' : (machine.categoryType === 'vehicle' ? 'registerNewKm' : 'registerNewTimer')}`)}
                </label>
                <label className={classes['sub-title']}>
                    {t(`${operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'previousCount' : 'previousKm'}`, operatingUnitType === OperatingTypes.OPERATING_COUNT
                        ? {
                            operatingCount: machine.operatingCount
                        }
                        : {
                            operatingTime: machine.operatingTime,
                            type: machine.operatingType
                        })}
                </label>
            </div>
        </div>
    );
};

export default Header
