import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Axios from '~/axiosWrapper';

import Dimmer from '~/app/common/Dimmer';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';

import Text, { TextProps } from '../Text';

import Input from './Input';
import List, { ListProps } from './List';
import { ListDefaultItem } from './List/ListItem';

import classes from './style.module.scss';

type SearchPropsDefault<T> = TextProps & Pick<ListProps<T>, 'renderItem' | 'onSelect'>

export interface SearchProps<T> extends SearchPropsDefault<T> {
  searchUrl: string
  searchKey?: keyof T
  searchKeys?: Array<keyof T>
  renderValue?: (item: T) => string
  onOpen?: () => void
  onClose?: () => void
}

const Search = <T extends ListDefaultItem = ListDefaultItem>(
  {
    searchUrl,
    searchKey = 'name',
    searchKeys,
    renderItem,
    defaultValue,
    onSelect,
    onOpen,
    onClose,
    ...props
  }: SearchProps<T>
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputWrapStyle, setInputWrapStyle] = useState<React.CSSProperties>({});
  const [textValue, setTextValue] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [list, setList] = useState<T[]>([]);
  const [searchedList, setSearchedList] = useState<T[]>([]);

  const triggerTextValue = useCallback(item => {
    setTextValue(renderItem ? renderItem(item) : item[searchKey]);
  }, [renderItem, searchKey]);

  /*const search = useCallback(
    (value: string, key = searchKey): Promise<any[]> => Axios.get<any[], any[]>(
      searchUrl,
      { params: { $filter: `contains(tolower(${key}),'${value.toLowerCase()}')` } }
    ),
    [searchKey, searchUrl]
  );*/

  const searchFormSearchHandler = useCallback((value: string): void => {
    setValue(value)
  }, []);

  const inputFocusHandler = useCallback((): void => {
    setIsOpen(true);
  }, []);

  const inputBlurHandler = useCallback(({ target: { value } }): void => {
    if (!value) {
      setIsOpen(false);
    }
  }, []);

  const listSelectHandler = useCallback((item: any): void => {
    setIsOpen(false);

    triggerTextValue(item);

    if (onSelect) {
      onSelect(item);
    }
  }, [onSelect, triggerTextValue]);

  const dimmerClickHandler = useCallback(() => {
    if (!value && !searchedList.length) {
      setIsOpen(false);
    }
  }, [searchedList.length, value]);

  useEffect(() => {
    if (isOpen) {
      if (onOpen) {
        onOpen();
      }
    } else {
      setInputWrapStyle({});
      setValue('');
      setSearchedList([]);

      if (onClose) {
        onClose();
      }
    }
  }, [isOpen, onClose, onOpen]);

  useLayoutEffect(() => {
    if (isOpen && inputRef.current) {
      const { top } = inputRef.current.getBoundingClientRect();

      setInputWrapStyle({
        top: top + 'px',
        paddingTop: 'var(--size-12)',
        transform: `translateY(-${top}px)`
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (value) {
      setSearchedList(list.filter(item => (
        ((searchKeys?.length ? searchKeys.map(key => item[key]).join(" ") : renderItem ? renderItem(item) : item[searchKey]) as string).toLowerCase().indexOf(value.toLowerCase()) !== -1
      )));
    } else {
      setSearchedList([]);
    }
  }, [list, renderItem, searchKeys, searchKey, value]);

  useDidMountEffect(() => {
    if (defaultValue) {
      setIsSearching(true);

      Axios.get<T, T>(`${searchUrl}/${defaultValue}`)
        .then(triggerTextValue)
        .finally(() => {
          setIsSearching(false);
        });
    }
  });

  useDidMountEffect(() => {
    setIsSearching(true);

    Axios.get<T[], T[]>(searchUrl)
      .then(setList)
      .finally(() => {
        setIsSearching(false);
      });
  });

  return (
    <>
      <Text {...props} ref={inputRef} icon="search" value={textValue} readOnly onFocus={inputFocusHandler} />

      {ReactDOM.createPortal((
        <Dimmer page show={isOpen} onClick={dimmerClickHandler}>
          <div className={classes['search-wrap']} style={inputWrapStyle}>
            <Input
              {...props}
              loading={isSearching}
              onSearch={searchFormSearchHandler}
              onFocus={inputFocusHandler}
              onBlur={inputBlurHandler}
              value={textValue}
            />

            {!!value && !isSearching && (
              <List<T> list={searchedList} searchKey={searchKey} renderItem={renderItem} onSelect={listSelectHandler} />
            )}
          </div>
        </Dimmer>
      ), (document.getElementById('root') as HTMLElement))}
    </>
  );
};

export default Search
