import store from "~/store";
import QueryBuilder from "./queryBuilder";
import moment from 'moment';
import {PeriodicChecksPayload} from "~/types";
import {syncTypes} from "~/sqlite/syncDataTypes";
import {InspectionTypes} from "~/periodicCheck/store";

export class PeriodicCheckOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public updatePeriodicChecks(data: PeriodicChecksPayload) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            return new Promise((resolve, reject) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.insert('SyncOfflineData', [
                        {
                            data: JSON.stringify({
                                machineId: data.machineId,
                                projectId: data.projectId,
                                inspectionType: data.inspectionType,
                                checks: data.checks,
                                operatingTime: store.getState().machine.data.operatingTime,
                                operatingCount: store.getState().machine.data.operatingCount,
                                userId: store.getState().user.data.id,
                                updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                                guid: data.guid
                            }),
                            userId: store.getState().user.data.id,
                            typeOfData: syncTypes.ADD_CHECK_PERIOD
                        }
                    ]));

                    const where = [{name: 'id', value:  data.machineId}];

                    tx.executeSql(qb.select('Machine', [], where), [], (SQLite, result) => {
                        const excavatorData = qb.getSingleResult(result);

                        if(excavatorData){
                            const excavatorDetails = JSON.parse(excavatorData.data)
                            excavatorDetails[data.inspectionType === InspectionTypes.ACCEPTANCE_INSPECTION ? 'lastAcceptanceInspection' : 'lastDailyCheck'] = {
                                id: data.machineId,
                                userId: store.getState().user.data.id,
                                userName: store.getState().user.data.name,
                                date: moment().format("YYYY-MM-DD")
                            }
                            console.log('lastDailyCheck', excavatorDetails)
                            tx.executeSql(qb.update('Machine', {
                                data: JSON.stringify(excavatorDetails)
                            }, where));
                        }
                    });
                }, (e) => reject(e), () => resolve());
            });
        }
    }
}
