import React from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import classes from "./style.module.scss";
import {useStoreState} from '~/store';

export type HeaderComponent = React.FC

const Header: HeaderComponent = () => {
    const {t} = useTranslation("periodicCheck");
    const inspectionType = useStoreState(state => state.periodicCheck.inspectionType);
    const lastRegisteredCheck = useStoreState(state => state.periodicCheck.lastRegisteredCheck);
    return (
        <div className={classes['header']}>
            <div className={classes['title']}>{t(`${inspectionType}.title`)}</div>
            {(lastRegisteredCheck && lastRegisteredCheck.id) &&
            <>
                <div className={classes['last-registered']}>{t('lastRegistered')}</div>
                <div className={classes['user']}>{lastRegisteredCheck.userName}</div>
                <div className={classes['date']}>{t('date', {date: lastRegisteredCheck.date})}</div>
            </>}
        </div>
    );
};

export default Header
