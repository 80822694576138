import React from 'react';

import useTranslation from '~/app/hooks/useTranslation';

import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import Button from '~/app/common/Button';

export type MenuProps = {
    onSelect?(): void,
    onEdit?(): void
}

export type ActionsComponent = React.FC<MenuProps>

const Actions: ActionsComponent = ({ onSelect, onEdit }) => {
    const { t } = useTranslation('header.issueOptions');

    return (
        <ul className={classes['menu']}>
            <li className={classes['edit']} onClick={onEdit}>
                <Icon className={classes['icon']} name="edit" /> {t('edit')}
            </li>
            <li>
                <Button block={true} className={classes['abortBtn']} onClick={onSelect}>{t('abort')}</Button>
            </li>
        </ul>
    );
};

export default Actions
