export enum Languages {
    ENGLISH = 'en',
    NORWEGIAN = 'no',
}

export enum LanguagesFlags {
    ENGLISH = 'gb',
    NORWEGIAN = 'no',
}

export const mapLanguageFlags = {
    [Languages.NORWEGIAN]: LanguagesFlags.NORWEGIAN,
    [Languages.ENGLISH]: LanguagesFlags.ENGLISH
}