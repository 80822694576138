import React, { useEffect, useState } from 'react';
import { useStoreState } from 'store';

import Transition from '~/app/common/Transition';

import classes from './style.module.scss';
import Icon from "~/app/common/Icon";

export type TitleComponent = React.FC

const Title: TitleComponent = () => {
  const [prevTitle, satPrevTitle] = useState<React.ReactNode | undefined>(<>&nbsp;</>);
  const [currentSubTitle, satCurrentSubTitle] = useState<React.ReactNode | undefined>(undefined);

  const title = useStoreState(state => state.app.appHeader.title);
  const subTitle = useStoreState(state => state.app.appHeader.subTitle);
  const parentFolderId = useStoreState(state => state.app.appHeader.parentFolderId);

  useEffect(() => {
    setTimeout(() => {
      satPrevTitle(title || prevTitle)
    }, (prevTitle !== title ? 0 : 150));
  }, [prevTitle, title]);
  useEffect(() => {
    setTimeout(() => {
      satCurrentSubTitle(subTitle)
    }, (subTitle ? 0 : 150));
  }, [subTitle]);

  return (
    <div className={`${classes['title-wrap']} ${parentFolderId && classes['light']}`}>
      <Transition
        in={prevTitle !== title}
        mountOnEnter={false}
        unmountOnExit={false}
        classNames={{
          enter: classes['title-enter'],
          enterActive: classes['title-enter-active'],
          enterDone: classes['title-enter-done'],
          exit: classes['title-exit'],
          exitActive: classes['title-exit-active'],
        }}
      >
        <div className={classes['title']}>
            <span>
              {!!parentFolderId && <Icon name={'folder_open'} className={classes['folder-icon']}/>}
              {title}<br/>
              {prevTitle}
            </span>
        </div>
      </Transition>

      <Transition
        in={!!subTitle}
        classNames={{
          enter: classes['sub-title-enter'],
          enterActive: classes['sub-title-enter-active'],
          enterDone: classes['sub-title-enter-done'],
          exit: classes['sub-title-exit'],
          exitActive: classes['sub-title-exit-active'],
        }}
      >
        <div className={classes['sub-title']}>{currentSubTitle}</div>
      </Transition>
    </div>
  );
};

export default Title
