import React, {useEffect, useState} from 'react';
import {useStoreState, useStoreActions} from '~/store';
import Loader from "~/app/common/Loader";
import {useParams} from 'react-router-dom';
import {IssueDetails} from "~/types";
import {IssueStatuses} from "~/app/data/statuses/issue";
import AccessControl from "~/accessControl";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import Details from "~/issues/Details/details";

export type IssueDetailsComponent = React.FC

const IssueDetail: IssueDetailsComponent = () => {
    const [fetching, setFetching] = useState<boolean>(true);
    const {issueId} = useParams<{ issueId: IssueDetails['id'] }>();

    const issueDetail = useStoreState(state => state.issue.data);
    const IssueFetchAction = useStoreActions(actions => actions.issue.fetchIssue);
    const IssueFetchOfflineAction = useStoreActions(actions => actions.issue.fetchOfflineIssue);
    const netWorkStatus = useStoreState(state => state.app.offline.status);

    useEffect(() => {
        (netWorkStatus ? IssueFetchOfflineAction : IssueFetchAction)(issueId).then(() => setFetching(false));
    }, [issueId, setFetching, IssueFetchAction, IssueFetchOfflineAction, netWorkStatus]);

    if (fetching) {
        return <Loader size="32"/>;
    }

    if (issueDetail.status === IssueStatuses.COMPLETED || issueDetail.status === IssueStatuses.CLOSED) {
        return (
            <AccessControl requiredPermissions={PERMISSION_GROUPS.readFinishedIssues} showMessage={true}>
                <Details issueDetail={issueDetail}/>
            </AccessControl>
        );
    }

    return <Details issueDetail={issueDetail}/>;
};

export default IssueDetail
