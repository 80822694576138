import React from 'react';
import classes from './style.module.scss';
import {IssueDetails, WorkDetails} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";
import {IssueStatuses} from "~/app/data/statuses/issue";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import AccessControl from "~/accessControl";

export interface WorkOrderProps {
    status: IssueDetails['status']
    workDetails: WorkDetails
}

export type ListComponent = React.FC<WorkOrderProps>

const List: ListComponent = ({status, workDetails}) => {
    const {t} = useTranslation("issue");

    const content = (
        <div className={classes['order-time-entry']}>
            <div className={classes['time-and-name']}>
                <span className={classes['time']}>{t("workDate", {date: workDetails.date})}</span>
                <span className={classes['name']}>{workDetails.name}</span>
            </div>
            <div className={classes['comment']}>{workDetails.comment}</div>
            <div className={classes['hours-cost']}>
                <span className={classes['hours']}>{workDetails.hours}</span>
                <span className={classes['cost']}>{workDetails.cost}</span>
            </div>
        </div>
    );

    if (status === IssueStatuses.COMPLETED || status === IssueStatuses.CLOSED) {
        return (
            <AccessControl requiredPermissions={PERMISSION_GROUPS.readCompletedWorkOrderDetails}>
                {content}
            </AccessControl>
        )
    }

    return content;
};

export default List
