import store from "~/store";
import QueryBuilder from "./queryBuilder";
import {AllUsers, Department, IssuePayload, syncResponse} from "~/types";
import FileTransfer from "~/sqlite/services/fileTransfer";
// import {parseBodyFields} from "~/user/helpers";
import {syncTypes} from '~/sqlite/syncDataTypes'

export class AppOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public resetDepartments(data: Department[]) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            return new Promise((resolve, reject) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.truncate('Departments'));
                    tx.executeSql(qb.insert('Departments', data));
                }, () => reject(), () => resolve());
            });
        }
    }

    public getAllDepartments() {
        if (this.SQLite) {
            return new Promise<Department[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('Departments'), [], (resultSet) => resolve(qb.getAllResult(resultSet)), (e) => reject());
            });
        }
    }

    public resetUsers(data: AllUsers[]) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            return new Promise((resolve, reject) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.truncate('Users'));
                    tx.executeSql(qb.insert('Users', data));
                }, () => reject(), () => resolve());
            });
        }
    }

    public getAllUsers() {
        if (this.SQLite) {
            return new Promise<AllUsers[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('Users'), [], (resultSet) => resolve(qb.getAllResult(resultSet)), (e) => reject());
            });
        }
    }

    public prepareChecksIssues(checks, issuesResonse) {
        checks.forEach(check => {
            if (check.issues) {
                let issues: any = [];
                check.issues.forEach(issue => {
                    if (issue.offlineIssueId !== undefined) {
                        issuesResonse[issue.offlineIssueId] !== undefined && issues.push(issuesResonse[issue.offlineIssueId]);
                    } else {
                        issues.push(issue);
                    }
                });
                check.issues = issues;
            }
        });
        return checks;
    }

    public sync() {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            const fileTransfer = new FileTransfer();
            let data: any = [];
            const syncWhere = [
                {name: 'userId', value: store.getState().user.data.id}
            ];
            return new Promise<syncResponse | void>((resolve, reject) => {
                this.SQLite.transaction((SQLite) => {

                    SQLite.executeSql(qb.select('SyncOfflineData', [], syncWhere), [], (SQLite, result) => {
                        if (result && result.rows.length > 0) {
                            for (let i = 0; i < result.rows.length; i++) {
                                data.push(result.rows.item(i));
                            }
                        }
                    });
                }, (error) => resolve(), async () => {
                    // data.sort((a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)));
                    // let issuesResonse = {};
                    let syncResponse: {issueIds: string[], updatedIssuesDetails: IssuePayload[]} = {
                        issueIds: [],
                        updatedIssuesDetails: []
                    };
                    let currentMachineId = store.getState().machine.data.id;
                    let reloadCurrentMachine = false;
                    const actionsList: any = {
                        [syncTypes.UPDATE_OPERATING_DATA]: store.getActions().machine.registerOperatingTimeOrCount,
                        [syncTypes.ADD_CHECK_PERIOD]: store.getActions().periodicCheck.update,
                        [syncTypes.ADD_ISSUE]: store.getActions().issue.saveIssue
                    };
                    let imageURLsToRemove = [];
                    for (let singleData of data) {
                        let imagesURLs = [];
                        let requestData = JSON.parse(singleData.data);
                        reloadCurrentMachine = requestData && requestData?.machineId.toString() === currentMachineId.toString() ? true : reloadCurrentMachine;
                        if (singleData.typeOfData === syncTypes.ADD_ISSUE && JSON.parse(singleData.images).length) {
                            imagesURLs = JSON.parse(singleData.images);
                            await fileTransfer.getBlobsFromURLs(imagesURLs).then(images => (singleData.images = images));
                        } else {
                            singleData.images = []
                        }

                        /*let bodyData = {}
                        if(singleData.typeOfData === syncTypes.ADD_ISSUE ){
                            const issueFields = ['issueType', 'machineId', 'projectId', 'categoryId', 'categoryName', 'description','departmentId', 'performedBy', 'type', 'images'];
                            bodyData = parseBodyFields({...JSON.parse(singleData.data), images: singleData.images}, issueFields)
                        }*/
                        singleData.typeOfData in actionsList &&
                        await (actionsList[singleData.typeOfData])({...requestData, images:singleData.images})
                            .then(async (response) => {
                                await this.SQLite.transaction(function (tx) {
                                    tx.executeSql(qb.delete('SyncOfflineData', [{
                                        name: 'id',
                                        value: singleData.id
                                    }]));
                                });

                                if(requestData?.id && response?.id !== undefined) {
                                    syncResponse.updatedIssuesDetails.push({...requestData, id: response.id});
                                } else if(singleData.typeOfData === syncTypes.ADD_ISSUE && response?.id !== undefined) {
                                    syncResponse.issueIds.push(response.id);
                                }
                                !!imagesURLs.length && imagesURLs.forEach((imageURL) => imageURLsToRemove.push(imageURL));
                        });

                    }
                    !!imageURLsToRemove.length && await fileTransfer.removeFiles(imageURLsToRemove);

                    if(reloadCurrentMachine){
                        store.getActions().machine.fetchMachine(store.getState().machine.data.id);
                    }
                    resolve(syncResponse);
                });
            });
        }
    }
}
