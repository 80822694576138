import { EffectCallback, useEffect } from 'react';

export interface UseDidMountEffect {
  (effect: EffectCallback): void
}

const useDidMountEffect: UseDidMountEffect = (effect) => {
  useEffect(effect, []); // eslint-disable-line
};

export default useDidMountEffect
