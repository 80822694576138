import React from 'react';
import classNames from 'classnames';
import {route} from "~/app/helpers/route";
import Icon from '~/app/common/Icon';
import {useStoreState} from '~/store';
import {Project} from '~/types';
import classes from './style.module.scss';
import LinkWrapper from "~/app/common/LinkWrapper";
import {MACHINES_ROUTES} from "~/machines/Router";

export interface ListItemProps {
    item: Project
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item}) => {
    const inFavorite = useStoreState(state => state.projects.inFavorite(item.id));

    return (
        <LinkWrapper className={classes['list-item']} to={route(MACHINES_ROUTES.machines, {projectId: item.id})}>
            <div className={classes['category-image']}>
                <img src={item.image} alt={''}/>
            </div>
            <div className={classes['info']}>
                <div className={classes['name']}>{item.name}</div>
                <div className={classes['code']}>{item.number}</div>
            </div>

            <div className={classNames(classes['favorite-wrap'], {[classes['in-favorite']]: inFavorite})}>
                {
                    inFavorite
                        ? <Icon name={inFavorite ? 'star' : 'star_border'}/>
                        : ''
                }
            </div>
        </LinkWrapper>
    );
};

export default ListItem
