import { action, Action, actionOn, ActionOn, computed, Computed, memo } from 'easy-peasy';
import { isEqual } from 'lodash';

import { StoreModel } from '~/store';

export enum ValidatorsEnum {
  required = 'required',
  number = 'number',
  integer = 'integer',
}

export type FieldValue = string | number | string[] | any[]
export interface FieldValidatorFieldData extends Omit<Field, 'validators'> {
  name: string
}
export type FieldValidatorFormData = {
  state: FormState
  values: FormValues
  errors: FormErrors
}
export type FieldValidator = (field: FieldValidatorFieldData, form: FieldValidatorFormData) => boolean
export type FieldValidators = { [key in ValidatorsEnum]: FieldValidator } & { [key: string]: FieldValidator }
export type FieldError = (ValidatorsEnum | string)
export type FieldErrors = FieldError[]
export type FieldState = { touched: boolean, invalid: boolean }

export type Field = {
  value: FieldValue
  validators: (ValidatorsEnum | [string, FieldValidator])[]
  errors: FieldErrors
  state: FieldState
}

export type Fields = { [name: string]: Field }
export type FieldsPayload = { [name: string]: Partial<Field> }

export type FormState = { touched: boolean, invalid: boolean }
export type FormValues = { [name: string]: FieldValue }
export type FormValidators = { [name: string]: FieldValidators[] }
export type FormErrors = { [name: string]: FieldErrors }

const Validators = {
  [ValidatorsEnum.required]: value => (value !== '' && value !== undefined && value !== null),
  [ValidatorsEnum.number]: value => typeof value === 'number',
  [ValidatorsEnum.integer]: value => Number.isInteger(value)
};

export interface FormModel {
  forms: {
    [name: string]: Fields
  }

  formFields: Computed<FormModel, (formName: string) => Fields, StoreModel>
  formState: Computed<FormModel, (formName: string) => FormState, StoreModel>

  getForm: Computed<FormModel, (formName: string) => Fields, StoreModel>
  addForm: Action<FormModel, { formName: string, fields: FieldsPayload }>
  updateForm: Action<FormModel, { formName: string, fields: FieldsPayload }>
  removeForm: Action<FormModel, string>

  getValues: Computed<FormModel, (formName: string) => (FormValues | undefined), StoreModel>
  setValue: Action<FormModel, { formName: string, name: string, value: FieldValue }>

  getValidators: Computed<FormModel, (formName: string) => FormValidators, StoreModel>

  getErrors: Computed<FormModel, (formName: string) => FormErrors, StoreModel>
  addError: Action<FormModel, { formName: string, name: string, error: FieldError }>
  removeError: Action<FormModel, { formName: string, name: string, error: FieldError }>
  clearErrors: Action<FormModel, { formName: string, name: string }>

  setDefaultValues: Action<FormModel, { formName: string, defaultValues: FormValues }>

  onSetValue: ActionOn<FormModel, StoreModel>
  onSetError: ActionOn<FormModel, StoreModel>
}

export const formModel: FormModel = {
  forms: {},

  formFields: computed(state => memo(formName => (state.forms[formName] || {}), 100)),
  formState: computed(state => memo(formName => {
    if (!state.forms[formName]) {
      return { touched: false, invalid: false };
    }

    let touched = false;
    let invalid = false;

    Object.keys(state.forms[formName]).forEach(name => {
      if (state.forms[formName][name].state.touched) {
        touched = true;
      }
      if (state.forms[formName][name].state.invalid) {
        invalid = true;
      }
    });

    return { touched, invalid }
  }, 100)),

  getForm: computed(state => formName => (state.forms[formName] || {})),
  addForm: action((state, { formName, fields }) => {
    if (!state.forms[formName]) {
      state.forms[formName] = {};
    }

    Object.keys(fields).forEach(name => {
      state.forms[formName][name] = {
        value: '',
        validators: [],
        errors: [],
        ...fields[name],
        state: { touched: false, invalid: false }
      };
    });
  }),
  updateForm: action((state, { formName, fields }) => {
    if (!state.forms[formName]) {
      state.forms[formName] = {};
    }

    Object.keys(fields).forEach(name => {
      state.forms[formName][name] = { ...(state.forms[formName][name] || {}), ...fields[name] };
    });
  }),
  removeForm: action((state, formName) => {
    delete state.forms[formName];
  }),

  getValues: computed(state => memo(formName => {
    if (!state.forms[formName]) {
      return;
    }

    const values = {};
    
    Object.keys(state.forms[formName]).forEach(name => {
      values[name] = state.forms[formName][name].value;
    });

    return values;
  }, 100)),
  setValue: action((state, { formName, name, value }) => {
    if (state.forms[formName] && state.forms[formName][name]) {
      state.forms[formName][name].value = value;
    }
  }),

  getValidators: computed(state => memo(formName => {
    if (!state.forms[formName]) {
      return {};
    }

    const validators: FormValidators = {};

    Object.keys(state.forms[formName]).forEach(name => {
      // @ts-ignore
      validators[name] = {};

      state.forms[formName][name].validators.forEach(validator => {
        if (Array.isArray(validator)) {
          validators[name][validator[0]] = validator[1];
        } else {
          validators[name][validator] = Validators[validator];
        }
      });
    });

    return validators;
  }, 100)),

  getErrors: computed(state => memo(formName => {
    if (!state.forms[formName]) {
      return {};
    }

    const errors = {};

    Object.keys(state.forms[formName]).forEach(name => {
      if (state.forms[formName][name].errors.length) {
        errors[name] = state.forms[formName][name].errors;
      }
    });

    return errors;
  }, 100)),
  addError: action((state, { formName, name, error }) => {
    if (state.forms[formName] && state.forms[formName][name]) {
      const index = state.forms[formName][name].errors.findIndex(e => e === error);

      if (index === -1) {
        state.forms[formName][name].errors.push(error);
      }
    }
  }),
  removeError: action((state, { formName, name, error }) => {
    if (state.forms[formName] && state.forms[formName][name]) {
      const index = state.forms[formName][name].errors.findIndex(e => e === error);

      if (index !== -1) {
        state.forms[formName][name].errors.splice(index, 1);
      }
    }
  }),
  clearErrors: action((state, { formName, name }) => {
    if (state.forms[formName] && state.forms[formName][name]) {
      state.forms[formName][name].errors = [];
    }
  }),

  setDefaultValues: action((state, { formName, defaultValues }) => {
    if (state.forms[formName]) {
      const values = {};

      Object.keys(state.forms[formName]).forEach(name => {
        values[name] = state.forms[formName][name].value;
      });

      if (!isEqual(values, defaultValues)) {
        Object.keys(state.forms[formName]).forEach(name => {
          state.forms[formName][name].value = defaultValues[name];
        });
      }
    }
  }),

  onSetValue: actionOn(
    actions => actions.setValue,
    (state, { payload: { formName, name } }) => {
      if (state.forms[formName] && state.forms[formName][name]) {
        state.forms[formName][name].state.touched = true;
      }
    }
  ),
  onSetError: actionOn(
    actions => [actions.addError, actions.removeError],
    (state, { payload: { formName, name } }) => {
      if (state.forms[formName] && state.forms[formName][name]) {
        state.forms[formName][name].state.invalid = !!state.forms[formName][name].errors.length;
      }
    }
  )
};
