import _ from 'lodash';
import moment from 'moment';

import {Machine, MachineTable} from '~/types';
import store from "~/store";
import QueryBuilder from "./queryBuilder";
import FileTransfer from "./fileTransfer";
import {IssueStatuses, IssueTypes} from "~/app/data/statuses/issue";
import {syncTypes} from "~/sqlite/syncDataTypes";
import { OperatingTypes } from '~/app/data/statuses/operatingData';
import { OperatingTimePayload } from '~/machines/store/machine';

export class MachineOfflineService {
    private SQLite;

    constructor() {
        this.SQLite = store.getState().app.offline.SQLiteConnection;
    }

    public reset(machines: Machine[]) {
        if (this.SQLite) {
            return new Promise((resolve, reject) => {
                this.downloadMachinesImages(machines).then(({machines, deleteMachines}) => {
                    const qb = new QueryBuilder();
                    const fileTransfer = new FileTransfer();
                    this.SQLite.transaction((tx) => {
                        if(deleteMachines.length){
                            tx.executeSql(qb.deleteIn('Machine' , 'id',  deleteMachines));
                            tx.executeSql(qb.deleteIn('Documents' , 'machineId',  deleteMachines));
                            tx.executeSql(qb.deleteIn('Issue' , 'machineId',  deleteMachines));
                            deleteMachines.forEach((machine) => {
                                fileTransfer.removeFolder(`issueImages/${machine}`);
                                fileTransfer.removeFolder(`archives/${machine}`);
                            })
                        }
                        if(machines) {
                            tx.executeSql(qb.insert('Machine', machines));
                        }
                    }, () => reject(), () => resolve());
                });
            });
        }
    }

    public downloadMachinesImages(machinesWithImage: Machine[]) {

        const allPromise: Promise<MachineTable>[] = [];
        const qb = new QueryBuilder();
        return new Promise<{machines: MachineTable[], deleteMachines: string[]}>((resolve, reject) => {
            this.SQLite.executeSql(qb.select('Machine'), [], (resultSet) => {
                const machines = machinesWithImage.map((data) => (
                    {data: JSON.stringify(data), id: data.id, categoryImage: data.categoryImage}
                ))
                const dbMachines = qb.getAllResult(resultSet);
                const deleteMachines = dbMachines
                    .filter((deleteMachine) => !_.some(machines, {data: deleteMachine.data}))
                    .map((data) =>  data.id);

                const addMachines = machines.filter(
                    (addMachine) => !_.some(dbMachines, {data: addMachine.data})
                );
                console.log('machines -> delete -> add', deleteMachines, addMachines)

                addMachines.forEach((machine) => {
                    allPromise.push(this.downloadMachineImages(machine));
                });

                Promise.all(allPromise).then(machines => {
                    resolve({machines, deleteMachines});
                });

            }, (e) => reject(e));
        })
    }

    public downloadMachineImages(machine: MachineTable) {
        return new Promise<MachineTable>((resolve) => {
            if (machine.categoryImage) {
                const fileTransfer = new FileTransfer();
                fileTransfer.downloadFileFromURL(machine.categoryImage, 'categoryImages')
                    .then((image) => resolve({...machine, categoryImage: image}));
            } else {
                resolve(machine);
            }
        });
    }

    public getAll() {
        if (this.SQLite) {
            return new Promise<MachineTable[]>((resolve, reject) => {
                const qb = new QueryBuilder();
                this.SQLite.executeSql(qb.select('Machine'), [], (resultSet) => resolve(qb.getAllResult(resultSet)), (e) => reject(e));
            });
        }
    }

    public machineDetails(id) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            // let checks: Machine['checks'] = [];
            // let lastDailyCheck: Machine['lastDailyCheck'];
            let issues: Machine['issues'] = [];
            let issuesStatusCount = {};
            let issuesCount: Machine['issuesCount'] = [];
            let excavatorData;
            let where = [{name: 'id', value: id}];
            let innerWhere = [{name: 'machineId', value: id}];
            let issuesOrderBy = [{name: 'id', value: 'DESC'}];
            return new Promise<Machine>((resolve) => {
                this.SQLite.transaction((SQLite) => {
                    SQLite.executeSql(qb.select('Machine', [], where), [], (SQLite, result) => {
                        excavatorData = qb.getSingleResult(result);
                    });

                    SQLite.executeSql(qb.select('Issue', [], innerWhere, issuesOrderBy), [], (SQLite, result) => {
                        if (result && result.rows.length > 0) {
                            for (let i = 0; i < result.rows.length; i++) {
                                let issueTable = result.rows.item(i);
                                let issue = JSON.parse(issueTable.data)
                                issues.push({...issue, id: issueTable.id});
                                if (Object.values(IssueTypes).includes(issue.type) && !(issue.status === IssueStatuses.COMPLETED || issue.status === IssueStatuses.CLOSED)) {
                                    issuesStatusCount[issue.type] = issuesStatusCount[issue.type] ? issuesStatusCount[issue.type] + 1 : 1;
                                }
                            }
                        }
                    });
                }, () => resolve(excavatorData), () => {
                    issuesCount = Object.keys(issuesStatusCount).reverse().map((status) => ({
                        type: parseInt(status),
                        count: issuesStatusCount[status]
                    }));
                    const carDetails = JSON.parse(excavatorData.data);
                    resolve({ ...carDetails, categoryImage: excavatorData.categoryImage, issues, issuesCount});
                });
            });
        }
    }

    public updateMachineDetail(machine: Machine) {
        /*if (this.SQLite && store.getState().machines.inFavorite(machine.id)) {
            const qb = new QueryBuilder();
            this.downloadMachineImages(machine).then((machine) => {
                this.SQLite.transaction((tx) => {
                    tx.executeSql(qb.delete('Machine', [{name: 'id', value: machine.id}]));
                    tx.executeSql(qb.delete('Checks', [{name: 'machineId', value: machine.id}]));
                    tx.executeSql(qb.delete('LastPeriodicCheck', [{name: 'machineId', value: machine.id}]));
                    tx.executeSql(qb.insert('Machine', [machine]));
                    tx.executeSql(qb.insert('Checks', machine.checks.map(checks => ({...checks, ...{machineId: machine.id}}))));
                    tx.executeSql(qb.insert('LastPeriodicCheck', [{...machine.lastDailyCheck, ...{machineId: machine.id}}]));
                });
            });
        }*/
    }

    public registerOperationTimeOrCount(machine: OperatingTimePayload) {
        if (this.SQLite) {
            const qb = new QueryBuilder();
            return new Promise((resolve, reject) => {
                this.SQLite.transaction((tx) => {

                    let where = [{name: 'id', value:  machine.machineId}];

                    tx.executeSql(qb.select('Machine', [], where), [], (SQLite, result) => {
                        const excavatorData = qb.getSingleResult(result);

                        if(excavatorData){
                            const excavatorDetails = JSON.parse(excavatorData.data);
                            if(machine.type === OperatingTypes.OPERATING_COUNT) {
                                excavatorDetails.operatingCount = machine.operatingTime;
                                excavatorDetails.lastOperatingCountDate = String(moment().format("YYYY-MM-DD"));
                            } else {
                                excavatorDetails.operatingTime = machine.operatingTime;
                                excavatorDetails.lastOperatingTimeDate = String(moment().format("YYYY-MM-DD"));
                            }

                            tx.executeSql(qb.update('Machine', {
                                data: JSON.stringify(excavatorDetails)
                            }, where));
                        }
                    });

                    //'OperatingTimeLog'
                    tx.executeSql(qb.insert('SyncOfflineData', [{
                        data: JSON.stringify({
                            machineId: machine.machineId,
                            operatingTime: machine.operatingTime,
                            type: machine.type,
                            userId: store.getState().user.data.id,
                            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
                        }),
                        userId: store.getState().user.data.id,
                        typeOfData: syncTypes.UPDATE_OPERATING_DATA,
                    }]));
                }, () => reject(), () => resolve());
            });
        }
    }

    public updateMachineDashboardDetail(machine: Machine) {
        if(this.SQLite && store.getState().machines.inFavorite(machine.id)) {
            const qb = new QueryBuilder();
            this.SQLite.transaction((tx) => {

                let where = [{name: 'id', value:  machine.id}];
                tx.executeSql(qb.select('Machine', [], where), [], (SQLite, result) => {
                    const excavatorData = qb.getSingleResult(result);
                    if(excavatorData){
                        const excavatorDetails = JSON.parse(excavatorData.data);
                        excavatorDetails.idleTimeHistoryData = machine.idleTimeHistoryData ? machine.idleTimeHistoryData : [];
                        excavatorDetails.fuelConsumptionHistoryData = machine.fuelConsumptionHistoryData ? machine.fuelConsumptionHistoryData : [];
                        excavatorDetails.inspectionData = machine.inspectionData ? machine.inspectionData : [];
                        tx.executeSql(qb.update('Machine', {
                            data: JSON.stringify(excavatorDetails)
                        }, where));
                    }
                });
            });
        }
    }
}
