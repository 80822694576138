import React, { useCallback } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

export type ListDefaultItem = { id: string | number, name: string }

export interface ListItemProps<T> {
  className?: string
  searchKey?: keyof T
  item: T,
  renderItem?: (item: T) => React.ReactNode
  onSelect?: (item: T) => void
}

const ListItem = <T extends ListDefaultItem = ListDefaultItem>(
  { className, searchKey = 'name', item, renderItem, onSelect }: ListItemProps<T>
) => {
  const itemClickHandler = useCallback(() => {
    if (onSelect) {
      onSelect(item);
    }
  }, [item, onSelect]);

  return (
    <div className={classNames(classes['list-item'], className)} onClick={itemClickHandler}>
      {renderItem ? renderItem(item) : item[searchKey]}
    </div>
  );
};

export default ListItem
