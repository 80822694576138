import React, {useEffect} from 'react';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';
import usePageTitle from "~/app/hooks/usePageTitle";
import {useAppHeader} from "~/app/modules/appHeader";
import {useHistory} from "react-router-dom";

export type AccessDeniedComponent = React.FC

const AccessDenied: AccessDeniedComponent = () => {
    const history = useHistory();
    const {t} = useTranslation('accessControl');

    usePageTitle(t('pageTitle'));

    const { setData } = useAppHeader();

    useEffect(() => {
        setData({
            title: '',
            subTitle: '',
            backLink: history.length > 1 ? history.goBack : undefined,
            showUser: true,
            machineId: '',
            projectId: '',
            parentFolderId: 0,
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: false
        });
    }, [setData, history]);

    return (
        <div className={classes['message']}>
            <Icon className={classes['icon']} name="terrain" size="60"/>
            <div className={classes['title']}>{t('accessDenied')}</div>
        </div>
    );
};

export default AccessDenied
