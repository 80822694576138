import React from 'react';
import classNames from 'classnames';

import useField from './useField';

import Field, { FieldProps } from './Field';

import classes from './style.module.scss';

export interface TextPropsDefault extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>
  inputClassName?: string
}

export type TextProps = TextPropsDefault & FieldProps

export type TextComponent = React.RefForwardingComponent<HTMLInputElement, TextProps>

const Text: TextComponent = (props, ref) => {
  const { fieldProps, inputProps: { inputClassName, ...othersInputProps } } = useField<TextPropsDefault>(props);

  return (
    <Field {...fieldProps}>
      <input
        {...othersInputProps}
        ref={ref}
        className={classNames(classes['input'], inputClassName)}
        type={othersInputProps.type || 'text'}
        disabled={fieldProps.loading || othersInputProps.disabled}
      />
    </Field>
  );
};

export default React.forwardRef(Text)
