import React from 'react';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';

export type EmptyMessageComponent = React.FC

const EmptyMessage: EmptyMessageComponent = () => {
    const {t} = useTranslation('issues');

    return (
        <div className={classes['message']}>
            <Icon className={classes['icon']} name="terrain" size="60"/>
            <div className={classes['title']}>{t('empty')}</div>
        </div>
    );
};

export default EmptyMessage
