import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

import { MSALBrowser } from './msalBrowser';
import { useStoreState } from "~/store";

export type BrowserProviderComponent = React.FC

const BrowserProvider: BrowserProviderComponent = ({ children }) => {
  const msalInstance = useStoreState(state => state.msalBrowser.msalInstance);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={MSALBrowser.authenticationRequest()}>
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
};

export default BrowserProvider
