import React, { useMemo } from 'react';
import classes from './style.module.scss';
import { MaterialIcons } from '../../../../../app/common/Icon/icons';
import Icon from '../../../../../app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';

export interface AssetDashboardAverageInfoProps {
    performanceData: number
    defaultColor?: string
    reverseColors?: boolean
}

export type AssetDashboardAverageInfoComponent = React.FC<AssetDashboardAverageInfoProps>;

const AssetDashboardAverageInfo = ({ performanceData, defaultColor, reverseColors = false }: AssetDashboardAverageInfoProps) => {
    const { t } = useTranslation("machine.dashboard");

    const text: string = useMemo(() => {
        return (performanceData < 0 ? t('aboveAverage', { averagePercentageBySubcategory: Math.abs(performanceData) }) : (performanceData > 0 ? t('belowAverage', { averagePercentageBySubcategory: Math.abs(performanceData) }) : t('onAverage'))); 
    }, [performanceData, t]);
    const icon: MaterialIcons = useMemo(() => {
        return (performanceData < 0 ? 'north_east' : (performanceData > 0 ? 'south_east' : 'east'));
    }, [performanceData]);
    const statusClass: string = useMemo(() => {
        if(defaultColor) {
            return defaultColor;
        }
        if(reverseColors) {
            return (performanceData > 0 ? 'above-average' : (performanceData < 0 ? 'below-average' : 'on-average'));
        } else {
            return (performanceData < 0 ? 'above-average' : (performanceData > 0 ? 'below-average' : 'on-average'));
        }
    }, [defaultColor, performanceData, reverseColors]);

    return (
        <div className={`${classes['additional-info']} ${classes[statusClass]}`}>
            <Icon className={classes['icon']} name={icon} />{text}
        </div>
    )
}

export default AssetDashboardAverageInfo;