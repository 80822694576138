import React, { useCallback, useState } from 'react';

import useField from '../useField';
import useDefaultValue from '../useDefaultValue';

import Field, { FieldProps } from '../Field';
import Checkbox, { CheckboxProps } from '../Checkbox';

import classes from './style.module.scss';

export interface CheckboxGroupPropsDefault extends Omit<CheckboxProps, 'defaultValue' | 'onChange'> {
  options: CheckboxProps[],
  defaultValue?: CheckboxProps['value'][]
  onChange?: CheckboxProps['onChange']
}

export type CheckboxGroupProps = CheckboxGroupPropsDefault & FieldProps

export type CheckboxGroupComponent = React.FC<CheckboxGroupProps>

const CheckboxGroup: CheckboxGroupComponent = props => {
  const {
    fieldProps,
    inputProps: {
      options,
      defaultValue: defaultValueValue,
      onChange,
      ...checkboxProps
    }
  } = useField<CheckboxGroupPropsDefault>(props);

  const defaultValue = useDefaultValue(defaultValueValue || []);
  
  const [values, setValues] = useState<CheckboxProps['value'][]>(defaultValue);

  const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();

    if (e.target.checked) {
      values.push(e.target.value)
    } else {
      const index = values.findIndex(val => val === e.target.value);

      if (index !== -1) {
        values.splice(index, 1);

        setValues(values);
      }
    }

    if (onChange) {
      // @ts-ignore
      onChange({ ...e, target: { ...e.target, name: e.target.name, value: values } });
    }
  }, [onChange, values]);

  return (
    <Field {...fieldProps}>
      <div className={classes['group']}>
        {options.map(option => (
          <Checkbox
            key={(option.value as string)}
            {...checkboxProps}
            {...option}
            isGroupItem
            inputClassName={classes['input']}
            placeholder={option.label}
            defaultChecked={values.includes(option.value)}
            onChange={inputChangeHandler}
          />
        ))}
      </div>
    </Field>
  );
};

export default CheckboxGroup
