import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classes from './style.module.scss';
import { useStoreActions, useStoreState } from '~/store';
import moment from 'moment';
import useTranslation from '~/app/hooks/useTranslation';
import Transition from '~/app/common/Transition';

export interface ReportCardProps {
    title: string
    subtitle?: string
    dateOfLastAvailableData: string
    mainGraphTitle: string
    mainGraph: ReactNode
    rightSideInfo: ReactNode
    trendGraph?: ReactNode
}

export type ReportCardComponent = React.FC<ReportCardProps>;

const ReportCard: ReportCardComponent = ({ title, subtitle, dateOfLastAvailableData, mainGraphTitle, mainGraph, rightSideInfo, trendGraph }) => {
    const { t } = useTranslation("machine.dashboard");
    const dataNotAvailableSinceDate = useStoreState(state => state.machine.dataNotAvailableSinceDate);
    const setDataNotAvailableSinceDate = useStoreActions(actions => actions.machine.setDataNotAvailableSinceDate);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (!dataNotAvailableSinceDate && moment(dateOfLastAvailableData, 'YYYY-MM-DD').startOf('day').isBefore(moment().subtract(1, 'days').startOf('day'))) {
            setDataNotAvailableSinceDate(moment(dateOfLastAvailableData));
        }
    }, [dateOfLastAvailableData, dataNotAvailableSinceDate, setDataNotAvailableSinceDate]);

    const menuClickHandler = useCallback((e) => {
        e.preventDefault();
        setExpanded(!expanded);
    }, [setExpanded, expanded]);

    const nodeRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className={classes['report-card']}>
                <div className={classes['header']}>
                    <div className={classes['sub-header']}>
                        <span className={classes['card-title']}>{title}</span>
                        {subtitle && <span className={classes['target-value']}>{subtitle}</span>}
                    </div>
                    {trendGraph && <div className={`${classes['view-trend']}`} onClick={menuClickHandler}>
                        <span>{t(!expanded ? 'seeTrend' : 'hideTrend')}</span>
                    </div>}
                </div>
                <div className={classes['main-chart-container']}>
                    <div className={classes['chart-container']}>
                        <div className={classes['main-data']}>
                            <div className={classes['chart-heading']}>{mainGraphTitle}</div>
                            {mainGraph}
                        </div>
                        <div className={classes['secondary-data']}>
                            {rightSideInfo}
                        </div>
                    </div>

                    {!!trendGraph && <div className={classes['trend-chart-container']}>
                        <Transition
                            in={expanded} 
                            timeout={500}
                            nodeRef={nodeRef}
                            mountOnEnter={false}
                            unmountOnExit={true}
                            classNames={{
                                enter: classes['trend-chart-wrapper-enter'],
                                enterActive: classes['trend-chart-wrapper-enter-active'],
                                enterDone: classes['trend-chart-wrapper-enter-done'],
                                exit: classes['trend-chart-wrapper-exit'],
                                exitActive: classes['trend-chart-wrapper-exit-active'],
                                exitDone: classes['trend-chart-wrapper-exit-done'],
                            }}>
                            <div ref={nodeRef}>
                                <div className={classes['trend-chart-inner-container']}>
                                    <span className={classes['card-title']}>{t('previous5WorkingDays')}</span>
                                    <div className={classes['main-data']}>
                                        {trendGraph}
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ReportCard