import React, { useCallback } from 'react';
import classNames from 'classnames';

import Field from './Field';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import Counter from './Counter';
import Date from './Date';
import Search from './Search';
import Select from './Select';
import Text from './Text';
import Textarea from './Textarea';

import classes from './style.module.scss';

export interface FormProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  className?: string
}

export interface FormComponent extends React.FC<FormProps> {
  Field: typeof Field
  Checkbox: typeof Checkbox
  CheckboxGroup: typeof CheckboxGroup
  Counter: typeof Counter
  Date: typeof Date
  Search: typeof Search
  Select: typeof Select
  Text: typeof Text
  Textarea: typeof Textarea
}

const Form: FormComponent = ({ className, children, onSubmit, ...props }) => {
  const formSubmitHandler = useCallback((e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit(e);
    }
  }, [onSubmit]);

  return (
    <form className={classNames(classes['form'], className)} {...props} onSubmit={formSubmitHandler}>
      {children}
    </form>
  );
};

Form.Field = Field;
Form.Checkbox = Checkbox;
Form.CheckboxGroup = CheckboxGroup;
Form.Counter = Counter;
Form.Date = Date;
Form.Search = Search;
Form.Select = Select;
Form.Text = Text;
Form.Textarea = Textarea;

export default Form
