import React, {useCallback} from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import {useStoreActions, useStoreState} from '~/store';
import classes from "./style.module.scss";
import {DocumentationTabs, Equipment} from "~/types";
import {useParams} from "react-router-dom";

export type HeaderComponent = React.FC

const Header: HeaderComponent = () => {
    const {equipmentId} = useParams<{ equipmentId: Equipment['id'] }>();

    const {t} = useTranslation("documentation");
    const tabs = useStoreState(state => state.documentation.tabs);
    const activeTab = useStoreState(state => state.documentation.activeTab);
    const setActiveTab = useStoreActions(actions => actions.documentation.setActiveTab);
    // const netWorkStatus = useStoreState(state => state.app.offline.status);

    const clickHandler = useCallback((tab: DocumentationTabs['activeTab']) => {
        setActiveTab(tab)
    }, [setActiveTab]);

    return (
        <div className={classes['header']}>
            {tabs.map((tab, index) =>
                (!equipmentId || tab !== 'equipments') &&
                /*(!netWorkStatus || tab !== 'archives') &&*/
                <div key={index} className={`${classes['link']} ${tab === activeTab && classes['active']}`}
                     onClick={() => clickHandler(tab)}>{t(`tabs.${tab}`)}</div>)}
        </div>
    );
};

export default Header

