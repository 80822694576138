import React, {useCallback, useState} from 'react';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';
import Footer from './Footer';
import Icon from "~/app/common/Icon";
import {useStoreState} from "~/store";

export interface FormProps {
    onSelect: () => void
}

export type FormComponent = React.FC<FormProps>
const Form: FormComponent = ({onSelect}) => {
    const {t} = useTranslation('periodicCheck.quantity');
    const currentCheck = useStoreState(state => state.periodicCheck.currentCheck);
    const [quantity, setQuantity] = useState<number>(currentCheck ? currentCheck['quantity'] : 0);

    const increaseQuantity = useCallback(() => {
        setQuantity(quantity + 0.5)
    }, [quantity, setQuantity]);

    const decreaseQuantity = useCallback(() => {
        setQuantity(quantity > 0 ? (quantity - 0.5) : 0)
    }, [quantity, setQuantity]);

    return (
        <div className={`${classes['model-body']}`}>
            <label className={`${classes['form-label']}`}>{currentCheck && currentCheck.name}</label>
            <div className={classes['quantity']}>
                <div className={classes['value']}>
                    {quantity.toFixed(1)}
                    <span className={classes['quantity-type']}>{t('type')}</span>
                </div>
                <div className={classes['buttons']}>
                    <Icon name={'add'} onClick={increaseQuantity}/>
                    <Icon name={'remove'} onClick={decreaseQuantity}/>
                </div>
            </div>
            <Footer onSelect={onSelect} quantity={quantity}/>
        </div>
    );
};

export default Form