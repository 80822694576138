import React, {useCallback} from 'react';
import classes from './style.module.scss';
import useTranslation from '~/app/hooks/useTranslation';
import {IssuePayload} from "~/types";
import useForm from "~/app/modules/form/useForm";

export interface CategoryProps {
    setShowDetails: (boolean) => void
}

export type CategoryComponent = React.FC<CategoryProps>;

const Category: CategoryComponent = ({setShowDetails}) => {
    const {t} = useTranslation('issue');
    const {values} = useForm<IssuePayload>({formName: 'addIssue'});

    const previousClickHandler = useCallback((): void => {
        setShowDetails(false);
    }, [setShowDetails]);

    return (
        <div className={classes['issue-category']}>
            <div className={classes['left']}>
                <div className={classes['gray-title']}>{t('issueCategories')}</div>
                <div className={classes['text']}>{values.categoryName}</div>
            </div>
            <div className={classes['right']}>
                <span className={classes['link']} onClick={previousClickHandler}>{t('change')}</span>
            </div>
        </div>
    );
};

export default Category
