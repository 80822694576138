import React from 'react';
import classNames from 'classnames';

import Icon, { IconProps } from '~/app/common/Icon';
import Loader from '~/app/common/Loader';

import { FieldError } from './store';

import classes from './style.module.scss';

export interface FieldProps {
  className?: string
  label?: React.ReactNode
  loading?: boolean
  fetching?: boolean
  icon?: IconProps['name'] | React.ReactElement
  iconPosition?: 'left' | 'right'
  iconSize?: IconProps['size']
  description?: React.ReactNode
  descriptionPosition?: 'top' | 'bottom'
  errors?: FieldError[]
}

export type FieldComponent = React.FC<FieldProps>

const Field: FieldComponent = (
  {
    className,
    label,
    loading,
    fetching,
    icon,
    iconPosition,
    iconSize = 24,
    description,
    descriptionPosition = 'bottom',
    errors,
    children
  }
) => {
  if (icon && !iconPosition) {
    iconPosition = 'right';
  }
  return (
    <div
      className={classNames(classes['field'], {
        [classes['has-errors']]: errors && errors.length
      }, className)}
    >
      {!!label && <label className={classes['label']}>{label}</label>}

      {!!description && descriptionPosition === 'top' && (
        <div className={`${classes['description']} ${classes['before-input']}`}>{description}</div>
      )}

      <div
        className={classNames(classes['input-wrap'], {
          [classes['icon-left']]: iconPosition === 'left',
          [classes['icon-right']]: iconPosition === 'right'
        })}
      >
        {children}
        {(!!icon || loading || fetching) && (
          <div
            className={classNames(classes['icon-wrap'], {
              [classes['icon-element']]: typeof icon !== 'string'
            })}
          >
            {loading || fetching ? <Loader /> : (typeof icon === 'string' ? <Icon name={icon} size={iconSize} /> : icon)}
          </div>
        )}
      </div>

      {!!description && descriptionPosition === 'bottom' && <div className={classes['description']}>{description}</div>}

      {/*{!!errors && (
        <div
          className={classes['errors']}
          dangerouslySetInnerHTML={{ __html: (Array.isArray(errors) ? errors.join('<span>&bull;</span>') : errors) }}
        />
      )}*/}
    </div>
  );
};

export default Field
