export enum MachineTabs {
    DASHBOARD = 'dashboard',
    REGISTRATION = 'registration',
    EMPTYTAB = ''
}

export enum MachineTypes {
    MACHINE = 'machine',
    VEHICLE = 'vehicle',
    EQUIPMENT = 'equipment'
}

export enum FuelConsumptionParams {
    BARSIZE = 2200,
    MAXRANGE = 2,
    MINRANGE = 0
}

export enum IdleHoursParams {
    IDLEHOURSAVERAGELINE = 1
}

export enum InspectionRateParams {
    BARSIZE = 8
}

export enum MaxFavoriteAssets {
    LIMIT = 15
}