import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { FieldProps } from './Field';

type UseFieldProps<T> = FieldProps & T

export interface UseFieldData<T> {
  fieldProps: FieldProps
  inputProps: T
}

export interface UseField {
  <T>(defaultProps: UseFieldProps<T>): UseFieldData<T>
}

const useField: UseField = <T>(defaultProps) => {
  const [props, setProps] = useState<UseFieldProps<T>>(defaultProps);

  const data = useMemo<UseFieldData<T>>(() => {
    const {
      className,
      label,
      loading,
      fetching,
      icon,
      iconPosition,
      iconSize,
      description,
      descriptionPosition,
      errors,
      ...inputProps
    } = props;

    return  {
      fieldProps: {
        className,
          label,
          loading,
          fetching,
          icon,
          iconPosition,
          iconSize,
          description,
          descriptionPosition,
          errors
      },
      inputProps: inputProps as T
    }
  }, [props]);

  useEffect(() => {
    if (!isEqual(defaultProps, props)) {
      setProps(defaultProps);
    }
  }, [defaultProps, props]);
  
  return data
};

export default useField
