import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { required } from '~/app/helpers/validation';

import Loader from '~/app/common/Loader';

import useForm, { UseFormOptionFields } from '~/app/modules/form/useForm';

import { IssueDetails, IssuePayload, Machine, Project } from '~/types';

import { useStoreState } from '~/store';

import { AddIssueProps } from '../';

import Footer from './Footer';
import Categories from './Categories';
import Details from './Details';

import classes from './style.module.scss';
import { IssueTypeRepaired, IssueTypes } from '~/app/data/statuses/issue';
import useDidMountEffect from '~/app/hooks/useDidMountEffect';

export type FormProps = {
  type: AddIssueProps['type']
  inspectionType?: AddIssueProps['inspectionType']
  onClose(): void
  issueDetails?: IssueDetails
  onSave?: (id: IssueDetails['id']) => void
}

export type FormComponent = React.FC<FormProps>

const Form: FormComponent = ({ onClose, type, inspectionType, issueDetails, onSave }) => {
  const [showDetails, setShowDetails] = useState<boolean>(issueDetails?.id ? true : false);

  const { id, projectId } = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
  const currentUserId = useStoreState(state => state.user.data.id);
  
  const formFields = useMemo<UseFormOptionFields>(() => ([
    'issueType',
    'machineId',
    'projectId',
    ['categoryId', { validators: [['required', required]] }],
    'categoryName',
    ['description', { validators: [['required', required]] }],
    'images',
    type === 'issue' ? ['departmentId', { validators: [['required', required]] }] : 'departmentId',
    type === 'repair' ? ['performedBy', { validators: [['required', required]] }] : 'performedBy',
    ['type', { validators: [['required', required]] }],
    ...inspectionType !== undefined ? ['inspectionType'] : []
  ]), [type, inspectionType]);

  const { values, defaultValues, removeForm } = useForm<IssuePayload>({
    formName: 'addIssue',
    fields: formFields,
    defaultValues: {
      issueType: type,
      machineId: issueDetails?.machineId ? Number(issueDetails?.machineId) : id,
      projectId: issueDetails?.projectId ? Number(issueDetails?.projectId) : projectId,
      departmentId: issueDetails?.issueDepartmentId ? Number(issueDetails?.issueDepartmentId) : 0,
      performedBy: type === 'repair' && currentUserId ? currentUserId : 0,
      categoryId: issueDetails?.categoryId ? Number(issueDetails?.categoryId) : 0,
      categoryName: issueDetails?.categoryName ?? undefined,
      type: type === 'repair' ? IssueTypeRepaired : (issueDetails?.type ?? IssueTypes.MINOR),
      ...inspectionType !== undefined && { inspectionType: inspectionType },
      images: issueDetails?.images ?? [],
      description: issueDetails?.description ?? undefined
    }
  });

  useDidMountEffect(() => {
    return () => {
      removeForm();
    }
  });

  if (!values) {
    return <Loader size="32"/>;
  }

  return (
    <>
      <div className={classes['container']}>
        {showDetails ? <Details setShowDetails={setShowDetails} type={type} issueDetails={issueDetails} /> : <Categories />}
      </div>

      <Footer onClose={onClose} showDetails={showDetails} setShowDetails={setShowDetails} type={type} issueDetails={issueDetails} defaultFormValues={defaultValues} onSave={onSave} />
    </>
  );
};

export default Form
