import React from 'react';
import {PeriodicCheckPayload} from "types/index";

import ListItem, { ListItemProps } from './ListItem';

import classes from './style.module.scss';

export interface ListProps extends Pick<ListItemProps, 'onSelect'> {
    list: PeriodicCheckPayload[]
}

export type ListComponent = React.FC<ListProps>

const List: ListComponent = ({ list, onSelect }) => (
    <div className={classes['list']}>
        {list.map((item, index) => <ListItem key={index} item={item} onSelect={onSelect} />)}
    </div>
);

export default List
