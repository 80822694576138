import React, {useCallback} from 'react';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import classes from './style.module.scss';
import {useStoreState, useStoreActions} from "~/store";
import Button from "~/app/common/Button";

export type MsalErrorComponent = React.FC

const MsalError: MsalErrorComponent = () => {
    const {t} = useTranslation('msalAuth.error');
    const tenantError = useStoreState(state => state.tenant.tenantError);
    const logout = useStoreActions(actions => actions.user.logout);

    const onLogoutClick = useCallback(() => {
        logout({microsoftLogout: true});
    }, [logout]);

    return (
        <div className={classes['message']}>
            <Icon className={classes['icon']} name="terrain" size="60"/>
            <div className={classes['title']}>{tenantError && tenantError.message}</div>
            <Button color="primary" onClick={onLogoutClick}>{t('logoutButton')}</Button>
        </div>
    );
};

export default MsalError
