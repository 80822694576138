import React, {useCallback, useEffect} from 'react';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import {isCordova} from "~/cordova";

export interface ExternalLinkProps {
    onClose: (boolean) => void
    open: boolean
    url: string
}

export type ExternalLinkComponent = React.FC<ExternalLinkProps>

const ExternalLink: ExternalLinkComponent = ({onClose, open, url}) => {
    const cancelClickHandler = useCallback((): void => {
        onClose(false);
    }, [onClose]);

    useEffect(() => {
        if (isCordova && open) {
            let inAppBrowserRef = window['cordova'].InAppBrowser.open(url, "_blank", "location=no");
            inAppBrowserRef.addEventListener('exit', cancelClickHandler);
        }
    }, [open, url, cancelClickHandler]);

    if (isCordova) {
        return <></>;
    }

    return (
        <Modal open={open} position={'top'} className={classes['content']}>
            <Icon name={'close'} className={classes['abort']} onClick={cancelClickHandler}/>
            <iframe title="content-window" src={url} className={classes['content-window']}></iframe>
        </Modal>
    );
};

export default ExternalLink
