import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import RouterSuspense from '~/app/common/RouterSuspense';
import { Route, useHistory, useParams } from "react-router";
import Details from '.';
import PrivateRoute from '~/app/PrivateRoute';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import PeriodicCheck from '~/periodicCheck';
import NestService from './Registration/NestService/NestService';
import Issues from '~/issues/List';
import Documentation from '~/documentation';
import IssueDetails from "~/issues/Details";
import { useStoreActions, useStoreState } from '~/store';
import { Machine, Project } from '~/types';
import { MACHINES_ROUTES } from '~/machines/Router';
import { ROUTES } from '~/app/Router';
import { route } from '~/app/helpers/route';
import usePageTitle from '~/app/hooks/usePageTitle';
import { MachineTabs } from '~/app/data/statuses/machine';

export const MACHINES_DETAILS_ROUTES = {
    details: '/projects/:projectId/machines/:id',
    periodicCheck: '/projects/:projectId/machines/:id/periodic-check',
    nextService: '/projects/:projectId/machines/:id/next-service',
    documentation: '/projects/:projectId/machines/:id/documentation/:equipmentId?',
    issues: '/machines/:id/issues',
    openCriticalIssues: '/machines/:id/open-critical-issues',
    issueDetails: '/machines/:id/issues/:issueId'
};

export type RouterComponent = React.FC

const Router: RouterComponent = () => {
    const history = useHistory();
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
    const machine = useStoreState(state => state.machine.data);
    const updateLastUsedAction = useStoreActions(actions => actions.machines.updateLastUsed);
    const MachineFetchAction = useStoreActions(actions => actions.machine.fetchMachine);
    const OfflineMachineFetchAction = useStoreActions(actions => actions.machine.fetchOfflineMachine);
    const MachineDashboardFetchAction = useStoreActions(actions => actions.machine.fetchMachineDashboard);
    const OfflineMachineDashbaordFetchAction = useStoreActions(actions => actions.machine.fetchOfflineMachineDashboard);
    const inFavorite = useStoreState(state => state.machines.inFavorite(id));
    const activeTab = useStoreState(state => state.machine.activeTab);

    usePageTitle((machine.name || '').toUpperCase());

    useEffect(() => {
        if ((netWorkStatus === 0 || inFavorite) && id) {
            (netWorkStatus ? (activeTab === MachineTabs.DASHBOARD ? OfflineMachineDashbaordFetchAction : OfflineMachineFetchAction) : (activeTab === MachineTabs.DASHBOARD ? MachineDashboardFetchAction : MachineFetchAction))(id).then(() => {
                netWorkStatus === 0 && updateLastUsedAction({ machineId: id });
            });
        } else {
            history.push(projectId ? route(MACHINES_ROUTES.machines, { projectId }) : route(ROUTES.projects));
        }
    }, [MachineFetchAction, id, projectId, netWorkStatus, OfflineMachineFetchAction, updateLastUsedAction, inFavorite, history, MachineDashboardFetchAction, activeTab, OfflineMachineDashbaordFetchAction]);

    return (
        <RouterSuspense>
            <Switch>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.periodicCheck} component={PeriodicCheck} requiredPermissions={PERMISSION_GROUPS.readWritePeriodicCheck}/>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.nextService} component={NestService} requiredPermissions={PERMISSION_GROUPS.readWritePeriodicCheck}/>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.documentation} component={Documentation} requiredPermissions={PERMISSION_GROUPS.readMachineDocuments}/>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.issueDetails} component={IssueDetails} requiredPermissions={PERMISSION_GROUPS.readIssues}/>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.issues} component={Issues} requiredPermissions={PERMISSION_GROUPS.readIssues}/>
                <PrivateRoute path={MACHINES_DETAILS_ROUTES.openCriticalIssues} component={Issues} requiredPermissions={PERMISSION_GROUPS.readIssues}/>
                <Route path={MACHINES_DETAILS_ROUTES.details} component={Details} />
            </Switch>
        </RouterSuspense>
    )
};

export default Router;
