import React, { useMemo } from 'react';
import { inspectionData } from '~/types';
import useTranslation from '~/app/hooks/useTranslation';
import InspectionGraph from '../InspectionGraph';
import ReportCard from '../../ReportCard';
import ReportCardRightSideInfo from '../../common/ReportCardRightSideInfo';

export interface InspectionReportCardProps {
    inspectionData: inspectionData
}

export type InspectionReportCardComponent = React.FC<InspectionReportCardProps>;

const InspectionReportCard: InspectionReportCardComponent = ({ inspectionData }) => {
    const { t } = useTranslation("machine.dashboard");

    const firstSectionData = useMemo(() => {
        return {
            title: t('last6MonthsAverage'),
            data:`${inspectionData.last6MonthsPeriodicChecks}%`,
            performanceData: inspectionData.last6MonthsPerformanceCompareToAverage
        }
    }, [inspectionData.last6MonthsPerformanceCompareToAverage, inspectionData.last6MonthsPeriodicChecks, t]);

    const secondSectionData = useMemo(() => {
        return {
            title: t('last12MonthsAverage'),
            data: `${inspectionData.last12MonthsPeriodicChecks}%`,
            performanceData: inspectionData.last12MonthsPerformanceCompareToAverage
        }
    }, [inspectionData.last12MonthsPerformanceCompareToAverage, inspectionData.last12MonthsPeriodicChecks, t]);

    return (
        <ReportCard
            title={t(`inspectionRate.title`)}
            subtitle={t(`onTarget`, { targetPercentage: inspectionData.TargerPercentage })}
            dateOfLastAvailableData={inspectionData.previousWorkingDay}
            rightSideInfo={<ReportCardRightSideInfo
                firstSection={firstSectionData}
                secondSection={secondSectionData}
                reverseColors={true}
            />}
            mainGraphTitle={t('last10Weeks')}
            mainGraph={<InspectionGraph inspectionData={inspectionData} />}
        />
    )
}

export default InspectionReportCard