import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import Icon from '~/app/common/Icon';

import useField from '../useField';
import useDefaultValue from '../useDefaultValue';

import Field, { FieldProps } from '../Field';

import mainClasses from '../style.module.scss';
import classes from './style.module.scss';

export interface CheckboxPropsDefault extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'placeholder'> {
  isGroupItem?: boolean
  inputClassName?: string
  placeholder?: React.ReactNode
}

export type CheckboxProps = CheckboxPropsDefault & FieldProps

export type CheckboxComponent = React.FC<CheckboxProps>

const Checkbox: CheckboxComponent = props => {
  const {
    fieldProps,
    inputProps: {
      isGroupItem,
      inputClassName,
      placeholder,
      defaultChecked: defaultCheckedValue,
      onChange,
      ...othersInputProps
    }
  } = useField<CheckboxPropsDefault>(props);

  const defaultChecked = useDefaultValue(defaultCheckedValue);

  const [checked, setChecked] = useState<boolean>(defaultChecked);

  const inputChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.checked);
    
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);
  
  const item = useMemo(() => (
    <label className={classNames(`${mainClasses['input']} ${classes['input']}`, inputClassName)}>
      {(!!placeholder) && <div className={classes['placeholder']}>{placeholder}</div>}

      <input
        {...othersInputProps}
        className={classes['checkbox-input']}
        type="checkbox"
        disabled={fieldProps.loading || othersInputProps.disabled}
        onChange={inputChangeHandler}
      />

      <Icon className={classes['icon']} name={checked ? 'check_box' : 'check_box_outline_blank'} />
    </label>
  ), [checked, fieldProps.loading, inputChangeHandler, inputClassName, othersInputProps, placeholder]);

  if (isGroupItem) {
    return item;
  }

  return (
    <Field {...fieldProps}>
      {item}
    </Field>
  );
};

export default Checkbox
