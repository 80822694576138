import React from 'react';
import Header from './Header';
import Form from './Form';
import Modal from '~/app/common/Modal';
import classes from './style.module.scss';
import AccessControl from "~/accessControl";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import { OperatingUnitTypePayload } from '~/types';
import { OperatingTypes } from '~/app/data/statuses/operatingData';
export interface RegisterProps {
    onClose: () => void
    open: boolean
    operatingUnitType: OperatingUnitTypePayload
}

export type RegisterOperatingTimeComponent = React.FC<RegisterProps>
const RegisterOperatingTime: RegisterOperatingTimeComponent = ({onClose, open, operatingUnitType}) => {
    return (
        <AccessControl requiredPermissions={operatingUnitType === OperatingTypes.OPERATING_COUNT ? PERMISSION_GROUPS.readWriteOperatingCount : PERMISSION_GROUPS.readWriteOperatingTime}>
            <Modal open={open} position={'center'} className={classes['register-operating-data']}>
                <Header operatingUnitType={operatingUnitType} />
                <Form onSelect={onClose} operatingUnitType={operatingUnitType} />
            </Modal>
        </AccessControl>
    );
};

export default RegisterOperatingTime
