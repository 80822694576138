import React from 'react';
import Icon from '~/app/common/Icon';
import { legendData } from '~/types';
import classes from './style.module.scss';

export type RenderLegendComponent = React.FC;

const RenderLegend = (props) => {

    return (
        <div className={classes['legend-wrapper']}>
            {props?.payload.map((entry: legendData, index: legendData['id']) => (
                <div className={classes['legends']} key={`item-${index}`}>
                    <Icon className={`${classes['icon']} ${entry.id === 'legend01' ? classes['current-unit'] : classes['average-unit']}`} size={12} name={entry.icon} />
                    <span className={classes['legend-text']}>
                        {entry.value}
                    </span>
                </div>
            ))}
        </div>
    )
};

export default RenderLegend