import React from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import useTranslation from '~/app/hooks/useTranslation';
import { OperatingTypes } from '~/app/data/statuses/operatingData';
import { OperatingUnitTypePayload } from '~/types';

export interface ValidationMessageProps {
    status: string,
    warning?: { type: string, difference: number }
    operatingUnitType: OperatingUnitTypePayload
}

export type ValidationMessageComponent = React.FC<ValidationMessageProps>;

const ValidationMessage: ValidationMessageComponent = ({status, warning, operatingUnitType}) => {
    const {t} = useTranslation(`${operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'operatingCount' : 'operatingTime'}.error`);

    if (status !== 'warning' || !warning) {
        return null;
    }
    return (
        <div className={`${classes['error-message']}`}>
            <Icon name="error_outline" className={classes['icon']} size={20}/>
            <div className={classes['label']}>{t(warning.type, {difference: warning.difference})}</div>
        </div>
    );
};

export default ValidationMessage
