import React from 'react';
import ListItem from './ListItem';
import { Issue } from '~/types';

export type ListProps = {
  list: Issue[],
  listClassName?: string
}

export type ListComponent = React.VFC<ListProps>

const List: ListComponent = ({ list, listClassName }) => (
  <>
    {list.map(item => <ListItem key={item.id} item={item} listClassName={listClassName} />)}
  </>
);

export default List
