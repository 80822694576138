import React from 'react';
import {IssueDetails} from "~/types";
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import List from "./List";

export interface WorkOrderProps {
    status: IssueDetails['status']
    workOrderNo: IssueDetails['workOrderNo']
    workDetails: IssueDetails['workDetails']
}

export type WorkOrderComponent = React.FC<WorkOrderProps>

const WorkOrder: WorkOrderComponent = ({status, workOrderNo, workDetails}) => {
    const {t} = useTranslation("issue.workorder");

    return (
        <div className={classes['work-info']}>
            <div className={classes['order-number']}>
                <span className={classes['title']}>{t('completedTitle')}</span>
                <span className={classes['number']}>{workOrderNo}</span>
            </div>
            {workDetails.map((details, index) => <List status={status} workDetails={details} key={index}/>)}
            <hr className={classes['bottom-line']}/>
        </div>
    );
};

export default WorkOrder
