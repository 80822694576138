import React, {useCallback} from 'react';
import {useSwipeable} from 'react-swipeable'
import classNames from 'classnames';
import {useStoreActions, useStoreState} from '~/store';
import classes from './style.module.scss';
import Menu from './Menu';
import useTranslation from "~/app/hooks/useTranslation";
import {isCordova} from "~/cordova";

export type SidebarComponent = React.FC

const Sidebar: SidebarComponent = () => {
    const isOpen = useStoreState(state => state.app.sidebar.isOpen);
    const setIsOpen = useStoreActions(actions => actions.app.sidebar.setOpen);
    const { t } = useTranslation("mainNav");

    const handlers = useSwipeable({
        onSwipedLeft: () => setIsOpen(false),
    });

    const closeMenu = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <div className={classNames(classes['main-sidebar'], {[classes['open']]: isOpen})} {...handlers}>
                <div className={classes['menu-title']}>
                    <span>{t('menuTitle')}</span>
                    {isCordova && <b>Versjon {window['BuildInfo']?.version} ({window['BuildInfo']?.versionCode})</b>}
                </div>
                <Menu/>
            </div>

            <div className={classNames(classes['overlay'], {[classes['show']]: isOpen})} onClick={closeMenu}/>
        </>

    )
};

export default Sidebar
