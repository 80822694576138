import React from 'react';

export interface FillPatternProps {
    id: string
    pattern: {
        width: number
        height: number
    }
    rect: {
        width: number
        height: number
    }
    fill: string
    patternTransform: string
}

export type FillPatternComponent = React.FC<FillPatternProps>;

const FillPattern = ({ id, pattern, rect, fill, patternTransform }) => {
    return (
        <pattern id={id} width={pattern.width} height={pattern.height} patternUnits="userSpaceOnUse" patternTransform={patternTransform}>
            <rect width={rect.width} height={rect.height} fill={fill} />
        </pattern>
    )
};

export default FillPattern;