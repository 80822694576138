import React, {useCallback} from 'react';
import classes from './style.module.scss';
import Button from '~/app/common/Button';
import useTranslation from '~/app/hooks/useTranslation';
import {useStoreActions, useStoreState} from "~/store";

export interface FooterProps {
    onSelect: () => void
    quantity: number
}

export type FooterComponent = React.FC<FooterProps>;

const Footer: FooterComponent = ({onSelect, quantity}) => {
    const {t} = useTranslation('periodicCheck.quantity');
    const updateCheckData = useStoreActions(actions => actions.periodicCheck.updateCheckData);
    const currentCheck = useStoreState(state => state.periodicCheck.currentCheck);

    const updateQuantity = useCallback(() => {
        if (currentCheck) {
            currentCheck.quantity = quantity;
            currentCheck.status = 1;
            updateCheckData(currentCheck);
        }
        onSelect();
    }, [onSelect, currentCheck, updateCheckData, quantity]);

    const abort = useCallback(() => {
        onSelect();
    }, [onSelect]);

    return (
        <div className={`${classes['footer']}`}>
            <Button
                className={classes['abort']}
                onClick={abort}>{t('cancel')}</Button>
            <Button
                className={classes['register']}
                onClick={updateQuantity}>{t(quantity ? 'register' : 'notUpdated')}</Button>
        </div>
    );
};

export default Footer
