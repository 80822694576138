import { createStore, createTypedHooks } from 'easy-peasy';

import { AppModel, appModel } from '~/app/store';
import { TenantModel, tenantModel } from '~/tenantProvider/store';
import { FormModel, formModel } from '~/app/modules/form/store';
import { UserModel, userModel } from '~/user/store';
import { ProjectsModel, projectsModel } from '~/projects/store/index';
import { ProjectModel, projectModel } from '~/projects/store/project';
import { MachinesModel, machinesModel } from '~/machines/store/index';
import { MachineModel, machineModel } from '~/machines/store/machine';
import { IssueModel, issueModel } from '~/issues/store';
import { PeriodicCheckModel, periodicCheckModel } from '~/periodicCheck/store';
import { DocumentationModel, documentationModel } from '~/documentation/store';
import { MsalBrowserModel, msalBrowserModel } from '~/auth/store/msalBrowser';
import { NotificationModel, notificationModel } from '~/notifications/store';

export interface StoreModel {
  app: AppModel
  tenant: TenantModel
  form: FormModel
  user: UserModel
  projects: ProjectsModel
  project: ProjectModel
  machines: MachinesModel
  machine: MachineModel
  issue: IssueModel
  periodicCheck: PeriodicCheckModel
  documentation: DocumentationModel
  msalBrowser: MsalBrowserModel
  notification: NotificationModel
}

const storeModel: StoreModel = {
  app: appModel,
  tenant: tenantModel,
  form: formModel,
  user: userModel,
  projects: projectsModel,
  project: projectModel,
  machines: machinesModel,
  machine: machineModel,
  issue: issueModel,
  periodicCheck: periodicCheckModel,
  documentation: documentationModel,
  msalBrowser: msalBrowserModel,
  notification: notificationModel
};

const typedHooks = createTypedHooks<StoreModel>();

export const { useStoreState } = typedHooks;
export const { useStoreActions } = typedHooks;

export default createStore<StoreModel>(storeModel, {
  name: 'TRANSPORT'
});
