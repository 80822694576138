import React from 'react';
import classNames from 'classnames';
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";

export interface StatusProps {
    count: number,
    type: number
}

export interface StatusesProps {
    statutes?: StatusProps[]
}
export type StatusComponent = React.FC<StatusesProps>

const Status: StatusComponent = ({statutes}) => {
    const {t} = useTranslation("machine.status");
    return (
        <div className={classNames(classes['statutes'])}>
            {statutes && statutes.map((item, index) => <span key={index} className={classNames(classes['status'], classes[item.type])} >{item.count} {t(`${item.type}`)}</span>)}
        </div>
    );
};

export default Status
