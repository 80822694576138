import React, {useCallback} from 'react';
import classes from './style.module.scss';
import Button from 'app/common/Button/index';
import useTranslation from 'app/hooks/useTranslation';
import {useStoreState} from "store/index";

export interface FooterProps {
    onSelect: (boolean) => void
}

export type FooterComponent = React.FC<FooterProps>;

const Footer: FooterComponent = ({onSelect}) => {
    const {t} = useTranslation('offline.dialog');
    const loading = useStoreState(state => state.machine.updating);

    const submitClickHandler = useCallback((): void => {
        onSelect(true);
    }, [onSelect]);

    const cancelClickHandler = useCallback((): void => {
        onSelect(false);
    }, [onSelect]);

    return (
        <div className={`${classes['footer']}`}>
            <Button
                className={classes['abort']}
                onClick={cancelClickHandler}>{t('cancel')}</Button>
            <Button
                className={`${classes['register']} `}
                loading={loading}
                onClick={submitClickHandler}>{t('submit')}</Button>
        </div>
    );
};

export default Footer
