import React, { useCallback, useEffect, useState } from 'react';

import useDidMountEffect from '~/app/hooks/useDidMountEffect';

import Loader from '~/app/common/Loader';

import { useStoreActions, useStoreState } from '~/store';

import { MSALCordova } from './msalCordova';
import LoginCanceled from './LoginCanceled';
import i18n from 'i18next';

export type CordovaProviderComponent = React.FC

const CordovaProvider: CordovaProviderComponent = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loginCanceled, setLoginCanceled] = useState<boolean>(false);
  const tenantError = useStoreState(state => state.tenant.tenantError);
  const token = useStoreState(state => state.user.token);
  const userData = useStoreState(state => state.user.data);

  const setStoreToken = useStoreActions(actions => actions.user.setToken);
  const logoutAction = useStoreActions(actions => actions.user.logout);

  const msalSignedInHandler = useCallback((response: MSALCordova.signInResponse): void => {
    setStoreToken(response.token);

    setLoading(false);
    setLoginCanceled(false);
  }, [setStoreToken]);

  const showLoginCanceledWindow = useCallback((): void => {
    setLoginCanceled(true);
  }, []);

  const startLogin = useCallback((): void => {
    setLoading(true);

      MSALCordova.signInInteractive({
          prompt: MSALCordova.SignInInteractivePrompts.login,
          authorizationQueryStringParameters: [
            {param: 'lang', value: i18n.language},
          ]
      })
      .then(msalSignedInHandler)
      .catch(() => {
        logoutAction().then(showLoginCanceledWindow)
      });
  }, [msalSignedInHandler, showLoginCanceledWindow, logoutAction]);

  const loginCanceledLoginClickHandler = useCallback((): void => {
    startLogin();

    setLoginCanceled(false);
  }, [startLogin]);

  useEffect(() => {
    !loading && !token && showLoginCanceledWindow();
  }, [token, loading, showLoginCanceledWindow]);

  useDidMountEffect(() => {
    MSALCordova.init()
      .then(() => {
        if (userData.id && window['cordova'].platformId === 'android') {
          setLoading(false);
        } else {
          MSALCordova.signInSilent()
            .then(msalSignedInHandler)
            .catch(startLogin);
        }
      })
      .catch(showLoginCanceledWindow);
  });

  if (loginCanceled && !tenantError) {
    return <LoginCanceled onLoginClick={loginCanceledLoginClickHandler} />;
  }

  if (loading && !tenantError) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default CordovaProvider
