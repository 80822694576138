import React, {useCallback, useState} from 'react';
import classes from './style.module.scss';
import useTranslation from "~/app/hooks/useTranslation";
import FlashMessage from "~/app/common/FlashMessage";
import RegisterOperatingTime from '~/operatingTime/Register';
import {Machine, OperatingUnitTypePayload} from "~/types";
import {useStoreState} from "~/store";
import { OperatingTypes } from '~/app/data/statuses/operatingData';

export interface MessageProps {
    type: Machine['categoryType'];
    operatingUnitType: OperatingUnitTypePayload;
}

export type MessageComponent = React.FC<MessageProps>

const Message: MessageComponent = ({type, operatingUnitType}) => {
    const {t} = useTranslation("periodicCheck");
    const [openOperatingTimeDialog, setOpenOperatingTimeDialog] = useState<boolean>(false);
    const inspectionType = useStoreState(state => state.periodicCheck.inspectionType);

    const toggleOperatingTimeDialog = useCallback(() => {
        setOpenOperatingTimeDialog(!openOperatingTimeDialog);
    }, [setOpenOperatingTimeDialog, openOperatingTimeDialog]);

    return (
        <>

            <FlashMessage type={'info'} icon={"error"}>
                {t("please")} <span className={classes['register-operating-time']} onClick={toggleOperatingTimeDialog}>
                    {t(`${operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'registerCount' : (type === 'vehicle' ? 'registerKm' : 'registerTime')}`)}
                </span> {t("before")} {t(`${inspectionType}.title`)}
            </FlashMessage>
            <RegisterOperatingTime open={openOperatingTimeDialog} onClose={toggleOperatingTimeDialog} operatingUnitType={operatingUnitType} />
        </>
    )
}
export default Message