import React from 'react';
import classes from './style.module.scss';
import { useStoreState } from '~/store';
import AssetDashboardAverageInfo from '../../common/AssetDashboardAverageInfo';
import { Bar, Cell, ComposedChart, ResponsiveContainer } from 'recharts';
import FillPattern from '../../common/fillPattern';
import { inspectionData } from '~/types';
import { InspectionRateParams } from '~/app/data/statuses/machine';

export interface InspectionGraphProps {
  inspectionData: inspectionData
}

export type InspectionGraphComponent = React.FC<InspectionGraphProps>;

const InspectionGraph: InspectionGraphComponent = ({ inspectionData }) => {
  const inspectionDataOfLast10Weeks = useStoreState(state => state.machine.data.inspectionDataOfLast10Weeks);

  const pattern = { width: 8, height: 8 };
  const rect = { width: 5, height: 8 };

  return (
    <>
      <div className={classes['inspection-data']}>
        {inspectionData.last10WeeksPeriodicChecks}%
      </div>
      <div className={classes['inspection-performance-info']}>
        <AssetDashboardAverageInfo performanceData={inspectionData.previous10WeeksPerformanceCompareToAverage} reverseColors={true} />
      </div>
      <ResponsiveContainer width="100%" height={60}>
        <ComposedChart
          data={inspectionDataOfLast10Weeks}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          barSize={InspectionRateParams.BARSIZE}
        >
          <defs>
            <FillPattern id="inspection-pattern" pattern={pattern} rect={rect} fill="var(--color-low-performance)" patternTransform="rotate(45)" />
          </defs>
          <Bar dataKey="value">
            {
              inspectionDataOfLast10Weeks.map((entry, index) => {
                return <Cell key={`cell-${index}`}
                  stroke={entry.isFilled ? 'var(--color-high-performance)' : 'var(--color-low-performance)'}
                  fill={entry.isFilled ? 'var(--color-high-performance)' : 'url(#inspection-pattern)'}
                />
              })
            }
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}

export default InspectionGraph