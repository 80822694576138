import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from '~/store';
import EmptyMessage from './../EmptyMessage';
import List from './List';
import {Equipment, Machine, Project} from "~/types";
import {useParams} from "react-router-dom";
import Loader from "~/app/common/Loader";
import {useHistory} from "react-router";

export type ArchivesComponent = React.VFC;

const Archives: ArchivesComponent = () => {
    const history = useHistory();
    const {projectId, id, equipmentId} = useParams<{ projectId: Project['id'], id: Machine['id'], equipmentId: Equipment['id'] }>();
    const [fetching, setFetching] = useState<boolean>(true);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const machineArchives = useStoreState(state => state.documentation.machineArchives);
    const parentFolderId = useStoreState(state => state.documentation.parentFolderId);

    const fetchMachineArchivesAction = useStoreActions(actions => actions.documentation.fetchMachineArchives);
    const getOfflineMachineArchives = useStoreActions(actions => actions.documentation.getOfflineMachineArchives);
    const setActiveTab = useStoreActions(actions => actions.documentation.setActiveTab);


    useEffect(() => {
        // netWorkStatus && setActiveTab('specifications');
        // netWorkStatus && history.push(route(MACHINES_DETAILS_ROUTES.details, {projectId, id}));
    }, [history, netWorkStatus, projectId, id, setActiveTab]);
    useEffect(() => {
        setFetching(true);
        if(netWorkStatus){
            console.log({machineId: equipmentId || id, documentId: parentFolderId})
            getOfflineMachineArchives({machineId: equipmentId || id, documentId: parentFolderId}).then(() => setFetching(false))
        } else {
            fetchMachineArchivesAction({machineId: equipmentId || id, parentFolderId}).then(() => setFetching(false));
        }

    }, [id, equipmentId, parentFolderId, fetchMachineArchivesAction, setFetching, getOfflineMachineArchives, netWorkStatus]);

    if (fetching) {
        return <Loader size="32"/>;
    }

    if (!machineArchives.length) {
        return <EmptyMessage/>;
    }

    return (
        <>
            <List list={machineArchives} machineId={id}/>
        </>
    );
};
export default Archives
