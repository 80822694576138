import React, {useCallback} from 'react';
import classes from './style.module.scss';
import {IssueCategory} from 'types/index';
import useTranslation from 'app/hooks/useTranslation';
import {useStoreState} from "store/index";
import List from './List/index';
import useForm from "~/app/modules/form/useForm";
import {IssuePayload} from "~/types";

export type CategoriesComponent = React.FC;

const Categories: CategoriesComponent = () => {
    const {t} = useTranslation('issue');
    const issueCategories = useStoreState(state => state.issue.categories);
    const {setValue, values} = useForm<IssuePayload>({formName: 'addIssue'});

    const selectCategoryHandler = useCallback((payload: IssueCategory): void => {
        setValue('categoryId',payload.id);
        setValue('categoryName',payload.name);
    }, [setValue]);

    return (
        <div className={classes['categories']}>
            <div className={classes['title']}>{t('issueCategories')}</div>
            <div className={classes['sub-title']}>{t('selectIssueCategory')}</div>
            <List list={issueCategories} activeItem={values.categoryId as IssueCategory['id']} onSelect={selectCategoryHandler}/>
        </div>
    );
};

export default Categories
