import React, {useCallback} from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import {useStoreActions, useStoreState} from '~/store';
import classes from "./style.module.scss";
import {MachineActiveTabs} from "~/types";

export type TabHeaderComponent = React.FC

const TabHeader: TabHeaderComponent = () => {
    const {t} = useTranslation("machine");
    const tabs = useStoreState(state => state.machine.tabs);
    const activeTab = useStoreState(state => state.machine.activeTab);
    const setActiveTab = useStoreActions(actions => actions.machine.setActiveTab);

    const clickHandler = useCallback((tab: MachineActiveTabs['activeTab']) => {
        setActiveTab(tab)
    }, [setActiveTab]);

    return (
        <div className={classes['header']}>
            {tabs.map((tab, index) =>
                <div key={index} className={`${classes['link']} ${tab === activeTab && classes['active']}`} 
                onClick={() => clickHandler(tab)}>{t(`tabs.${tab}`)}</div>)}
        </div>
    );
};

export default TabHeader

