import React, {useEffect} from 'react';
import usePageTitle from "~/app/hooks/usePageTitle";
import {useAppHeader} from "~/app/modules/appHeader";
import useTranslation from "~/app/hooks/useTranslation";
import {ROUTES} from "~/app/Router";
import classes from './style.module.scss';

export type InstructionsComponent = React.FC

const Instructions: InstructionsComponent = () => {
    const {setData} = useAppHeader();
    const {t} = useTranslation("instructions");
    usePageTitle(t('title'));

    useEffect(() => {
        setData({
            title: t('title'),
            subTitle: '',
            backLink: ROUTES.projects,
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: false
        });
    }, [setData, t]);

    return (
        <div className={classes['instruction']}>
            <h1>Bruksanvisning for Førerappen</h1>

            <h2>Innlogging</h2>

            <p>Som bruker logger du inn med brukernavn og passord. Ved å sjekke av ‘Husk meg’ på innloggingssiden gjør du at mobilen din husker brukernavn og passord neste gang du åpner siden.</p>

            <h2>Velg prosjekt</h2>

            <p>Du blir bedt om å finne et Prosjekt. Dette kan gjøres ved å søke på Prosjekt nr. eller ved å velge et prosjekt fra Favoritter. Se neste avsnitt for mer informasjon om hvordan du legger et prosjekt til som favoritt.</p>

            <h2>Hvordan legge et prosjekt til som favoritt</h2>

            <ol>
                <li>Søk på Prosjekt nr.</li>
                <li>På den neste siden, trykk på stjernen ved siden av prosjektet du valgte. Når denne blir gul er prosjektet lagt til som favoritt og vil vise neste gang du skal velge et prosjekt, under søkeboksen.</li>
            </ol>

            <h2>Velg maskin / kjøretøy / utstyr</h2>

            <p>Du blir så bedt om å velge maskin / kjøretøy / utstyr. Dette kan gjøres ved å søke på Intern nr., eller ved å velge fra favoritter. Se neste avsnitt for mer informasjon om hvordan du legger maskin / kjøretøy / utstyr til som favoritt.</p>

            <h2>Hvordan legge maskin / kjøretøy / utstyr til som favoritt</h2>

            <ol>
                <li>Søk på Intern nr.</li>
                <li>På den neste siden, trykk på stjernen ved siden av Intern nr. Når denne er gul, er maskinen / kjøretøyet / utstyret blitt lagt til som favoritt og vil vise neste gang du skal velge maskin / kjøretøy / utstyr.</li>
            </ol>

            <h2>Hovedfunksjoner</h2>

            <p>Førerappen er et verktøy som består av følgende hovedfunksjoner:</p>

            <ol>
                <li>Gangtid / Km. stand (avhengig av type)</li>
                <li>Daglig sjekk</li>
                <li>Status</li>
                <li>Registrer mindre feil</li>
                <li>Rep. utført av fører</li>
                <li>Registrer alvorlig feil</li>
                <li>Driftsstans</li>
            </ol>

            <ol>
                <li>
                    <h3>Gangtid / Km. stand</h3>

                    <p>Maskinens gangtid føres minst en gang i uken eller oftere for at systemet skal kunne forutsi neste gang maskinen skal inn på service/vedlikehold. Dette er et nummer som viser hvor mange timer maskinen har vært i drift, og vil kunne leses av maskinens timeteller.</p>

                    <p>Det samme gjelder Km. stand for kjøretøy.</p>

                    <p>Under dette menypunktet vises forrige måling, dvs. sist registrerte gangtid/km. stand. Man kan oppdatere gangtid/km. stand ved å skrive inn antall timer i feltet under.</p>

                    <p>For maskin/utstyr: Trykk på ”Registrer Gangtid” for å registrere den nye gangtiden.</p>

                    <p>For kjøretøy: Trykk på ”Registrer Km. stand” for å registrere ny km. stand.</p>
                </li>
                <li>
                    <h3>Daglig sjekk</h3>

                    <p>Under dette menypunktet er det en sjekkliste for å registrere daglig sjekk på maskinen / kjøretøyet / utstyret.</p>
                    <p>Indikatoren på selve menyknappen har to statuser:</p>

                    <ul>
                        <li>Rød – Daglig sjekk har ikke blitt utført</li>
                        <li>Grønn – Daglig sjekk har blitt utført</li>
                    </ul>

                    <p>Denne statusen nullstilles hver dag.</p>
                    <p>For å utføre daglig sjekk gjør følgende:</p>

                    <ol>
                        <li>Trykk på menypunkt ”Daglig sjekk” for å åpne sjekklisten</li>
                        <li>Gå gjennom sjekklisten:
                            <ul>
                                <li>Trykk én gang hvis punktet er sjekket og er bekreftet OK</li>
                                <li>Trykk to ganger for å registrere en feil. Se eget punkt på dette nedenfor.</li>
                                <li>Når man trykker på sjekkpunkter som inkluderer væsker vil du bli bedt om å registrere eventuelt påfyll i liter ved å trykke på pluss og minus-tegnene med et intervall på 0.5 liter per trykk.<br/>
                                    NB: Hvis du IKKE har fylt på noe, så kan du klikke ”Ikke etterfylt” for å gå videre med sjekken. Da vil det bli registrert 0 liter.
                                </li>
                            </ul>
                        </li>
                        <li>Trykk på ”Fullført” for å registrere den daglige sjekken.</li>
                    </ol>

                    <p>Dersom et punkt markeres med rødt, som tilsier at det er oppdaget en feil som må utbedres, gjør følgende: </p>

                    <ol>
                        <li>Velg type feil (mindre feil, alvorlig feil, driftsstans, Rep. utført av fører)</li>
                        <li>Velg feilkategori</li>
                        <li>Velg avdelingen feilen skal sendes til (hvis usikker, velg Avd. Nærbø)</li>
                        <li>Legg inn beskrivelse av feilen i kommentarfeltet som åpner seg. Du kan også ta opptil 3 bilder av feilen med mobilen. Merk at bilder av høy kvalitet vil kunne ta tid å laste opp</li>
                        <li>Trykk på ”Registrer feil” for å registrere feilen i systemet for videre oppfølging av teknisk personell. Du kan fortsette med sjekklisten etter dette</li>
                    </ol>

                    <p>Hvis du ikke mente å registrere en feil, trykk en gang til på sjekkpunktet. Da vil det røde sjekkpunktet for å gå tilbake til en tom sjekkboks. Kommentarfeltet vil automatisk skjules.</p>
                    <p>Oppdages det en større feil som tilsier at maskinen ikke er kjørbar må du kontakte ansvarlig person direkte etter gjeldene rutiner (på telefon).</p>

                    <p>Merk at minst ett av punktene må være sjekket grønt eller rødt for at du skal kunne fullføre registrering av Daglig sjekk.</p>
                </li>
                <li>
                    <h3>Status</h3>

                    <p>Status lar deg følge status på feil som er registrert på maskinen. Før du åpner menypunktet kan du se 3 tellere med fargekodene rød, gul og grønn. Når det er registrert feil vil det stå hvor mange i tellerne.</p>

                    <ul>
                        <li>Rød – Antall feil som er registrert og ubehandlet (Registrert)</li>
                        <li>Gul – Antall feil planlagt på arbeidsordre (Under arbeid)</li>
                        <li>Grønn – Antall feil som er ferdig utbedret på arbeidsordre (Ferdigmeldt)</li>
                    </ul>

                    <p>Ved å klikke deg inn på menypunktet får du ytterligere detaljer:</p>

                    <ul>
                        <li>Oversikt over hver enkelt feil, med tilhørende beskrivelse</li>
                        <li>Grafisk fremstilling av hvor de befinner seg i prosessen (se statuser ovenfor)</li>
                    </ul>

                    <h3>Tilbakemelding / Chat</h3>
                    <p>Ved å klikke på en feil under Status vil du få opp chat-funksjonaliteten. Her vil du kunne få tilbakemelding fra Teknisk personell / servicebilkoordinator på den registrerte feilen.</p>

                    <ul>
                        <li>Merk: hver enkelt chat-samtale er knyttet til den spesifikke feilen i feilhistorikken</li>
                    </ul>
                </li>
                <li>
                    <h3>Registrer mindre feil</h3>

                    <p>Her kan du registrere mindre feil på maskinen, som da blir tilgjengelig for oppfølging av teknisk personell eller verksted i systemet. For å registrere feil, gjør følgende:</p>

                    <ol>
                        <li>Klikk på ”Registrer mindre feil” i menyen</li>
                        <li>Velg feilkategori </li>
                        <li>Velg avdelingen feilen skal sendes til (hvis usikker, velg Avd. Nærbø)</li>
                        <li>Legg inn beskrivelse av feilen i kommentarfeltet som åpnes. Evt. ta et bilde med mobilen (maks. antall bilder: 3)</li>
                        <li>Klikk på ”Registrer feil” for å avslutte, eller ”Tilbake” for å angre</li>
                    </ol>
                </li>
                <li>
                    <h3>Rep. utført av fører</h3>

                    <p>Når man gjør mindre reparasjoner selv, skal disse registreres i systemet.  Dette gjøres på samme måte som når man registrerer mindre feil.</p>

                    <p>For å registrere reparasjoner, gjør følgende:</p>

                    <ol>
                        <li>Klikk på ”Rep. utført av fører” i menyen</li>
                        <li>Velg ønsket kategori for reparasjonen (Bruk ”Annet” når det ikke er en predefinert kategori som passer)</li>
                        <li>Legg inn beskrivelse av reparasjonen  i kommentarfeltet som åpnes. Evt. ta et bilde med mobilen (maks. antall bilder: 3)</li>
                        <li>Klikk på ”Registrer” for å avslutte, eller ”Tilbake” for å angre</li>
                    </ol>

                    <p>Du kan også registrere reparasjoner utført av andre ansatte.</p>
                </li>
                <li>
                    <h3>Registrer alvorlige feil</h3>

                    <p>Her kan du registrere alvorlige feil på maskinen, som da blir tilgjengelig for oppfølging av teknisk personell eller verksted i systemet. For å registrere feil, gjør følgende:</p>

                    <ol>
                        <li>Klikk på ”Registrer alvorlig feil” i menyen</li>
                        <li>Velg feilkategori</li>
                        <li>Velg av deling feilen skal sendes til (hvis usikker, velg Avd. Nærbø)</li>
                        <li>Legg inn beskrivelse av feilen i kommentarfeltet som åpnes. Evt. ta et bilde med mobilen (maks. antall bilder: 3)</li>
                        <li>Klikk på ”Registrer feil” for å avslutte, eller ”Tilbake” for å angre</li>
                    </ol>
                </li>
                <li>
                    <h3>Driftsstans</h3>

                    <p>VIKTIG: Ved driftsstans skal man ringe inn først og registrere etterpå.</p>
                    <p>Du registrerer driftsstans på samme måte som mindre og alvorlige feil. For å registrere driftsstans:</p>

                    <ol>
                        <li>Klikk på ”Driftsstans” i menyen</li>
                        <li>Velg feilkategori</li>
                        <li>Velg av deling feilen skal sendes til (hvis usikker, velg Avd. Nærbø)</li>
                        <li>Legg inn beskrivelse av feilen i kommentarfeltet som åpnes. Evt. ta et bilde med mobilen (maks. antall bilder: 3)</li>
                        <li>Klikk på ”Registrer feil” for å avslutte, eller ”Tilbake” for å angre</li>
                    </ol>
                </li>

            </ol>

            <h2>Meny</h2>

            <p>Ved å klikke på menyikonet øverst i venstre hjørne vil du få opp en liten meny med forskjellige valg.</p>
            <p>Fra denne menyen kan du gå direkte til sidene hvor du søker på prosjekt og maskin / kjøretøy / utstyr og i tillegg til at du finner denne bruksanvisningen på et eget punkt.</p>

            <h2>Logg av</h2>

            <p>Du kan logge av to forskjellige steder i appen:</p>

            <ol>
                <li>I sidemenyen: trykk på ”Logg av”</li>
                <li>På hovedsiden: trykk på ikonet ved siden av brukernavnet ditt og så ”Logg av”</li>
            </ol>

            <p className={classes['light']}>Bruksanvisningen blir oppdatert fortløpende ved endringer.</p>
        </div>
    );
};

export default Instructions
