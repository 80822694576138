import { Action, action, State } from 'easy-peasy';
import { ReactNode } from 'react';

import { MaterialIcons } from '~/app/common/Icon';
import {Archive, Machine, Project} from "~/types";

export interface SetAppHeaderDataPayload {
  backLink?: string | (() => void)
  backLinkIcon?: MaterialIcons
  chatIcon?: MaterialIcons
  menuIcon?: MaterialIcons
  searchIcon?: MaterialIcons
  title?: ReactNode
  subTitle?: ReactNode
  showUser?: boolean
  machineId?: Machine['id']
  projectId?: Project['id']
  parentFolderId?: Archive['id']
  showMachineMessages?: boolean
  isIssueDetailPage?:boolean
  notification?: boolean
  search?: boolean
}

export interface AppHeaderModel extends SetAppHeaderDataPayload {
  setData: Action<AppHeaderModel, SetAppHeaderDataPayload>
  clearData: Action<AppHeaderModel, undefined>
}

const defaultState: State<AppHeaderModel> = {
  backLink: undefined,
  backLinkIcon: 'arrow_back',
  chatIcon: 'forum',
  menuIcon: 'menu',
  searchIcon: 'search',
  title: undefined,
  subTitle: undefined,
  showUser: false,
  machineId: '',
  projectId: '',
  parentFolderId: 0,
  showMachineMessages: false,
  isIssueDetailPage: false,
  notification: false,
  search: false
};

export const appHeaderModel: AppHeaderModel = {
  ...defaultState,
  setData: action((state, payload) => ({ ...defaultState, ...state, ...payload })),
  clearData: action(() => ({ ...defaultState }))
};
