import React, { useCallback, useMemo, useState } from 'react';

import useTranslation from '~/app/hooks/useTranslation';
import { useAppLoader } from '~/app/modules/appLoader';

import { useStoreActions } from '~/store';

import Item, { ItemProps } from './Item';
import ExternalLink from "~/app/common/ExternalLink";

export type MenuComponent = React.FC

const Menu: MenuComponent = () => {
  const { t } = useTranslation('mainNav');
  const { REACT_APP_HELP_URL = '' } = process.env;

  const { showAppLoader, hideAppLoader } = useAppLoader();
  const [showUserGuideDialog, setShowUserGuideDialog] = useState<boolean>(false);

  const logoutAction = useStoreActions(actions => actions.user.logout);

  const itemLogoutClickHandler = useCallback((): void => {
    showAppLoader();

    logoutAction({ microsoftLogout: true }).finally(hideAppLoader);
  }, [hideAppLoader, logoutAction, showAppLoader]);

  const toggleShowUserGuideDialog = useCallback(() => {
    setShowUserGuideDialog(!showUserGuideDialog);
  }, [showUserGuideDialog]);

  const items = useMemo<({ key: string } & ItemProps)[]>(() => ([
    { key: 'logout', label: t('signOut'), to: '#', clickHandler: itemLogoutClickHandler, itemClassName: 'font-color-red' },
    { key: 'about', label: t('aboutUs'), to: '/aboutUs', showArrow: false },
    { key: 'userGuide', label: t('userGuide'), to: '#', clickHandler: toggleShowUserGuideDialog },
  ]), [t, itemLogoutClickHandler, toggleShowUserGuideDialog]);

  return (
    <div>
      {items.map(item => <Item {...item} />)}
      <ExternalLink open={showUserGuideDialog} onClose={toggleShowUserGuideDialog} url={REACT_APP_HELP_URL} />
    </div>
  );
};

export default Menu
