import i18next, { FormatFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import translationEN from './langs/en.json';
import translationNO from './langs/no.json';
import { ChangeLanguage, Expression, Language, LanguageDetail, TranslationObject } from '~/types';
import { Languages, mapLanguageFlags } from '~/app/data/statuses/i18n';
import { currentUserLanguage, prepareLanguageObj } from '~/app/helpers/i18n';

// Mapping of language locales to their JSON objects
const languageJSONMap: { [key: string]: Record<string, any> } = {
  [Languages.NORWEGIAN]: translationNO,
  [Languages.ENGLISH]: translationEN
};

// Add default norwegian language
export const availableLanguages: Language[] = [prepareLanguageObj(Languages.NORWEGIAN, mapLanguageFlags[Languages.NORWEGIAN], 'Norwegian')];

// Add default translation resources for norwegian language
const translationResources: any = {
  [Languages.NORWEGIAN]: {
    translation: languageJSONMap[Languages.NORWEGIAN]
  }
};

const fallbackLng: Languages[] = [Languages.NORWEGIAN];

// Set available languages for translation dynamically
export const setAvailableLanguages = (languages: LanguageDetail[]) => {
  availableLanguages.length = 0; // Clear existing languages

  try {
    if (languages && languages.length > 0) {
      languages.forEach(lang => {
        // Prepare the language object and push to availableLanguages
        const languageObj = prepareLanguageObj(lang.locale, mapLanguageFlags[lang.locale], lang.name);
        availableLanguages.push(languageObj);

        // Add resource bundle for the language
        languageJSONMap[lang.locale] && (translationResources[lang.locale] = {translation: languageJSONMap[lang.locale]});
      });
    }
  } catch (error) {
    // Handle errors and provide default fallback language
    console.error('Error setting available languages:', error);
  }

  // Fallback to default language if no languages received
  if(!availableLanguages?.length) {
    const defaultLanguage = prepareLanguageObj(Languages.NORWEGIAN, mapLanguageFlags[Languages.NORWEGIAN], 'Norwegian');
    availableLanguages.push(defaultLanguage);
    translationResources[Languages.NORWEGIAN] = {translation: languageJSONMap[Languages.NORWEGIAN]};
  }

  // Initialize i18next with the current user language
  initializeI18n(currentUserLanguage);
};

// Change language 
export const changeLanguage: ChangeLanguage = language => i18next.changeLanguage(String(language));

const format: FormatFunction = (value, format) => {
  switch (format) {
    case 'uppercase':
      return String(value).toUpperCase();
    case 'lowercase':
      return String(value).toLowerCase();
    case 'number':
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  const functions: ({ regExp: RegExp, matchCallback: (args: RegExpMatchArray) => any })[] = [
    { regExp: /slice\((\d+),\s?(\d+)\)/, matchCallback: args => (value || '').slice(+args[1], +args[2]) }
  ];

  if (format) {
    for (const item of functions) {
      if (item.regExp.test(format)) {
        const args = format.match(item.regExp);

        if (args) {
          return item.matchCallback(args);
        }
      }
    }
  }

  //const date = moment.parseZone(value); // Removed timezone transformation
  const date = moment(value);

  if (date.isValid()) {
    return date.local().format(format || 'L');
  }

  return value;
};

// Set text for language label translation
const setLanguagesLabel = () => {
  availableLanguages.forEach((language, index) => {
    availableLanguages[index].label = i18next.t(`system.i18n.languages.${language.value}`);
  });
};

// initialize i18next with user-language or default language
const initializeI18n = async (userLanguage: Languages) => {
  try {
    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng,
        lng: userLanguage || Languages.NORWEGIAN, // Use the user's language from localStorage, or default to Norwegian
        whitelist: _.map(availableLanguages, 'value'),
        resources: translationResources,
        interpolation: {
          escapeValue: false,
          format
        },
        react: {
          useSuspense: false
        }
      });

    setLanguagesLabel(); // Update language labels after init
  } catch (error) {
    console.error('Error initializing i18n:', error);
  }
}

// Call initialization with user-preferred language
initializeI18n(currentUserLanguage);

i18next.on('languageChanged', lng => {
  moment.locale(lng);
  setLanguagesLabel();
});

// Replace expression text in the respective language JSON object
export const replaceExpressionsForLanguages = (expressions: Expression[]) => {
  if (expressions?.length) {
    availableLanguages.forEach((language) => {
      replaceTextInTranslations(languageJSONMap[language.value], expressions, language.value);
    });
  }
}

// Recursive function to replace expression text
const replaceTextInTranslations = (translationObject: TranslationObject, expressions: Expression[], language: Languages) => {
  if (typeof translationObject === 'object' && translationObject) {
    Object.keys(translationObject).forEach(key => {

      // If value is an object, recursively traverse it
      if (typeof translationObject[key] === 'object') {
        translationObject[key] = replaceTextInTranslations(translationObject[key], expressions, language);
      }
      // If value is a string, compare and replace if needed
      else if (typeof translationObject[key] === 'string') {
        const expression = expressions.find(e => e.currentText === translationObject[key] && e.language === language);
        if (expression) {
          translationObject[key] = expression.newText; // Replace the current text with the new text
        }
      }
    });
  }
  return translationObject;
}

export default i18next;
