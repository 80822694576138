import { Action, action } from 'easy-peasy';

import { localStorageGet, localStorageRemove, localStorageSet } from '~/app/helpers/localStorage';

import { isCordova } from '~/cordova';

import {Tenant, tenantCode, tenantErrorKey} from './config';
import { getTenant } from './helpers';

export interface TenantError {
    code: number
    message: string
}

export type TenantModel = {
  currentTenant: Tenant | undefined

  tenantError: TenantError | undefined
  setTenantError: Action<TenantModel, TenantError | undefined>
}

export const tenantModel: TenantModel = {
  currentTenant: getTenant(tenantCode),

  tenantError: isCordova && localStorageGet(tenantErrorKey) ? JSON.parse(localStorageGet(tenantErrorKey) || '') : undefined,
  setTenantError: action((state, payload) => {
    isCordova && (payload ? localStorageSet(tenantErrorKey, JSON.stringify(payload)) : localStorageRemove(tenantErrorKey));
    state.tenantError = payload;
  })
};
