import React from 'react';
import classes from './style.module.scss';
import Icon from 'app/common/Icon/index';
import useTranslation from 'app/hooks/useTranslation';

export type HeaderComponent = React.FC;

const Header: HeaderComponent = () => {
    const {t} = useTranslation('offline.dialog');

    return (
        <div className={classes['model-header']}>
            <Icon name={"wifi_off"} className={classes['icon']}/>
            <div className={classes['header-content']}>
                <label className={classes['title']}>{t('title')}</label>
            </div>
        </div>
    );
};

export default Header
