import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RouterSuspense from '~/app/common/RouterSuspense';
import List from './';

export const PROJECTS_ROUTES = {
    projects: '/',
    list: '/projects'
};

export type RouterComponent = React.FC

const Router: RouterComponent = () => (
    <RouterSuspense>
        <Switch>
            <Route path={[PROJECTS_ROUTES.projects, PROJECTS_ROUTES.list]} component={List}/>
        </Switch>
    </RouterSuspense>
);

export default Router;