import Axios, {AxiosInstance} from 'axios'

const axiosWrapper = () => {

    const reset = controller => setTimeout(() => {
        controller.abort();
        console.log('stop request after 30 second awaiting')
    }, 30000);

    const get: AxiosInstance['get']  = (url, params = {}): Promise<any> => {
        const controller = new AbortController();
        const timeout = reset(controller)

        return Axios.get(url, {...params, signal: controller.signal}).then(data => {
            clearTimeout(timeout);
            return data;
        }).catch(e => {
            clearTimeout(timeout);
            throw e;
        })
    }

    const post: AxiosInstance['post']  = (url, data, params = {}): Promise<any> => {
        const controller = new AbortController();
        const timeout = reset(controller)

        return Axios.post(url, data, {...params, signal: controller.signal}).then(data => {
            clearTimeout(timeout);
            return data;
        }).catch(e => {
            clearTimeout(timeout);
            throw e;
        })
    }

    const put: AxiosInstance['put']  = (url, data, params = {}): Promise<any> => {
        const controller = new AbortController();
        const timeout = reset(controller)

        return Axios.put(url, data, {...params, signal: controller.signal}).then(data => {
            clearTimeout(timeout);
            return data;
        }).catch(e => {
            clearTimeout(timeout);
            throw e;
        })
    }

    const patch: AxiosInstance['patch']  = (url, data, params = {}): Promise<any> => {
        const controller = new AbortController();
        const timeout = reset(controller)

        return Axios.patch(url, data, {...params, signal: controller.signal}).then(data => {
            clearTimeout(timeout);
            return data;
        }).catch(e => {
            clearTimeout(timeout);
            throw e;
        })
    }

    const deleteAction: AxiosInstance['delete']  = (url, params = {}): Promise<any> => {
        const controller = new AbortController();
        const timeout = reset(controller)

        return Axios.delete(url, {...params, signal: controller.signal}).then(data => {
            clearTimeout(timeout);
            return data;
        }).catch(e => {
            clearTimeout(timeout);
            throw e;
        })
    }

    return {
        post,
        get,
        put,
        patch,
        delete: deleteAction
    }
}

export default axiosWrapper()
