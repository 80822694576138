import React, {useCallback} from 'react';
import classNames from 'classnames';
import Icon from '~/app/common/Icon';
import {MaterialIcons as Icons} from '~/app/common/Icon/icons';
import classes from './style.module.scss';
import SubTitle, {SubTitleProps} from "~/machines/Details/Registration/List/ListItem/SubTitle";
import Status, {StatusProps} from "~/machines/Details/Registration/List/ListItem/Status";
import {useHistory} from "react-router";
import {default as AccessControl, RequiredPermissions} from "~/accessControl";

export interface ItemProps{
    icon: string
    title: string
    thirdTitle?: SubTitleProps | null;
    statuses?: StatusProps[]
    subTitle?: SubTitleProps | null
    rightIcon: string
    className?: string
    onSelect?: (ItemProps) => void
    url?: string
    requiredPermissions: RequiredPermissions
}

export interface ListItemProps {
    item: ItemProps
    onSelect?: (item: any) => void
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item}) => {
    const history = useHistory();
    const itemClickHandler = useCallback(() => {
        if (item.onSelect) {
            item.onSelect(item);
        }
        if(item.url) {
            history.push(item.url);
        }
    }, [item, history]);

    return (
        <AccessControl requiredPermissions={item.requiredPermissions}>
            <div className={classNames(classes['list-item'], (item.className && classes[item.className]))} onClick={itemClickHandler}>
                <div className={classes['left-icon-wrap']}>
                    <Icon name={item.icon as Icons}/>
                </div>
                <div className={classes['info']}>
                    {item.title && <div className={classes['title']}>{item.title}</div>}
                    {(item.subTitle && !item.statuses) && <SubTitle {...item.subTitle}/>}
                    {(item.thirdTitle) && <SubTitle {...item.thirdTitle}/>}
                    {item.statuses && <Status statutes={item.statuses}/>}
                </div>

                <div className={classNames(classes['right-icon-wrap'])}>
                    <Icon name={item.rightIcon as Icons}/>
                </div>
            </div>
        </AccessControl>
    );
};

export default ListItem
