import React, {useEffect} from 'react';
import {useStoreState, useStoreActions} from "~/store";
import {useLocation} from "react-router";
import FlashMessage from "~/app/common/FlashMessage";
import Offline from "./Offline";
import IssueMessage from "./Issue";

export type MessagesComponent = React.FC

const Messages: MessagesComponent = () => {
    const messages = useStoreState(state => state.app.flashMessage.messages);
    const setFlashMessages = useStoreActions(actions => actions.app.flashMessage.setMessages);
    const location = useLocation();

    useEffect(() => {
        setFlashMessages([]);
    }, [location, setFlashMessages]);

    return (
        <>
            <Offline/>
            <IssueMessage/>
            {messages && messages.map((message, index) => <FlashMessage {...message} key={index}/>)}
        </>
    )
};

export default Messages
