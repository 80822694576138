import React, {useCallback, useEffect} from 'react';
import useTranslation from "~/app/hooks/useTranslation";
import {useStoreActions, useStoreState} from '~/store';
import classes from "./style.module.scss";
import AccessControl from "~/accessControl";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import useAccessControl from "~/app/hooks/useAccessControl";

export type HeaderComponent = React.FC

const Header: HeaderComponent = () => {
    const {t} = useTranslation("issues");
    const {checkPermission} = useAccessControl();
    const showCompletedIssues = useStoreState(state => state.issue.showCompletedIssues);
    const setShowCompletedIssues = useStoreActions(actions => actions.issue.setShowCompletedIssues);

    const clickHandler = useCallback((show) => {
        setShowCompletedIssues(show)
    }, [setShowCompletedIssues]);

    useEffect(() => {
        !checkPermission(PERMISSION_GROUPS.readFinishedIssues) && clickHandler(false);
    }, [checkPermission, clickHandler]);

    return (
        <div className={classes['header']}>
            <div className={`${classes['link']} ${!showCompletedIssues && classes['active']}`}
                 onClick={() => clickHandler(false)}>
                {t('open')}
            </div>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.readFinishedIssues}>
                <div className={`${classes['link']} ${showCompletedIssues && classes['active']}`}
                     onClick={() => clickHandler(true)}>
                    {t('completed')}
                </div>
            </AccessControl>
        </div>
    );
};

export default Header

