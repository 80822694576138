import React from 'react';

import EmptyMessage from './EmptyMessage';
import ListItem, { ListItemProps, ListDefaultItem } from './ListItem';

import classes from './style.module.scss';

export interface ListProps<T> extends Pick<ListItemProps<T>, 'searchKey' | 'renderItem' | 'onSelect'> {
  list: T[]
}

const List = <T extends ListDefaultItem = ListDefaultItem>({ list, ...props }: ListProps<T>) => (
  <div className={classes['list']}>
    {(list.length ? list.map(item => <ListItem<T> key={item.id} item={item} {...props} />) : <EmptyMessage />)}
  </div>
);

export default List
