import React from 'react';
import classes from './style.module.scss';
import Icon from '~/app/common/Icon';
import {MaterialIcons as Icons} from '~/app/common/Icon/icons';
import useTranslation from "~/app/hooks/useTranslation";
import LinkWrapper from "~/app/common/LinkWrapper";

export interface AppMessageProps {
    type: string
    icon?: string
    text?: string
    url?: string
}

export type AppMessageComponent = React.FC<AppMessageProps>

const AppMessage: AppMessageComponent = ({icon, type, text, url, children}) => {
    const {t} = useTranslation("system");
    return (
        <div className={`${classes['msg-container']} ${classes[type]}`}>
            {icon && <Icon name={icon as Icons} className={classes['icon']}/>}
            <div className={classes['content']}>
                {text &&
                <div className={classes['info']}>
                    <span className={classes['text']}>{text}</span>
                    {url &&
                    <span className={classes['link']}>
                        <LinkWrapper to={url}>
                        {t('moreInfo')}
                        </LinkWrapper>
                    </span>}
                </div>}
                {children}
            </div>
        </div>
    )
}
export default AppMessage