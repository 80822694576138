import React, {useState, useCallback} from 'react';
import classes from './style.module.scss';
import FlashMessage from 'app/common/FlashMessage/index';
import Icon from 'app/common/Icon/index';
import useTranslation from "app/hooks/useTranslation";

export type OfflineTimerMessageComponent = React.FC

const OfflineModeMessage: OfflineTimerMessageComponent = () => {

    const {t} = useTranslation("offline");
    const [expand, setExpand] = useState(false);
    const clickHandler = useCallback(() => {
        setExpand(!expand);
    }, [setExpand, expand]);

    return (
        <FlashMessage type="warning">
            <div className={`${classes['container']} ${expand && classes["expand"]}`}>
                <Icon name="wifi_off" className={classes['icon']}/>
                <span className={`${classes['title']}`}>{t("title")}</span>
                {expand && <span className={`${classes['message']} ${classes['first']}`}>{t("message1")}</span>}
                {expand && <span className={`${classes['message']} ${classes['last']}`}>{t("message2")}</span>}
                {!expand && <span className={`${classes['more-info']}`} onClick={clickHandler}>{t("moreInfo")}</span>}
                {expand && <Icon name="close" className={classes['close']} onClick={clickHandler}/>}
            </div>
        </FlashMessage>
    );

};

export default OfflineModeMessage
