export enum StatusBarContentColors {
  default,
  light,
}

export function setStatusBarColor(bgColor: string, contentColor?: StatusBarContentColors): void {
  if (!window['StatusBar']) {
    return;
  }

  if (bgColor.indexOf('#') === 0) {
    bgColor = bgColor.slice(1);
  }

  if (bgColor.length === 3) {
    bgColor = bgColor[0] + bgColor[0] + bgColor[1] + bgColor[1] + bgColor[2] + bgColor[2];
  }

  if (bgColor.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  if (!contentColor) {
    contentColor = (
      (
        parseInt(bgColor.slice(0, 2), 16) * 0.299 +
        parseInt(bgColor.slice(2, 4), 16) * 0.587 +
        parseInt(bgColor.slice(4, 6), 16) * 0.114
      ) > 186
        ? StatusBarContentColors.default
        : StatusBarContentColors.light
    );
  }

  switch (contentColor) {
    case StatusBarContentColors.default:
      window['StatusBar'].styleDefault();
      break;
    case StatusBarContentColors.light:
      window['StatusBar'].styleLightContent();
      break;
  }

  window['StatusBar'].backgroundColorByHexString(`#${bgColor}`);
}
