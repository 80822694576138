import {action, Action, thunk, Thunk} from 'easy-peasy';
import {StoreModel} from "~/store";
import {API} from "~/app/routes";
import {fetch, fetchCancel} from "~/store/commonStore";
import {AllTenantListItem, AllUsers, Department, syncResponse} from "~/types";
import {AppOfflineService} from "~/sqlite/services/app";

export interface AppDataModel {
    users: AllUsers[]
    fetchingUsers: boolean
    setFetchingUsers: Action<AppDataModel, boolean>
    fetchUsers: Thunk<AppDataModel, void, any, StoreModel, Promise<AllUsers[]>>
    fetchUsersCancel: Action<AppDataModel>
    fetchedUsers: Action<AppDataModel, AllUsers[]>
    setOfflineUsers: Thunk<AppDataModel, AllUsers[], any, StoreModel, Promise<void>>
    getOfflineUsers: Thunk<AppDataModel, void, any, StoreModel, Promise<void>>

    departments: Department[]
    fetchingDepartments: boolean
    setFetchingDepartments: Action<AppDataModel, boolean>
    fetchDepartments: Thunk<AppDataModel, void, any, StoreModel, Promise<Department[]>>
    fetchDepartmentsCancel: Action<AppDataModel>
    fetchedDepartments: Action<AppDataModel, Department[]>
    setOfflineDepartments: Thunk<AppDataModel, Department[], any, StoreModel, Promise<void>>
    getOfflineDepartments: Thunk<AppDataModel, void, any, StoreModel, Promise<void>>

    sync: Thunk<AppDataModel, void, any, StoreModel, Promise<syncResponse | void>>

    allTenants: AllTenantListItem[]
    fetchingAllTenants: boolean
    setFetchingAllTenants: Action<AppDataModel, boolean>
    fetchAllTenants: Thunk<AppDataModel, void, any, StoreModel, Promise<AllTenantListItem[]>>
    fetchAllTenantsCancel: Action<AppDataModel>
    fetchedAllTenants: Action<AppDataModel, AllTenantListItem[]>

}

export const appDataModel: AppDataModel = {
    users: [],
    fetchingUsers: false,
    setFetchingUsers: action((state, payload) => {
        state.fetchingUsers = payload;
    }),
    fetchUsers: thunk((actions, payload, { getStoreState }) => {
        actions.setFetchingUsers(true);
        const userId = getStoreState().user?.data?.id
        return fetch<AllUsers>(`${API.allUsers}`)
            .then(data => {
                actions.fetchedUsers(data);

                const oneUserForOfflineMode = data.find((obj) => +userId === +obj.id)
                const offlineUsers = oneUserForOfflineMode ? [oneUserForOfflineMode]: [];
                actions.setOfflineUsers(offlineUsers);
                return data;
            })
            .finally(() => {
                actions.setFetchingUsers(false);
            });
    }),
    fetchUsersCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedUsers: action((state, payload) => {
        state.users = payload;
    }),
    setOfflineUsers: thunk(async (actions, payload) => {
        const AppOffline = new AppOfflineService();
        await AppOffline.resetUsers(payload);
    }),
    getOfflineUsers: thunk(async (actions) => {
        const AppOffline = new AppOfflineService();
        let users = await AppOffline.getAllUsers();
        users && actions.fetchedUsers(users);
    }),

    departments: [],
    fetchingDepartments: false,
    setFetchingDepartments: action((state, payload) => {
        state.fetchingDepartments = payload;
    }),
    fetchDepartments: thunk((actions) => {
        actions.setFetchingDepartments(true);
        return fetch<Department>(`${API.derpartments}`)
            .then(data => {
                actions.fetchedDepartments(data);
                actions.setOfflineDepartments(data);
                return data;
            })
            .finally(() => {
                actions.setFetchingDepartments(false);
            });
    }),
    fetchDepartmentsCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedDepartments: action((state, payload) => {
        state.departments = payload;
    }),
    setOfflineDepartments: thunk(async (actions, payload) => {
        const AppOffline = new AppOfflineService();
        await AppOffline.resetDepartments(payload);
    }),
    getOfflineDepartments: thunk(async (actions) => {
        const AppOffline = new AppOfflineService();
        let departments = await AppOffline.getAllDepartments();
        departments && actions.fetchedDepartments(departments);
    }),

    sync: thunk(async (actions) => {
        const AppOffline = new AppOfflineService();
        return await AppOffline.sync();
    }),

    allTenants: [],
    fetchingAllTenants: false,
    setFetchingAllTenants: action((state, payload) => {
        state.fetchingAllTenants = payload;
    }),
    fetchAllTenants: thunk((actions) => {
        actions.setFetchingAllTenants(true);
        return fetch<AllTenantListItem>(`${API.tenant}/all`)
            .then(data => {
                actions.fetchedAllTenants(data);
                return data;
            })
            .finally(() => {
                actions.setFetchingAllTenants(false);
            });
    }),
    fetchAllTenantsCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedAllTenants: action((state, payload) => {
        state.allTenants = payload;
    }),
};
