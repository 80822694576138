import React, {useEffect} from 'react';
import {useStoreActions, useStoreState} from '~/store';
import usePageTitle from "~/app/hooks/usePageTitle";
import {useAppHeader} from "~/app/modules/appHeader";
import useTranslation from "~/app/hooks/useTranslation";
import {ROUTES} from "~/app/Router";
import classes from './style.module.scss';
import {isCordova} from "~/cordova";

export type InstructionsComponent = React.FC

const AboutUs: InstructionsComponent = () => {
    const {setData} = useAppHeader();
    const {t} = useTranslation("aboutUs");
    usePageTitle(t('title'));

    const extensionTenantId = useStoreState(state => state.user.tokenData?.extension_tenantid);
    const allTenants = useStoreState(state => state.app.appData.allTenants);
    const fetchAllTenants = useStoreActions(actions => actions.app.appData.fetchAllTenants);
    const networkStatus = useStoreState(state => state.app.offline.status);

    useEffect(() => {
        setData({
            title: t('title'),
            subTitle: '',
            backLink: ROUTES.projects,
            showUser: false,
            machineId: '',
            projectId: '',
            showMachineMessages: false,
            isIssueDetailPage: false,
            notification: false,
            search: false
        });
    }, [setData, t]);

    useEffect(() => {
        if(!allTenants.length && !networkStatus) {
            fetchAllTenants();
        }
    }, [extensionTenantId, allTenants, fetchAllTenants, networkStatus]);

    const handleOpenIOS = () => {
        window['cordova'].InAppBrowser.open('itms-apps://itunes.apple.com/app//id1547223744', '_blank',);
    }

    const handleOpenANDROID = () => {
        window['cordova'].InAppBrowser.open('market://details?id=com.onlinelog.driverapp', '_blank',);
    }

    return (
        <div className={classes['aboutUs']}>
            <h1>Barde Felt</h1>
            {!!allTenants.length && <h3>Tenant: {allTenants.find(tenant => tenant.id === extensionTenantId)?.name || ""}</h3>}
            {isCordova && <h3>Versjon: {window['BuildInfo']?.version} ({window['BuildInfo']?.versionCode})</h3>}
            {window['cordova']?.platformId === 'ios' && (<div><button  onClick={handleOpenIOS} >Upgrade IOS App</button ></div>)}
            {window['cordova']?.platformId === 'android' && (<div><button onClick={handleOpenANDROID} >Upgrade Android App</button></div>)}

        </div>
    );
};

export default AboutUs
