import React from 'react';
import classes from './style.module.scss';
import {Message} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";

export interface ListItemProps {
    item: Message
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item}) => {
    const {t} = useTranslation("")
    return (
        <div className={classes['message']}>
            {!!item.showDate &&
            <div className={classes['message-date']}><span>{t("date.primary", {date: item.dateTime})}</span></div>}
            {!item.actions ?
                <>
                    <div className={classes['time']}>{item.time}</div>
                    <div className={classes['info']}>
                        <div className={classes['name']}>{item.senderName}</div>
                        <div className={classes['text']}>{item.message}</div>
                    </div>
                </> :
                <div className={classes['action']}>{item.message}</div>
            }
        </div>
    );
};

export default ListItem
