import React from 'react';
import { CertificationPermissionKeys } from '~/accessControl/permissionGroups';
import { Certification } from '~/types';
import ListItem from './ListItem';

export interface ListProps {
    list: {
        [key in CertificationPermissionKeys]: Certification
    }
}

export type ListComponent = React.FC<ListProps>

const List: ListComponent = ({ list }) => (
    <>
        {
            Object.keys(list).map((listItem, key) =>
                <ListItem key={key} readPerimission={listItem as CertificationPermissionKeys} data={list[listItem]} />
            )
        }
    </>
);

export default List;
