import React from 'react';
import classNames from 'classnames';
import Dimmer from '~/app/common/Dimmer';

import classes from './style.module.scss';
import ReactDOM from 'react-dom';

export interface ModalProps {
  open?: boolean
  onClose?: () => void
  className?: string
  position?: string
}

export type ModalComponent = React.FC<ModalProps>

const Modal: ModalComponent = ({ open = false, children, onClose, className = '', position = 'center' }) => ReactDOM.createPortal((
  <Dimmer show={open} page onClick={onClose}>
    <Dimmer.AnimationContent
      classNames={{
        enter: classes['enter'],
        enterActive: classes['enter-active'],
        exit: classes['exit'],
        exitActive: classes['exit-active'],
      }}
    >
      <div className={classNames(classes['modal'], className, classes[position])}>
        {children}
      </div>
    </Dimmer.AnimationContent>
  </Dimmer>
), (document.getElementById('root') as HTMLElement));

export default Modal
