import React from 'react';
import classNames from 'classnames';

import useField from './useField';

import Field, { FieldProps } from './Field';
import TextareaAutosize from 'react-textarea-autosize';

import classes from './style.module.scss';
import {TextareaAutosizeProps} from "react-textarea-autosize/dist/declarations/src";

export interface TextareaPropsDefault extends TextareaAutosizeProps {
  ref?: React.Ref<HTMLTextAreaElement>
  inputClassName?: string
}

export type TextareaProps = TextareaPropsDefault & FieldProps

export type TextareaComponent = React.RefForwardingComponent<HTMLTextAreaElement, TextareaProps>

const Textarea: TextareaComponent = (props, ref) => {
  const { fieldProps, inputProps: { inputClassName, ...othersInputProps } } = useField<TextareaPropsDefault>(props);

  return (
    <Field {...fieldProps}>
      <TextareaAutosize
        {...othersInputProps}
        ref={ref}
        className={classNames(classes['input'], classes['auto-size'], inputClassName)}
        disabled={fieldProps.loading}
      />
    </Field>
  );
};

export default React.forwardRef(Textarea)
