import React, {useEffect, useState} from 'react';
import {Route, Switch, useParams} from 'react-router-dom';
import RouterSuspense from '~/app/common/RouterSuspense';
import List from './';
import {Project} from "~/types";
import {useStoreActions, useStoreState} from "~/store";
import {ROUTES} from "~/app/Router";
import {useHistory} from "react-router";
import MachineDetailsRouter from "~/machines/Details/Router";
import Loader from "~/app/common/Loader";

export const MACHINES_ROUTES = {
    machines: '/projects/:projectId',
    list: '/projects/:projectId/machines',
    details: '/projects/:projectId/machines/:id'
};

export type RouterComponent = React.FC

const Router: RouterComponent = () => {
    const history = useHistory();
    const {projectId} = useParams<{ projectId: Project['id'] }>();
    const [fetching, setFetching] = useState<boolean>(true);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const updateLastUsedAction = useStoreActions(actions => actions.projects.updateLastUsed);
    const ProjectFetchAction = useStoreActions(actions => actions.project.fetchProject);
    const OfflineProjectFetchAction = useStoreActions(actions => actions.project.fetchOfflineProject);
    const inFavorite = useStoreState(state => state.projects.inFavorite(projectId));

    useEffect(() => {
        if ((netWorkStatus === 0 || inFavorite) && projectId) {
            (netWorkStatus ? OfflineProjectFetchAction : ProjectFetchAction)(projectId).then(() => {
                setFetching(false);
                netWorkStatus === 0 && updateLastUsedAction({projectId: projectId});
            }).finally(() => {
                setFetching(false);
                console.log('tupac2')
            });
        } else {
            history.push(ROUTES.projects);
        }
    }, [ProjectFetchAction, projectId, netWorkStatus, OfflineProjectFetchAction, updateLastUsedAction, inFavorite, history]);

    if (fetching) {
        return <Loader size="32"/>;
    }

    return (
        <RouterSuspense>
            <Switch>
                <Route path={MACHINES_ROUTES.details} component={MachineDetailsRouter}/>
                <Route path={[MACHINES_ROUTES.machines, MACHINES_ROUTES.list]} component={List}/>
            </Switch>
        </RouterSuspense>
    )
};

export default Router;
