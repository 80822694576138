import React, { useEffect, useMemo } from 'react';
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { route } from '~/app/helpers/route';

import usePageTitle from '~/app/hooks/usePageTitle';
import useTranslation from '~/app/hooks/useTranslation';

import { useAppHeader } from '~/app/modules/appHeader';

import { Machine } from '~/types';

import { useStoreState } from '~/store';

import { MACHINES_DETAILS_ROUTES } from '~/machines/Details/Router';

import List from './List';
import Header from './Header';
import EmptyMessage from './EmptyMessage';
import { ROUTES } from "~/app/Router";

export type IssueComponent = React.VFC;

const Issues: IssueComponent = () => {
  const { id } = useParams<{ id: Machine['id'] }>();

  const { t } = useTranslation('machine.details');

  usePageTitle(t('errorStatus'));
  const history = useHistory();
  const { setData } = useAppHeader();
  const location = useLocation();

  const showOnlyOpenCriticalIssues = useMemo(() => (
    matchPath(location.pathname, MACHINES_DETAILS_ROUTES.openCriticalIssues)
  ), [location.pathname]);

  const machine = useStoreState(state => state.machine.data);
  const issues = useStoreState(state => showOnlyOpenCriticalIssues ? state.issue.filteredOpenIssues : state.issue.filteredIssues);

  useEffect(() => {
    setData({
      title: t('errorStatus'),
      subTitle: `${machine.intern} - ${machine.name}`.toUpperCase(),
      backLink: history.length > 1 ? history.goBack : route(ROUTES.projects),
      showUser: false,
      machineId: '',
      projectId: '',
      showMachineMessages: false,
      isIssueDetailPage: false,
      notification: false,
      search: false
    });
  }, [setData, id, machine, t, history]);

  return (
    <>
      {!showOnlyOpenCriticalIssues && <Header />}
      {issues.length ? <List list={_.orderBy(issues, ['statusDate'], ['desc'])} /> : <EmptyMessage />}
    </>
  );
};
export default Issues
