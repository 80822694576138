import {RequiredPermissions} from "~/accessControl/index";

export type CertificationPermissionKeys = 
    'readCertificationInfo' |
    'readADRCertificationInfo' |
    'readLiftControlCertificationInfo' |
    'readElectricalControlCertificationInfo' |
    'readPSVCertificationInfo' |
    'readAirTank1YearCertificationInfo' |
    'readExtinguishingEquipmentCertificationInfo' |
    'readAirTank2YearCertificationInfo' |
    'readElectricMotorCertificationInfo' |
    'readNDTCertificationInfo' |
    'readAirTank10YearCertificationInfo';

export type PermissionGroupsKeys =
    'app' |
    'machineRegistration' |
    'machineDashboard' |
    'readWriteOperatingTime' |
    'readWriteOperatingCount' |
    'readWritePeriodicCheck' |
    'readIssues' |
    'registerIssues' |
    'registerRepairByDriver' |
    'performAcceptanceInspection' |
    'readMachineDocuments' |
    'readFinishedIssues' |
    'readCompletedIssueChat' |
    'readCompletedWorkOrderDetails' |
    'readServiceDetails' |
    'readIdleHoursHistoryReport' |
    'readFuelConsumptionHistoryReport' |
    'readPeriodicChecksHistoryReport' |
    'editOwnRegisteredIssues' |
    'editIssuesRegisteredByOthers' |
    CertificationPermissionKeys;
    

type PermissionGroups = {
    [key in PermissionGroupsKeys]: RequiredPermissions
}

export const PERMISSION_GROUPS: PermissionGroups = {
    app: {
        menu: 'felt_app'
    },
    machineRegistration: {
        menu: 'felt_app',
        subMenu: 'assets_menu'
    },
    machineDashboard: {
        menu: 'felt_app',
        subMenu: 'asset_dashboard'
    },
    readWriteOperatingTime: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'operating_time'
    },
    readWriteOperatingCount: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'operating_count'
    },
    readWritePeriodicCheck: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'daily-check'
    },
    readIssues: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status'
    },
    registerIssues: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'register_issues'
    },
    registerRepairByDriver: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'register_issues_by_driver'
    },
    performAcceptanceInspection: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'perform_acceptance_inspection'
    },
    readMachineDocuments: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'information_documentation'
    },
    readFinishedIssues: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status',
        subAction: 'view_completed_issues'
    },
    readCompletedIssueChat: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status',
        subAction: 'view_chat_history_on_completed_issues'
    },
    readCompletedWorkOrderDetails: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status',
        subAction: 'view_workorder_on_completed_issues'
    },
    readServiceDetails: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_service_info'
    },
    readCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_certification_info'
    },
    readADRCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_adr_certification_info'
    },
    readLiftControlCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_lift_control_certification_info'
    },
    readElectricalControlCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_electrical_control_certification_info'
    },
    readPSVCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_psv_certification_info'
    },
    readAirTank1YearCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_air_tank_1_year_certification_info'
    },
    readExtinguishingEquipmentCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_extinguishing_equipment_certification_info'
    },
    readAirTank2YearCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_air_tank_2_year_certification_info'
    },
    readElectricMotorCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_electric_motor_certification_info'
    },
    readNDTCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_ndt_certification_info'
    },
    readAirTank10YearCertificationInfo: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'view_air_tank_10_year_certification_info'
    },
    readIdleHoursHistoryReport: {
        menu: 'felt_app',
        subMenu: 'asset_dashboard',
        action: 'idle_hours_report'
    },
    readFuelConsumptionHistoryReport: {
        menu: 'felt_app',
        subMenu: 'asset_dashboard',
        action: 'fuel_consumption_report'
    },
    readPeriodicChecksHistoryReport: {
        menu: 'felt_app',
        subMenu: 'asset_dashboard',
        action: 'periodic_checks_report'
    },
    editOwnRegisteredIssues: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status',
        subAction: 'access_to_edit_own_registered_issues'
    },
    editIssuesRegisteredByOthers: {
        menu: 'felt_app',
        subMenu: 'assets_menu',
        action: 'issue_status',
        subAction: 'access_to_edit_issues_registered_by_others'
    },
}