import React, {useCallback} from 'react';
import classes from './style.module.scss';
import useTranslation from 'app/hooks/useTranslation';
import Checkbox from "app/modules/form/Checkbox/index";
import {useStoreState, useStoreActions} from "~/store";

export type DailogContentComponent = React.FC;

const Content: DailogContentComponent = () => {
    const {t} = useTranslation('offline.dialog');
    const setDoNotShowAction = useStoreActions(actions => actions.app.offline.setDoNotShowDialogAgain);
    const doNotShowDialogAgain = useStoreState(actions => actions.app.offline.doNotShowDialogAgain);

    const checkboxClickHandler = useCallback((): void => {
        setDoNotShowAction(!doNotShowDialogAgain);
    }, [doNotShowDialogAgain, setDoNotShowAction]);

    return (
        <>
            <div className={classes['model-content']}>
                <div className={`${classes['message']} ${classes['bold-text']}`}>{t('message1')}</div>
                <div className={`${classes['message']}`}>{t('message2')}</div>
                <Checkbox
                    name='checkbox'
                    className={`${classes['checkbox-wrapper']}`}
                    inputClassName={`${classes['checkbox']}  ${(doNotShowDialogAgain) ? classes['checked'] : ''}`}
                    placeholder={t('doNotShowAgain')}
                    defaultChecked={doNotShowDialogAgain}
                    onChange={checkboxClickHandler}/>
            </div>
        </>
    );
};

export default Content
