import React, {useMemo} from 'react';
import {IssueDetails} from 'types/index';
import Icon, {MaterialIcons} from "app/common/Icon/index";
import classes from './style.module.scss';
import useTranslation from "app/hooks/useTranslation";

export interface StatusMessageProps {
    item: IssueDetails
}

export type StatusMessageComponent = React.FC<StatusMessageProps>

const StatusMessage: StatusMessageComponent = ({item}) => {
    const {t} = useTranslation("issue.statusMessages");

    const icon = useMemo<MaterialIcons | undefined>(() => {
        if (item.status === 3 || item.status === 2) {
            return 'check';
        } else if (item.status === 1) {
            return 'error_outline';
        } else if (item.status === 0 && item.seenDate) {
            return 'done_all';
        }
    }, [item]);

    if (item.status === 0 && !item.seenDate) {
        return null;
    }

    return (
        <>
            <div className={`${classes['issue-message']} ${classes[item.status]} `}>
                <Icon name={icon} className={classes['icon']}/>
                <span>
                    {t((item.type === 3 ? 4 : item.status).toString(),
                        {
                            date: (item.status === 0) ? item.seenDate : item.statusDate,
                            department: item.departmentName
                        }
                    )}
                </span>
            </div>
        </>
    );
}

export default StatusMessage
