import React from 'react';
import classes from './style.module.scss';
import {Equipment, Machine, Project} from "~/types";
import {route} from "~/app/helpers/route";
import {useParams} from "react-router-dom";
import LinkWrapper from "~/app/common/LinkWrapper";
import classNames from "classnames";
import Icon from "~/app/common/Icon";
import {MACHINES_DETAILS_ROUTES} from "~/machines/Details/Router";

export interface ListItemProps {
    item: Equipment
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({item}) => {
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();

    return (
        <LinkWrapper className={classes['list-item']}
                     to={route(MACHINES_DETAILS_ROUTES.documentation, {projectId, id, equipmentId: item.id})}>
            <div className={classes['info']}>
                <div className={classes['name']}>{item.name}</div>
                <div className={classes['code']}>{item.intern}</div>
            </div>
            <div className={classNames(classes['right-icon-wrap'])}>
                <Icon name="keyboard_arrow_right"/>
            </div>
        </LinkWrapper>
    );
};

export default ListItem
