import React, { useCallback, useEffect, useState, memo } from 'react';
import axios from '~/axiosWrapper';

import useDidMountEffect from '~/app/hooks/useDidMountEffect';

import Field, { FieldProps } from './Field';
import useField from './useField';
import Options from './Options';
import useDefaultValue from './useDefaultValue';

import classes from './style.module.scss';

type SelectOption = {
  text: React.ReactNode,
  value: string | number
}

export interface SelectPropsDefault extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  ref?: React.Ref<HTMLSelectElement>
  options?: SelectOption[]
  optionsUrl?: string
  showEmptyOption?: boolean
  emptyOptionValue?: string | number
}

export type SelectProps = SelectPropsDefault & FieldProps

export type SelectComponent = React.RefForwardingComponent<HTMLSelectElement, SelectProps>

const Select: SelectComponent = (props, ref) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<SelectOption[]>([]);
  // console.log('tttt', props, ref)

  const {
    fieldProps: { icon = 'arrow_drop_down', iconPosition = 'right', iconSize = 32, ...fieldProps },
    inputProps: { defaultValue: defaultDefaultValue, options: defaultOptions, optionsUrl, onChange, placeholder, showEmptyOption, emptyOptionValue, ...othersInputProps }
  } = useField<SelectPropsDefault>(props);

  const defaultValue = useDefaultValue(defaultDefaultValue);

  const [value, setValue] = useState<number>(defaultValue);

  const setNewOptions = useCallback((data: any[]): void => {
    setOptions(data.map(item => ({ text: item.name, value: item.id })));

    if (!defaultValue) {
      setValue(data[0].id);
    }
  }, [defaultValue]);

  const selectChangeHandler = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
    setValue(+e.target.value);
  }, []);

  useDidMountEffect(() => {
    if (optionsUrl) {
      axios.get<any[], any[]>(optionsUrl)
        .then(setNewOptions)
        .finally(() => {
          setLoading(false);
        });
    } else if (defaultOptions) {
      setOptions(defaultOptions);
      setLoading(false);
    }
  });

  useEffect(() => {
    if (onChange) {
      // @ts-ignore
      onChange({ target: { name: othersInputProps.name, value } });
    }
  }, [onChange, othersInputProps.name, value]);

  return (
    <Field
      icon={icon}
      iconPosition={iconPosition}
      iconSize={iconSize}
      {...fieldProps}
      loading={fieldProps.loading || loading}
    >
      <select
        ref={ref}
        className={`${classes['input']} ${classes['select']}`}
        {...othersInputProps}
        value={value}
        disabled={fieldProps.loading || loading}
        onChange={selectChangeHandler}
      >
        {!!placeholder && !!showEmptyOption && <option key="" value={emptyOptionValue} disabled>{placeholder}</option>}
        <Options options={options} />
        {/*{options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}*/}
      </select>
    </Field>
  );
};

export default memo(React.forwardRef(Select))
