import React, {memo, FC} from 'react'

interface Props {
    options: {
        text: React.ReactNode,
        value: string | number
    }[]
}


const Options: FC<Props> = ({options}) => {
    console.log('options')
    return <> {options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}</>
}

export default memo(Options)
