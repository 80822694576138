import { Action, action, actionOn, ActionOn } from 'easy-peasy';

import { localStorageGet, localStorageRemove, localStorageSet } from '~/app/helpers/localStorage';
import { StoreModel } from '~/store';

export interface SidebarModel {
  isCollapse: boolean
  isOpen: boolean
  setCollapse: Action<SidebarModel, boolean>
  setOpen: Action<SidebarModel, boolean>
  onSetCollapse: ActionOn<SidebarModel, StoreModel>
}

const localStorageKey = 'sidebar.collapsed';

export const sidebarModel: SidebarModel = {
  isCollapse: !!localStorageGet(localStorageKey),
  isOpen: false,
  setCollapse: action((state, payload) => {
    state.isCollapse = payload;
  }),
  setOpen: action((state, payload) => {
    state.isOpen = payload;
  }),
  onSetCollapse: actionOn(
    actions => actions.setCollapse,
    (actions, target) => {
      if (target.payload) {
        localStorageSet(localStorageKey, '1');
      } else {
        localStorageRemove(localStorageKey);
      }
    }
  )
};
